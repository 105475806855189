import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LexiPagebuilderService } from '../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-plain-view',
  templateUrl: './plain-view.component.html',
  styleUrls: ['./plain-view.component.css']
})
export class PlainViewComponent  {

  public document;

  @Input() set setPageData(val:any) {
    this.page = val;
  };

  public page:any;

  constructor(private builder: LexiPagebuilderService, @Inject(PLATFORM_ID) private platformId: any) {
    this.builder.editable = false;
    if(isPlatformBrowser(this.platformId)) {

    this.document = document;
    }
  }

  buildClass(section:any) {
    if(!section.style) {
      return ""
    }
    
    let classname = Object.values(section.style);
    return classname.join(" ")
  }


  validateColumeType(type:string) {
    return type.startsWith("columns")
  }

}
