import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceManagerComponent } from 'lexi-resource';

@Component({
  selector: 'lib-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent {

  public component: any
  public loading: boolean = false;
  public setup:any = [];

  @Input() set setComponent(val:any) {

    if(val.setup) {
      this.setup = val.setup;
    } else {
      switch(val.type) {
        case "text":
          this.setup = [
            // {type: 'setting_accordion', title: 'Text Setting', key: 'set_text'},
            {type: 'label', title: 'Text Setting'},
            {type: 'wysiwyg-simple', title: 'Enter Your Text', key: 'value'},
            {type: 'text-heading', title: 'Heading Level', key: 'heading_level'},
            {type: 'style-font-size', title: 'Font Size', key: 'font_size'},
            {type: 'style-font-color', title: 'Font Color', key: 'font_color'},
            {type: 'style-font-family', title: 'Font Family', key: 'font_family'},
            {type: 'style-text-align', title: 'Text Align', key: 'text_align'},
            {type: 'style-letter-spacing', title: 'Letter Spacing', key: 'letter_spacing'},
            {type: 'style-line-height', title: 'Line Height', key: 'line_height'},
            {type: 'seperator'}
          ]
        break;
        case "image": 
          this.setup = [
            {type: 'label', title: 'Image Setting'},
            {type: 'image', title: 'Select Your Image in Desktop View', key: 'value', select_media_title: "SELECT MEDIA"},
            {type: 'image', title: 'Select Your Image in Mobile View', key: 'mobile_value', select_media_title: "SELECT MEDIA"},
            {type: 'style-corner', title: 'Image Corner', key: 'corner'},
            {type: 'checkbox', title: 'Border', key: 'set_border', value:"true"},
            {type: 'style-border-style', title: 'Border Style', key: 'border_style', dependency: 'set_border'},
            {type: 'style-border-width', title: 'Border Width', key: 'border_width', dependency: 'set_border'},
            {type: 'style-border-color', title: 'Border Color', key: 'border_color', dependency: 'set_border'},
            {type: 'text', title: 'Describe the image', key: 'title'},
            {type: 'seperator'}
          ]
        break;
        case "button":
          this.setup = [
            {type: 'label', title: 'Button Setting'},
            {type: 'text', title: 'Enter Your Button Text', key: 'value'},
            {type: 'style-font-family', title: 'Font Family', key: 'font_family'},
            {type: 'style-button-size', title: 'Button Size', key: 'size'},
            {type: 'style-corner', title: 'Corner', key: 'corner'},
            {type: 'style-line-height', title: 'Line Height', key: 'line_height'},
            {type: 'checkbox', title: 'General Button Setting', key: 'general_btn', value:"true"},
            {type: 'style-general-button-color', title: 'Outline Color', key: 'default_color', dependency: 'general_btn'},
            {type: 'seperator', dependency: 'general_btn'},
            {type: 'checkbox', title: 'Advance Button Setting', key: 'advance_btn', value:"true"},
            {type: 'style-button-color', title: 'Button Color', key: 'btn_color', dependency: 'advance_btn'},
            {type: 'style-font-color', title: 'Font Color', key: 'font_color', dependency: 'advance_btn'},
            {type: 'seperator', dependency: 'advance_btn'}
          ]
        break;
        case "video":
          this.setup = [
            {type: 'video', title: 'Select Your Video', key: 'value', select_media_title: "SELECT MEDIA"},
            {type: 'image', title: 'Select Your Video poster', key: 'poster', select_media_title: "SELECT MEDIA"},
            {type: 'checkbox', title: 'Autoplay Video on start', key: 'autoplay', value:"true"},
            {type: 'checkbox', title: 'Loop Video', key: 'loop', value:"true"},
            {type: 'checkbox', title: 'Show video control', key: 'controls', value:"true"},
            {type: 'checkbox', title: 'Mute video', key: 'muted', value:"true"},
          ]
        break;
        case "html":
          this.setup = [
            {type: 'html', title: 'Enter Your HTML', key: 'value'},
          ]
        break;
        case "slider":
          this.setup = [
            {type: 'label', title: 'Slider Setting'},
            {type: 'style-text-align', title: 'Text Align', key: 'text_align'},
            {type: 'style-line-height', title: 'Line Height', key: 'line_height'},
            {type: 'number', title: 'Interval (Second)', key: 'interval'},
            {type: 'checkbox', title: 'Allow Keyboard Access', key: 'keyboard'},
            {type: 'checkbox', title: 'Pause on Hover', key: 'pauseOnHover'},
            {type: 'checkbox', title: 'Show Navigation Arrows', key: 'showNavigationArrows'},
            {type: 'checkbox', title: 'Show Navigation Indicators', key: 'showNavigationIndicators'},
            {type: 'checkbox', title: 'Loop the slide', key: 'wrap'},
            // {type: 'label', title: 'Button Setting'},
            // {type: 'text', title: 'Enter Your Button Text', key: 'value'},
            // {type: 'style-font-family', title: 'Font Family', key: 'font_family'},
            // {type: 'style-button-size', title: 'Button Size', key: 'size'},
            // {type: 'style-corner', title: 'Corner', key: 'corner'},
            // {type: 'style-line-height', title: 'Line Height', key: 'line_height'},
            // {type: 'checkbox', title: 'General Button Setting', key: 'general_btn', value:"true"},
            // {type: 'style-general-button-color', title: 'Outline Color', key: 'default_color', dependency: 'general_btn'},
            // {type: 'seperator', dependency: 'general_btn'},
            // {type: 'checkbox', title: 'Advance Button Setting', key: 'advance_btn', value:"true"},
            // {type: 'style-button-color', title: 'Button Color', key: 'btn_color', dependency: 'advance_btn'},
            // {type: 'style-font-color', title: 'Font Color', key: 'font_color', dependency: 'advance_btn'},
            // {type: 'seperator', dependency: 'advance_btn'},


            {type: 'slide', title: 'Slider Title Style',  size:"size", colors:"color", font_family: "font_family"},
            {type: 'slide', title: 'Slider Description Style',  size:"dsize",colors:"dcolor", font_family: "dfont_family"},
            {type: 'notes', title: 'Take note: to maintain the layout structure of the slider, use the same image size for desktop. eg 1200px x 400px and same image size for mobile. eg: 800px 600px'},
            {type: 'seperator'},
            {type: 'fields', title: 'Select Your Image', key: 'value', fields: 
              [
                {type: 'wysiwyg-simple', title: 'Enter Slide Title', key: 'title'},
                {type: 'wysiwyg-simple', title: 'Enter Slide Description', key: 'descr'},
                {type: 'image', title: 'Select Slide Image in Desktop View', key: 'image', select_media_title: "SELECT MEDIA"},
                {type: 'image', title: 'Select Slide Image in Mobile View', key: 'mobile_image', select_media_title: "SELECT MEDIA"},

                {type: 'checkbox', title: 'Display Button', key: 'set_btn', value:"true"},
                {type: 'label', title: 'Button setting', dependency: 'set_btn'},
                // {type: 'text', title: 'Enter Your Button Text', key: 'button', dependency: 'set_btn'},
                // {type: 'style-button-size', title: 'Button Size', key: 'size', style_key: "btn", dependency: 'set_btn'},
                // {type: 'style-font-family', title: 'Font Family', key: 'font_family', style_key: "btn", dependency: 'set_btn'},
                // {type: 'style-corner', title: 'Corner', key: 'corner', dependency: 'set_btn'},
                // {type: 'checkbox', title: 'General Button Setting', key: 'general_btn', value:"true", dependency: 'set_btn'},
                // {type: 'style-general-button-color', title: 'Outline Color', key: 'default_color', style_key: "btn", dependency: 'general_btn'},
                // {type: 'checkbox', title: 'Advance Button Setting', key: 'advance_btn', style_key: "btn", value:"true", dependency: 'set_btn'},
                // {type: 'style-button-color', title: 'Button Color', key: 'btn_color', style_key: "btn", dependency: 'advance_btn'},
                // {type: 'style-font-color', title: 'Font Color', key: 'font_color', style_key: "btn", dependency: 'advance_btn'},
                // {type: 'seperator', dependency: 'set_btn'}
              ]
            }
          ]
        break;
        case "card":
          this.setup = [
            {type: 'label', title: 'Card Setting'},
            {type: 'checkbox', title: 'Display Image', key: 'set_image', value:"true"},
            {type: 'label', title: 'Image Setting', dependency: 'set_image'},
            {type: 'image', title: 'Select Card Image', key: 'value', select_media_title: "SELECT MEDIA", dependency: 'set_image'},
            {type: 'seperator', dependency: 'set_image'},
            {type: 'checkbox', title: 'Display Card Title', key: 'set_title', value:"true"},
            {type: 'label', title: 'Title Setting', dependency: 'set_title'},
            {type: 'wysiwyg-simple', title: 'Enter Your Card Title', key: 'title', dependency: 'set_title'},
            {type: 'style-font-size', title: 'Font Size', key: 'title_font_size', dependency: 'set_title'},
            {type: 'style-font-color', title: 'Font Color', key: 'title_font_color', dependency: 'set_title'},
            {type: 'style-font-family', title: 'Font Family', key: 'title_font_family', dependency: 'set_title'},
            {type: 'style-text-align', title: 'Text Align', key: 'title_text_align', dependency: 'set_title'},
            {type: 'style-letter-spacing', title: 'Letter Spacing', key: 'title_letter_spacing', dependency: 'set_title'},
            {type: 'seperator', dependency: 'set_title'},
            {type: 'checkbox', title: 'Display Card Description', key: 'set_descr', value:"true"},
            {type: 'label', title: 'Description Setting', dependency: 'set_descr'},
            {type: 'wysiwyg-simple', title: 'Enter Your Card Description', size:"descr_font_size", colors:"descr_font_color", font_family: "descr_font_family", text_align:"descr_text_align", key: 'descr', dependency: 'set_descr'},
            // {type: 'style-font-size', title: 'Font Size', key: 'descr_font_size', dependency: 'set_descr'},
            // {type: 'style-font-color', title: 'Font Color', key: 'descr_font_color', dependency: 'set_descr'},
            // {type: 'style-font-family', title: 'Font Family', key: 'descr_font_family', dependency: 'set_descr'},
            // {type: 'style-text-align', title: 'Text Align', key: 'descr_text_align', dependency: 'set_descr'},
            // {type: 'style-letter-spacing', title: 'Letter Spacing', key: 'descr_letter_spacing', dependency: 'set_descr'},
            {type: 'seperator', dependency: 'set_descr'},
            {type: 'checkbox', title: 'Display Button', key: 'set_btn', value:"true"},
            {type: 'label', title: 'Button setting', dependency: 'set_btn'},
            {type: 'text', title: 'Enter Your Button Text', key: 'button', dependency: 'set_btn'},
            {type: 'style-button-size', title: 'Button Size', key: 'size', style_key: "btn", dependency: 'set_btn'},
            {type: 'style-font-family', title: 'Font Family', key: 'font_family', style_key: "btn", dependency: 'set_btn'},
            {type: 'style-corner', title: 'Corner', key: 'corner', dependency: 'set_btn'},
            {type: 'checkbox', title: 'General Button Setting', key: 'general_btn', value:"true", dependency: 'set_btn'},
            {type: 'style-general-button-color', title: 'Outline Color', key: 'default_color', style_key: "btn", dependency: 'general_btn'},
            {type: 'checkbox', title: 'Advance Button Setting', key: 'advance_btn', style_key: "btn", value:"true", dependency: 'set_btn'},
            {type: 'style-button-color', title: 'Button Color', key: 'btn_color', style_key: "btn", dependency: 'advance_btn'},
            {type: 'style-font-color', title: 'Font Color', key: 'font_color', style_key: "btn", dependency: 'advance_btn'},
            {type: 'seperator', dependency: 'set_btn'}
          ]
        break;
        case "accordion":
          this.setup = [
            {type: 'label', title: 'Accordian Setting'},
            {type: 'accordion', title: 'Accordion Title Style',  size:"size", colors:"color", font_family: "font_family"},
            {type: 'style-text-align', title: 'Title Text Align', key: 'title_text_align'},
            {type: 'style-letter-spacing', title: 'Title Letter Spacing', key: 'title_letter_spacing'},
            {type: 'style-line-height', title: 'Title Line Height', key: 'title_line_height'},
            {type: 'accordion', title: 'Accordion Description Style',  size:"dsize", colors:"dcolor", font_family: "dfont_family"},
            {type: 'style-text-align', title: 'Description Text Align', key: 'descr_text_align'},
            {type: 'style-letter-spacing', title: 'Description Letter Spacing', key: 'descr_letter_spacing'},
            {type: 'style-line-height', title: 'Description Line Height', key: 'descr_line_height'},
            {type: 'fields', fields: [
              {type: 'wysiwyg-simple', title: 'Enter Your Accordion Title', key: 'title'},
              {type: 'wysiwyg-simple', title: 'Enter Your Description', key: 'descp'}
            ]}
          ] 
        break;
        case "list":
          this.setup = [
            {type: 'label', title: 'List Setting'},
            {type: 'list-style', title: 'Select List Type', list_style: 'list_style'},
            {type: 'style-font-size', title: 'Font Size', key: 'font_size'},
            {type: 'style-font-color', title: 'Font Color', key: 'font_color'},
            {type: 'style-font-family', title: 'Font Family', key: 'font_family'},
            {type: 'style-text-align', title: 'Text Align', key: 'text_align'},
            {type: 'style-letter-spacing', title: 'Letter Spacing', key: 'letter_spacing'},
            {type: 'style-line-height', title: 'Line Height', key: 'line_height'},
            {type: 'fields', fields: [
              {type: 'wysiwyg-simple', title: 'Enter List Content', key: 'value'}
            ]}
          ]
        break;
        case "maps":
          this.setup = [
            {type: 'label', title: 'Maps Setting'},
            {type: 'text', title: 'Enter the Latitude', key: 'Latitude'},
            {type: 'text', title: 'Enter the Longitude', key: 'Longitude'}
          ]
        break;

      }
    }
    
    this.component = val;
  };

  @Output() update = new EventEmitter();

  constructor(private modalService: NgbModal) {
    
  }
  
  triggerUpdate(): void {
    this.update.emit(this.component);
  }

  updatezreseult(event:any){
    console.log(event, this.component)
    // this.component = event;
    this.triggerUpdate()
  }

  setStyle(key:string, val:string, data_key:string = "style") {
    if(!this.component[data_key]) {
      this.component[data_key] = {}
    }
    this.component[data_key][key] = val;
    this.triggerUpdate()
  }

  addItem() {
    let _default :any = {};
    if(this.component.default) {
      let def = Object.keys(this.component.default);
      let def_val = this.component.default;

      for(var x = 0; x < def.length; x++) {
        _default[def[x]] = def_val[def[x]]
      }
    }    
    this.component.fields.push(_default)
  }

  selectMediaWithKey(key?:string) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.getSelected.subscribe((v:any) => {

      if(v && v[0] && key) {
        this.component[key] = v[0]['key']
      } else if(v && v[0]) {
        this.component.value = v[0]['key']
      }
      this.triggerUpdate();

      modal.close();

    })
  }

  selectMedia(i?:number, key?:string) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.getSelected.subscribe((v:any) => {

      if(i != undefined && key != undefined) {
        this.component.items[i][key] =  v[0]['key'];
        this.triggerUpdate();
        modal.close();
        return;
      }

      if(v && v[0]) {
        this.component.value = v[0]['key']
        this.triggerUpdate();
        modal.close();
      }
    })
  }
  setStyleOnChange(event:any, key:string, data_key:string = "style") {
    if(!event.target.value) {
      return;
    }
    if(!this.component[data_key]){
      this.component[data_key] = []
    }
    
    this.component[data_key][key] = event.target.value;
    this.triggerUpdate()
  }
  getData($event:any) {
    this.component.value = $event;
    this.triggerUpdate()
  }

  removeItem(index:number) {
    if(!this.component.fields || this.component.fields.length <= 1) {
      return
    }

    this.component.fields.splice(index, 1)
  }

  toggleOption(event:any) {
    let accrodion = event.target.parentNode.classList.contains("lexi-accordion")
    if(accrodion) {
      event.target.parentNode.classList.toggle("active")
      return;
    }
    accrodion = event.target.parentNode.parentNode.classList.contains("lexi-accordion")
    if(accrodion) {
      event.target.parentNode.parentNode.classList.toggle("active")
      return;
    } 
    event.target.parentNode.parentNode.parentNode.classList.toggle("active")
  }
}