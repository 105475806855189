<form class="p-3" (submit)=submit()>
    <h2>Add Event to Calender</h2>
    <div class="form-group">
        <label for="branch" class="me-2">Select Branch <em>*</em></label>
        <select class="form-control" id="branch" [(ngModel)]="data.installation_branch" name="branch">
            <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
        </select>
    </div>

    <div class="form-group">
        <label for="branch" class="me-2">Event Type <em>*</em></label>
        <select class="form-control" id="branch" [(ngModel)]="data.event_id" name="event_id">
            <option [value]="'on-leave'">On Leave</option>
            <option [value]="'other'">Other</option>
        </select>
    </div>
    
    <div class="form-group position-relative">
        <label for="eventDate">Event Date <em>*</em></label>
        <input class="form-control" id="eventDate" type="date" name="date" ngbDatepicker #d="ngbDatepicker" [(ngModel)]="data.event_date" (click)="d.toggle()" autocomplete="off" required/>
    </div>
      
    <div class="form-group">
      <label for="eventTitle">Event Title <em>*</em></label>
      <input type="text" class="form-control" id="eventTitle" [(ngModel)]="data.event_title"placeholder="Enter Event Title" name="event" required/>
    </div>
    <div class="form-group">
        <label for="eventDescription">Event Description</label>
        <textarea type="text" class="form-control" id="eventDescription" [(ngModel)]="data.event_description" placeholder="Enter Event Description" name="event_description"></textarea>
      </div>
    
    <button type="submit" class="btn btn-primary">Add Event</button>
</form>