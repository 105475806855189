import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-customer-appointment',
  templateUrl: './customer-appointment.component.html',
  styleUrls: ['./customer-appointment.component.css']
})
export class CustomerAppointmentComponent {

  public data:any = {customer: {}, product: [{filter_product: []}], car_maker: null, car_model: null};
  public init: any = {};
  public appointment_uuid:string | null = null;
  public today_date:any;
  constructor(private router: Router, private api: ApiService, private activatedRoute:ActivatedRoute, private notify: LexiNotifyService) {

    this.activatedRoute.params.subscribe(async (val:any) => {
      let state_data = this.router.getCurrentNavigation()?.extras.state
      if(val.appointment_uuid) {
        this.appointment_uuid = val.appointment_uuid
      } else  if(!state_data || !state_data['customer']) {
        this.router.navigate(['/new'])
        return;
      } else {
        this.data.customer = state_data['customer'];
      }
    })

    let date = new Date();
    this.today_date = { day: date.getUTCDay(), month: date.getUTCMonth(), year: date.getUTCFullYear()};

    this.call();

  }

  async call() {
    this.init = await this.api.post("/profile/init/form", {});

    this.init.filter_model = [];
    if(this.init.branch.length == 0) {
      this.router.navigate(['/new'])
      return;
    }
    this.data.installation_branch = this.init.branch[0]['uuid']

    await this.getAppointment()
  }

  async getAppointment() {

    if(!this.appointment_uuid) {
      return;
    }

    this.data = await this.api.post("/profile/appointment/get", {appointment_uuid: this.appointment_uuid});
    console.log(this.data)
    
    let car_model = this.data.car_model;
    this.filterModel()

    this.data.car_model = car_model
    
    this.data.product.forEach((v:any) => {
      v.filter_product = this.init.product.filter((b:any) => {
        return b.category_id == v.category_id;
      })
    })
  }

  filterModel() {
    this.init.filter_model = this.init.model.filter((v:any) => {
      return v.car_maker_id == this.data.car_maker;
    })
    this.data.car_model = null
  }

  filterCategory(product:any) {
    product.filter_product = this.init.product.filter((v:any) => {
      return v.category_id == product.category;
    })
    product.product = null;
  }

  showWarranty(product:any) {

    let p = this.init.product.find((v:any) => {
      return v.id == product.product;
    });

    if(!p) {
      return;
    }

    let warranty = "";
    if(p.tint_warranty) {
      warranty += "Tint: " + p.tint_warranty + " years<br/>";
    }

    if(p.coating_warranty) {
      warranty += "Coating: " + p.coating_warranty + " years<br/>";
    }

    if(p.coating_maintenance) {
      warranty += "Maintenance: " + p.coating_maintenance + " time<br/>";
    }

    if(p.ppf_warranty) {
      warranty += "PPF: " + p.ppf_warranty + " years<br/>";
    }

    return warranty;
  }

  addNewProduct() {
    this.data.product.push({filter_product: []})
  }

  removeProduct(index:number) {
    if(this.data.product.length <= 1) {
      return;
    }
    this.data.product.splice(index, 1)
  }

  async save() {
    let data = this.data;
    data.appointment_uuid = this.appointment_uuid
    let rtn = await this.api.post("/profile/appointment/save", data);

    if(!rtn.status) {
      alert(rtn.message)
      return;
    }

    this.appointment_uuid = rtn.appointment_uuid;
    await this.getAppointment();

    this.router.navigate(["/customer/appointment", this.appointment_uuid]);
    this.notify.success("Appointment create successfully.")
  }

  async convert() {
    let data = this.data;
    console.log(data)
    data.appointment_uuid = this.appointment_uuid
    data.installation_date = data.appointment_date
//    this.router.navigate(["/customer/create/job"], { state: { customer: data.customer, appointment_uuid: data.uuid, appointment: data } } )

    this.router.navigate(["/customer/create/job"], { state: { customer: data.customer, data: data } } )

  }
}
