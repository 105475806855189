<form class="p-3">

    <h4>New Customer</h4>
        <hr/>
        <div class="form-group mb-3">
            <label for="name">Name</label>
            <input type="text" id="name" class="form-control" name="name" [(ngModel)]="data.name">
        </div>

        <div class="form-group mb-3">
            <label for="email">Email</label>
            <input type="text" id="email" class="form-control" name="email" [(ngModel)]="data.email">
        </div>
        <div class="form-group mb-3">
            <label for="mobile">Mobile</label>
            <input type="text" id="mobile" class="form-control" name="mobile" [(ngModel)]="data.mobile">
        </div>
        <div class="form-group mb-3">
            <label for="name">From Source</label>
            <select class="form-control from-control-sm" name="from_source" [(ngModel)]="data.from_source">
                <option>Walk In</option>
                <option>Facebook</option>
                <option>Instagram</option>
                <option>Whatsapp</option>
                <option>Salesman</option>
                <option>Return Customer</option>
            </select>
        </div>
    <a class="btn btn-primary w-100 mb-3" (click)="create()">Create</a>
    <a class="btn btn-outline-primary w-100" (click)="close()">Close</a>

</form>