import { Component, ElementRef, ViewContainerRef, Input } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-new-section',
  templateUrl: './new-section.component.html',
  styleUrls: ['./new-section.component.css']
})
export class NewSectionComponent {

  private viewer: ViewContainerRef | undefined;

  @Input() set setViewer(val:ViewContainerRef) {
    this.viewer = val;
  };

  constructor(private builder: LexiPagebuilderService) { }
  
  addSection() {

    if(!this.viewer) {
      return;
    }

    let sections = this.builder.getSection();
    
    let uid = this.builder.builderIDGenerator();
    let component = {uid: uid, style:{"position_type": "d-flex", "flex_type": "flex-column", "flex_algin": "align-items-center"}, type: "section", components: []};
    sections.push(component)
    this.builder.setSection(sections)
    this.builder.generateSection(this.viewer, component)
//    this.createSection(component)
  }

}
