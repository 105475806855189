import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'lib-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.css']
})
export class SurveyFormComponent implements OnInit {

  public success:boolean = false;
  public submitted: any  = false;
  public uuid: any = null;
  public forms:any = []
  public data:any = {};
  public job:any = {};
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: ApiService
  ) {
    const form_uuid: Observable<string> = this.activatedRoute.params.pipe(map(p => p['uuid']));
    form_uuid.subscribe((val:any) => {
      if (val) {
        this.uuid = val;
      }
      this.init()
    })
  }

  ngOnInit(): void {
  }

  async init(){
    
    if(!this.uuid){
      return this.router.navigate(["/survey"])
    }

    const init:any = await this.http.post('/profile/validate/survey', {uuid:this.uuid});
    
    if(!init || !init.status){
      alert(init.message);
      return this.router.navigate(["/survey"])
    }
    
    this.job = init.job;

    this.forms = init.forms;
    this.forms.forEach((form:any) => {
      if (form['type'] == 'checkbox') {
        this.data[form['name']] = {};
      } else {
        this.data[form['name']] = null;
      }
    });

    this.data = {...this.data, ...init.data}
    return
  }

  async submit(){

    const form_data = this.data;

    for(var y = 0; y < this.forms.length; y++) {
      const form = this.forms[y];
      if (form['type'] == 'checkbox') {
        const current = form_data[form['name']];
        const val = Object.keys(current);
        form_data[form['name']] = [];

        if (current['other']) {
          form_data[form['name']].push(current['other'])
        }

        for(var x = 0; x < form['details']['selection'].length; x++) {
          if (form['details']['selection'][x]['checked']) {
            form_data[form['name']].push(form['details']['selection'][x]['input'])
          }
        }
      }

      if(!form_data[form['name']]  && form['required']){
        return alert(form['title'] + " is required.")
      }
      if(( Array.isArray(form_data[form['name']]) && form_data[form['name']].length == 0) && form['required'] ){
        return alert(form['title'] + " is required.")
      }
      
    }
    const rtn:any = await this.http.post('/profile/survey/submit',{uuid:this.uuid,data: form_data});

    if(rtn.status) {
      this.submitted = true;
      this.success = true;
    } else if (rtn.message) {
      this.success = false;
      alert(rtn.message)
    } else {
      this.success = false;
      alert("There was an error while trying to save the data. Please retry.");
    }

  }
}
