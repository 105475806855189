import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformComponent } from './platform.component';
import { PlatformRoutingModule } from './platform.routing';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { LexiCalendarModule } from 'lexi-calendar';
import { AppointmentCalendarComponent } from './appointment-calendar/appointment-calendar.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerAppointmentComponent } from './customer-appointment/customer-appointment.component';
import { CustomerJobComponent } from './customer-job/customer-job.component';
import { CustomerNewComponent } from './customer-new/customer-new.component';
import { CustomerJobRemarkComponent } from './customer-job-remark/customer-job-remark.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatComponent } from './chat/chat.component';
import { CalenderEventComponent } from './calender-event/calender-event.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryComponent } from './inventory/inventory.component';
import { ReportComponent } from './report/report.component';
import { LexiReportModule } from 'lexi-report';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { InspectionComponent } from './inspection/inspection.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgWhiteboardModule } from 'ng-whiteboard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OldWarrantyComponent } from './old-warranty/old-warranty.component';
import { SetReminderComponent } from './set-reminder/set-reminder.component';
import { SurveyDataComponent } from './survey-data/survey-data.component';
import { InventoryStatementComponent } from './inventory-statement/inventory-statement.component';

@NgModule({
  declarations: [
    PlatformComponent,
    DashboardComponent,
    AppointmentCalendarComponent,
    CustomerFormComponent,
    CustomerAppointmentComponent,
    CustomerJobComponent,
    CustomerNewComponent,
    CustomerJobRemarkComponent,
    ChatComponent,
    CalenderEventComponent,
    InventoryComponent,
    ReportComponent,
    AppointmentDetailComponent,
    InspectionComponent,
    ChangePasswordComponent,
    OldWarrantyComponent,
    SetReminderComponent,
    SurveyDataComponent,
    InventoryStatementComponent
  ],
  imports: [
    CommonModule,
    PlatformRoutingModule,
    FormsModule,
    LexiCalendarModule,
    NgSelectModule,
    NgbDatepickerModule,
    LexiReportModule,
    ClipboardModule,
    NgWhiteboardModule
  ],
  exports: [
  ]
})
export class PlatformModule { }
