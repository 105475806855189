import { NgModule } from '@angular/core';
import { LexiReportComponent } from './lexi-report.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';



@NgModule({
  declarations: [
    LexiReportComponent
  ],
  imports: [
    NgxChartsModule
  ],
  exports: [
    LexiReportComponent
  ]
})
export class LexiReportModule { }
