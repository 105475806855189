import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})

export class VideoComponent {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {

    if(!val) {
      val = {}
    }

    if(!val.value) {
      val.loop = true;
      val.autoplay = true;
      val.controls = true;
      val.value = "https://lexisoft98.b-cdn.net/resource/sample.mp4";
      val.poster = "https://lexisoft98.b-cdn.net/resource/videoplaceholder.jpg";
    }
    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }

    this.component = val;
  } 

  public component:any;

}
