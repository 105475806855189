import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { LexiDashforgeTopService } from './lexi-dashforge-top.service';

export function initializeAppFactory(ModuleService: LexiDashforgeTopService, 
  value: any) {  
  return () => {
    console.log(value)
    if (value) {
      ModuleService.setPostRoute(value)
    }
  };
}

export type ConfigFunction = () => any;

@NgModule({})
export class LexiDashforgeTopRoutingLoaderModule {
  static loadPostRoutes(config: {
    func: ConfigFunction,
    key: string
  }): ModuleWithProviders<LexiDashforgeTopRoutingLoaderModule> {
    const TOKEN = new InjectionToken(config.key + "_route");

    return { 
        ngModule: LexiDashforgeTopRoutingLoaderModule,
        providers: [
          { provide: TOKEN, useFactory: config.func },
          {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [LexiDashforgeTopService, TOKEN],
            multi: true
          }
        ]
    };
  }
}
