import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.css']
})
export class BuilderComponent implements AfterViewInit {

  public screen_size:number = 992;
  public options:any = {};
  public data:any;
  private doc:any;
  public langauge:any;

  @ViewChild("frame", {static: true}) frame!: ElementRef;

  constructor(
    private activateRouter: ActivatedRoute,
    private renderer: Renderer2,
    private vcr: ViewContainerRef
  ) { 
    this.activateRouter.params.subscribe((v:any) => {
     // this.frame_src = '/admin/builder/frame/' + v.page_uuid;
    })
  }
 
  ngAfterViewInit() {
  //  this.frame.nativeElement.setAttribute("src", this.frame_src)
    this.renderer.setStyle(this.frame.nativeElement, "width", "100%")
  }

  async createView() {
    this.doc = this.frame.nativeElement.contentDocument || this.frame.nativeElement.contentWindow;

    const section = ((await import("./view/view.component")).ViewComponent);
    const view = this.vcr.createComponent(section);
    view.instance.setDocument = this.doc;
    view.instance.updateLangauge = this.langauge;
    
    let style = document.createElement("link")
    style.rel = "stylesheet"
    style.href = "http://ns11.lexiglobal.my:40012/styles.css"

    this.doc.head.appendChild(style)
    this.doc.body.appendChild(view.location.nativeElement)
  }

  screenUpdate(size:any) {
    if(size > 900) {
      this.renderer.setStyle(this.frame.nativeElement, "width", "100%")
      return;      
    }
    this.renderer.setStyle(this.frame.nativeElement, "width", size + "px")
  }

  selectedElement($event:any) {
    this.options = $event;
  }

  optionUpdate($event:any) {
    this.options = $event
  }

  getData(data:any) {
    this.data = data;
  }

  themeUpdate(status: boolean) {
    if(status) {
      this.doc.head.innerHTML = ""
      this.doc.body.innerHTML = ""
      this.createView()
    }
  }

  langaugeUpdate(lang:string){
    if(this.langauge !== lang){
      this.langauge = lang;

      this.doc.head.innerHTML = "";
      this.doc.body.innerHTML = "";
      this.createView();
    }

    // this.createView()
  }
}
