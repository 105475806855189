import { Routes, RouterModule } from '@angular/router';
import { Inject, NgModule } from '@angular/core';
import { NegativeAuthGuard } from 'lexi-auth';
import { PositiveAuthGuard } from 'lexi-auth';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import("./template/pre/pre.module").then(m => m.PreModule), canActivate:[NegativeAuthGuard] },
  { path: 'post', loadChildren: () => import("./template/post/post.module").then(m => m.PostModule), canActivate:[PositiveAuthGuard] },
  { path: '**', pathMatch: 'full', loadChildren: () => import("./template/page-not-found/page-not-found.module").then(m => m.PageNotFoundModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class LexiDashforgeTopRoutingModule {
}


