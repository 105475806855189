<container-element [ngSwitch]="setup.type" class="switchSetting setting" *ngIf="!setup.dependency || (setup.dependency && component[setup.dependency])">

    <!-- General -->
    <ng-container *ngSwitchCase="'label'">
        <h5 class="mt-2 mb-2">{{setup.title}}</h5>
    </ng-container>

    <ng-container *ngSwitchCase="'notes'">
        <div class="mt-2 mb-2 fs-7 text-muted" [innerHTML]="setup.title"></div>
    </ng-container>

    <ng-container *ngSwitchCase="'seperator'">
        <hr/>
    </ng-container>







    <!-- Component -->
    <ng-container *ngSwitchCase="'text'">
        <p class="mt-2 mb-2">{{setup.title}} <em>*</em></p>
        <textarea class="w-100" rows="6" [(ngModel)]="component[setup.key]">
        </textarea>
    </ng-container>

    <ng-container *ngSwitchCase="'wysiwyg-simple'">
        <p class="mt-2 mb-2">{{setup.title}}</p>
        <lib-lexi-wysiwyg [setData]="component[setup.key]" [setType]="'text'" (getData)="getData($event)" [apikey]="'1iwwmm62kc88rij0jg9eg2ohomin38yjsuvrlwi4a39cnk6b'"></lib-lexi-wysiwyg>
    </ng-container>
    
    <ng-container *ngSwitchCase="'image'">
        <div class="btn-toolbar" role="toolbar">
            <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">{{setup.title}}</span>
            <button class="btn btn-sm rounded-0" (click)="selectMediaWithKey(setup.key)">{{setup.select_media_title}}</button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill" >{{setup.title}}</span> 
            <div class="toggler btn btn-secondary btn-xs position-relative p-0 rounded-0" (click)="toogleSetting(setup.key)" [ngClass]="{'active': component[setup.key]}">
                <div class="h-100 d-flex align-items-center mx-1 position-relative">
                    <span class="w-50 on">ON</span>
                    <span class="w-50 button d-flex align-items-center position-absolute border border-dark" >
                        <p class="text-center text-dark m-0 w-100">||</p>
                    </span>
                    <span class="w-50 off">OFF</span>
                </div>
            </div>
        </div>
    </ng-container>


    <!-- <ng-container *ngSwitchCase="'setting_accordion'">
        <div class="btn-toolbar mt-2 d-flex justify-content-between" role="toolbar">
            <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill" >{{setup.title}}</span> 
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24">
                <path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"/>
            </svg>
        </div>
    </ng-container> -->











    
    <ng-container *ngSwitchCase="'video'">
        <div class="btn-toolbar" role="toolbar">
            <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">{{setup.title}}</span>
            <button class="btn btn-sm rounded-0" (click)="selectMediaWithKey(setup.key)">{{setup.select_media_title}}</button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'html'">
        <p class="mt-2 mb-2">{{setup.title}}</p>
        <lib-lexi-wysiwyg [setData]="component.value" (getData)="getData($event)" [apikey]="'1iwwmm62kc88rij0jg9eg2ohomin38yjsuvrlwi4a39cnk6b'"></lib-lexi-wysiwyg>
    </ng-container>

    <ng-container *ngIf="component.type == 'map'">
        <p class="mt-2 mb-2">{{setup.Latitude}}</p>
        <textarea class="w-100" [(ngModel)]="component[setup.key]" (change)="triggerUpdate()">
        </textarea>
        <p class="mt-2 mb-2">{{setup.Longitude}}</p>
        <textarea class="w-100" [(ngModel)]="component[setup.key]" (change)="triggerUpdate()">
        </textarea>
    </ng-container>








    <!-- Style -->
    <ng-container *ngSwitchCase="'list-style'">
        <div class="btn-toolbar" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.list_style]" class="form-control-sm form-control-modify rounded-0" >
                    <option [value]="'lst-none'">None</option>
                    <option [value]="'lst-disc'">Description</option>
                    <option [value]="'lst-circle'">Circle</option>
                    <option [value]="'lst-square'">Square</option>
                    <option [value]="'lst-decimal'">Decimal</option>
                    <option [value]="'lst-lower-alpha'">Lower-Alpha</option>
                    <option [value]="'lst-upper-alpha'">Upper-Alpha</option>
                    <option [value]="'lst-lower-roman'">Lower-Roman</option>
                    <option [value]="'lst-upper-roman'">Upper-Roman</option>
                    <option [value]="'lst-cjk-Ideographic'">CJK-Ideographic</option>
                </select>     
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngSwitchCase="'style-general-button-color'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="null">None</option>
                    <option [value]="'btn-outline-primary'">Outline Primary</option>
                    <option [value]="'btn-outline-secondary'">Outline Secondary</option>
                    <option [value]="'btn-outline-success'">Outline Success</option>
                    <option [value]="'btn-outline-danger'">Outline Danger</option>
                    <option [value]="'btn-outline-warning'">Outline Warning</option>
                    <option [value]="'btn-outline-info'">Outline Info</option>
                    <option [value]="'btn-outline-light'">Outline Light</option>
                    <option [value]="'btn-outline-dark'">Outline Dark</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-button-color'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="null">None</option>
                    <option [value]="'btn-primary'">Primary</option>
                    <option [value]="'btn-secondary'">Secondary</option>
                    <option [value]="'btn-success'">Success</option>
                    <option [value]="'btn-danger'">Danger</option>
                    <option [value]="'btn-warning'">Warning</option>
                    <option [value]="'btn-info'">Info</option>
                    <option [value]="'btn-light'">Light</option>
                    <option [value]="'btn-dark'">Dark</option>
                    <option [value]="'btn-link'">Link</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-button-size'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'btn-sm', setup.style_key ?? 'style')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'btn-sm'}">Small</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '', setup.style_key ?? 'style')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'btn-lg', setup.style_key ?? 'style')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'btn-lg'}">Large</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-border-width'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'bdw-1')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'bdw-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'bdw-2')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'bdw-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'bdw-3')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'bdw-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'bdw-4')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'bdw-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'bdw-5')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'bdw-5'}">5</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-border-style'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="'bds-none'">None</option>
                    <option [value]="'bds-solid'">Solid</option>
                    <option [value]="'bds-dotted'">Dotted</option>
                    <option [value]="'bds-dashed'">Dashed</option>
                    <option [value]="'bds-inset'">Inset</option>
                    <option [value]="'bds-outset'">Outset</option>
                    <option [value]="'bds-ridge'">Ridge</option>
                    <option [value]="'bds-groove'">Groove</option>
                    <option [value]="'bds-double'">Double</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-border-color'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="">None</option>
                    <option [value]="'bdc-primary'">Primary Color</option>
                    <option [value]="'bdc-secondary'">Secondary Color</option>
                    <option [value]="'bdc-tertiary'">Tertiary Color</option>
                    <option [value]="'bdc-quaternary'">Quaternary Color</option>
                    <option [value]="'bdc-quinary'">Quinary Color</option>
                    <option [value]="'bdc-dark'">Dark Color</option>
                    <option [value]="'bdc-light'">Light Color</option>
                    <option [value]="'bdc-error'">Error Color</option>
                    <option [value]="'bdc-success'">Success Color</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-corner'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-0')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-0'}">0</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-1')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-2')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-3')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-4')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-5')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-5'}">5</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-6')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-6'}">6</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-7')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-7'}">7</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-8')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-8'}">8</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-9')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-9'}">9</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'rounded-10')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'rounded-10'}">10</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-font-size'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-1')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-2')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-3')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-4')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-5')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-5'}">5</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-6')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-6'}">6</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-7')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-7'}">7</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fs-8')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fs-8'}">8</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-font-color'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="">None</option>
                    <option [value]="'text-primary'">Primary Color</option>
                    <option [value]="'text-secondary'">Secondary Color</option>
                    <option [value]="'text-tertiary'">Tertiary Color</option>
                    <option [value]="'text-quaternary'">Quaternary Color</option>
                    <option [value]="'text-quinary'">Quinary Color</option>
                    <option [value]="'text-dark'">Dark Color</option>
                    <option [value]="'text-light'">Light Color</option>
                    <option [value]="'text-danger'">Error Color</option>
                    <option [value]="'text-success'">Success Color</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-font-family'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.style_key ?? 'style'][setup.key]" class="form-control-sm form-control-modify rounded-0" (change)="triggerUpdate()">
                    <option [value]="">None</option>
                    <option [value]="'font-primary'">Primary Font</option>
                    <option [value]="'font-secondary'">Secondary Font</option>
                    <option [value]="'font-tertiary'">Tertiary Font</option>
                    <option [value]="'font-quaternary'">Quaternary Font</option>
                </select>     
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-font-weight'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fw-lighter')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fw-lighter'}">Lighter</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fw-light')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fw-light'}">Light</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fw-normal')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fw-normal'}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fw-bold')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fw-bold'}">Bold</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fw-bolder')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fw-bolder'}">Bolder</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-font-style'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">None</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'fst-italic')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'fst-italic'}">Italic</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-text-align'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-start')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M14 15H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zm0-8H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM4 13h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0 8h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 4c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-center')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M18,15 C18.5522847,15 19,15.4477153 19,16 C19,16.5522847 18.5522847,17 18,17 L6,17 C5.44771525,17 5,16.5522847 5,16 C5,15.4477153 5.44771525,15 6,15 L18,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M18,7 C18.5522847,7 19,7.44771525 19,8 C19,8.55228475 18.5522847,9 18,9 L6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 L18,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-end')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 L9,9 C8.44771525,9 8,8.55228475 8,8 C8,7.44771525 8.44771525,7 9,7 L21,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-justify')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-justify'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 C2,15.4477153 2.44771525,15 3,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 L3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 L21,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-text-decoration'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">None</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-decoration-underline')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-decoration-underline'}">Underline</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'text-decoration-line-through')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'text-decoration-line-through'}">Line-through</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-letter-spacing'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'ls-1')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'ls-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'ls-2')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'ls-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'ls-3')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'ls-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'ls-4')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'ls-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'ls-5')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'ls-5'}">5</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'style-line-height'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, '')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == '' || !component[setup.style_key ?? 'style'][setup.key]}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-1')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-sm')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-sm'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-base')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-base'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-lg')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-lg'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-5')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-5'}">5</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-6')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-6'}">6</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-7')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-7'}">7</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle(setup.key, 'lh-8')" [ngClass]="{'active': component[setup.style_key ?? 'style'][setup.key] == 'lh-8'}">8</button>
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngSwitchCase="'number'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
               <input type="number" [(ngModel)]="component[setup.key]" min="1" value="5" max="10" step="1">  
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'text-heading'">
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">{{setup.title}}</span>
            <div class="btn-group btn-group-sm" role="group">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'paragraph')" [ngClass]="{'active': component[setup.key] == 'paragraph'}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_1')" [ngClass]="{'active': component[setup.key] == 'heading_1'}">h1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_2')" [ngClass]="{'active': component[setup.key] == 'heading_2'}">h2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_3')" [ngClass]="{'active': component[setup.key] == 'heading_3'}">h3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_4')" [ngClass]="{'active': component[setup.key] == 'heading_4'}">h4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_5')" [ngClass]="{'active': component[setup.key] == 'heading_5'}">h5</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setType(setup.key, 'heading_6')" [ngClass]="{'active': component[setup.key] == 'heading_6'}">h6</button>
                </div>
            </div>
        </div>
    </ng-container>


    
    <!-- template -->
    <ng-container *ngSwitchCase="'slide'">
        <p class="mt-2 mb-2">{{setup.title}}</p>
        <div class="btn-toolbar" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">SIZE</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.size]" class="form-control-modify form-control-sm rounded-0">
                    <option [value]="">Normal</option>
                    <option [value]="'fs-4'">Middle</option>
                    <option [value]="'fs-2'">Large</option>
                    <option [value]="'fs-1'">Extra Large</option>
                </select>    
            </div>
        </div>
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">COLOR</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.colors]" class="form-control-sm form-control-modify rounded-0" [ngStyle]="{'appearance': 'auto'}">
                    <option [value]="null">None</option>
                    <option [value]="'text-primary'">Primary</option>
                    <option [value]="'text-secondary'">Secondary</option>
                    <option [value]="'text-success'">Success</option>
                    <option [value]="'text-danger'">Danger</option>
                    <option [value]="'text-warning'">Warning</option>
                    <option [value]="'text-info'">Info</option>
                    <option [value]="'text-light'">Light</option>
                    <option [value]="'text-dark'">Dark</option>
                    <option [value]="'text-body'">Body</option>
                    <option [value]="'text-muted'">Muted</option>
                    <option [value]="'text-white bg-dark'">White</option>
                    <option [value]="'text-black-50'">Black-50</option>
                    <option [value]="'text-white-50 bg-dark'">White-50</option>
                </select>     
            </div>
        </div>
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">FONT FAMILY</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.font_family]" class="form-control-modify form-control-sm rounded-0">
                    <option [value]="">None</option>
                    <option [value]="'font-primary'">Primary Font</option>
                    <option [value]="'font-secondary'">Secondary Font</option>
                    <option [value]="'font-tertiary'">Tertiary Font</option>
                    <option [value]="'font-quaternary'">Quaternary Font</option>
                    <option [value]="'font-quinary'">Quinary Font</option>
                </select>    
            </div>
        </div>

    </ng-container>
    
   




    <ng-container *ngSwitchCase="'accordion'">
        <p class="mt-2 mb-2">{{setup.title}}</p>
        <div class="btn-toolbar" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">SIZE</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.size]" class="form-control-modify form-control-sm rounded-0">
                    <option [value]="">Normal</option>
                    <option [value]="'fs-4'">Middle</option>
                    <option [value]="'fs-2'">Large</option>
                    <option [value]="'fs-1'">Extra Large</option>
                </select>    
            </div>
        </div>
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">COLOR</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.colors]" class="form-control-sm form-control-modify rounded-0" [ngStyle]="{'appearance': 'auto'}">
                    <option [value]="null">None</option>
                    <option [value]="'text-primary'">Primary</option>
                    <option [value]="'text-secondary'">Secondary</option>
                    <option [value]="'text-success'">Success</option>
                    <option [value]="'text-danger'">Danger</option>
                    <option [value]="'text-warning'">Warning</option>
                    <option [value]="'text-info'">Info</option>
                    <option [value]="'text-light'">Light</option>
                    <option [value]="'text-dark'">Dark</option>
                    <option [value]="'text-body'">Body</option>
                    <option [value]="'text-muted'">Muted</option>
                    <option [value]="'text-white bg-dark'">White</option>
                    <option [value]="'text-black-50'">Black-50</option>
                    <option [value]="'text-white-50 bg-dark'">White-50</option>
                </select>     
            </div>
        </div>
        <div class="btn-toolbar mt-2" role="toolbar">
            <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">FONT FAMILY</span>
            <div class="btn-group btn-group-sm" role="group">
                <select [(ngModel)]="component[setup.font_family]" class="form-control-modify form-control-sm rounded-0">
                    <option [value]="">None</option>
                    <option [value]="'font-primary'">Primary Font</option>
                    <option [value]="'font-secondary'">Secondary Font</option>
                    <option [value]="'font-tertiary'">Tertiary Font</option>
                    <option [value]="'font-quaternary'">Quaternary Font</option>
                    <option [value]="'font-quinary'">Quinary Font</option>
                </select>    
            </div>
        </div>
    </ng-container>






    <ng-container *ngSwitchDefault>
        <p>Default</p>
    </ng-container>





    
</container-element>