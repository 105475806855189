import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {
    if(!val.value) {
      val.value = "ENTER BUTTON VALUE"
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {"btn": "btn", "default_color":"btn-primary"};
    }

    if(!val.style.btn) {
      val.style.btn = 'btn';
    }

    this.component = val;
  } 

  public component:any;
  constructor() {  }


}
