import { Component, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent {

  public theme : any = {
    primary_color: "#fff",
    secondary_color: "#fff",
    tertiary_color: "#fff",
    quaternary_color: "#fff",
    quinary_color: "#fff",
    primary_font: null,
    secondary_font: null,
    tertiary_font: null,
    quaternary_font: null,
  }

  public fonts:any = [  ];

  @Output() close = new EventEmitter<boolean>();

  constructor(private api: ApiService) {
    this.getFonts();
    this.getTheme();
  }

  async saveTheme() {
    let rtn = await this.api.post("/page/theme/save", {theme: this.theme});

    if(rtn.status) {
      this.close.emit(true);
    }
  }

  async closeTheme() {
    this.close.emit(false);
  }

  async getTheme() {
    let rtn = await this.api.post("/page/theme/get", {});

    if(rtn.theme && rtn.theme.value) {
      this.theme = rtn.theme.value;
    }
    console.log(rtn, this.theme)
  }
  async getFonts() {
    let rtn = await this.api.cachePost("/page/get/font-style", {});
    this.fonts = rtn.fonts;

    let head = document.getElementsByTagName("head")[0];

    let query = head.querySelectorAll("[import='true']");

    if(query.length > 0) {
      return;
    }

    for(var x = 0; x < this.fonts.length; x++) {

      if(!this.fonts[x].import || this.fonts[x].import == "false") {
        continue;
      }

      let link = document.createElement("link")
      link.href = this.fonts[x].import
      link.rel = "stylesheet"
      link.setAttribute("import", "true")
      head.append(link)
    }

  }
}
