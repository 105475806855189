<h3 class="mb-2">Dashboard</h3>
<div class="row">
    <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title mb-0">Upcoming Reminder</h5>
            </div>
            <div class="card-body p-0" *ngIf="data.reminders.length > 0">
                <table class="table table-sm mb-0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Plate No</th>
                            <th>View</th>
                        </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let reminder of data.reminders">
                            <td>{{reminder.notify_date}}</td>
                            <td>{{reminder.plate_no}}</td>
                            <td>
                                <a [routerLink]="['/customer/job', reminder.job_uuid]">VIEW</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body" *ngIf="data.reminders.length == 0">
                <p class="mb-0" >There are no relevant coating reminder available.</p>
            </div>
        </div>
    </div>
</div>
