import { Component, OnInit } from '@angular/core';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'lib-pre',
  templateUrl: './pre.component.html',
  styleUrls: ['./pre.component.css']
})
export class PreComponent implements OnInit {
  private config: any;

  constructor(private service: LexiDashforgeTopService, private titleService:Title) { 
    this.config = this.service.get();
  }

  ngOnInit(): void {
    if (this.config.title) {
      this.titleService.setTitle(this.config.title);
    }
  } 

}
