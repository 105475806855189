<div #wrapper>
    <div class="d-flex justify-content-center align-items-center">
        <!-- <div *ngIf="apiLoaded" async>
            <google-map [options]="options"></google-map>
        </div> -->
        <div>
            <!-- Google Map Copied Code -->
            <iframe src=
"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.2233913121413!2d77.4051603706222!3d28.50292593193056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce626851f7009%3A0x621185133cfd1ad1!2sGeeksforGeeks!5e0!3m2!1sen!2sin!4v1585040658255!5m2!1sen!2sin"
                    width="500vh"
                    height="500vh"
                    frameborder="0"
                    style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0">
            </iframe>
        </div>
    </div>
</div>
