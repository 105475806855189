<div class="row">
    <div class="col-12 col-md-2" *ngIf="editable">
        <div class="d-flex flex-column mb-3">
            <!-- <label for="branch" class="me-2">Select Branch</label> -->
            <div class="btn-group" role="group" aria-label="Basic example">
                <!-- <select class="form-control" id="branch" [(ngModel)]="branch" (change)="getInventory()">
                    <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
                </select> -->
                <button class="btn btn-primary" (click)="save()">UPDATE INVENTORY</button>
              </div>
        </div>
    </div>
    <div class="col-12 col-md-3" >
        <div class="d-flex flex-row mb-3">
            <span>Search Title</span>
            <input class="form-control form-control-sm" (input)="search($event)" />
        </div>
    </div>

</div>

<table class="table table-sm table-striped">
    <thead>
        <tr>
            <th>Code</th>
            <th>Title</th>
            <th>Balance</th>
            <th *ngIf="editable">Add (Square Inch)</th>
            <th *ngIf="editable">Deduct (Square Inch)</th>
            <th *ngIf="editable">Balance (Square Inch)</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let inventory of filters">
            <td>{{inventory.code | uppercase}}</td>
            <td>{{inventory.title}}</td>
            <td>{{inventory.balance | number: '1.2-2'}}</td>
            <td *ngIf="editable"><input class="form-control form-control-sm" type="number" [(ngModel)]="inventory.debit" (input)="calc(inventory)"/></td>
            <td *ngIf="editable"><input class="form-control form-control-sm" type="number" [(ngModel)]="inventory.credit" (input)="calc(inventory)"/></td>
            <td *ngIf="editable">{{inventory.new_balance ?? 0 | number: '1.2-2'}}</td>
        </tr>
    </tbody>
</table>