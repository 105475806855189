import { Component, AfterViewInit, Inject } from '@angular/core';
import { MenuHelperService } from 'menu-helper';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError} from '@angular/router';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';
import { LexiAuthService } from 'lexi-auth';
import { ApiService } from 'lexi-api';
import { DsmeDataService } from 'dsme-data';
declare var PerfectScrollbar: any;
declare var $: any;  
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageComponent } from '../language/language.component';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit {

  public config : any;
  public profile_path : any;
  public signout_api : any;
  public login_path : any;
  public create_profile_path : any;
  public menu: any = {};
  public user: any = {};

  public dsme: boolean = false;
  public selected_profile:any = {}
  public profiles: any = [];
  public loading_profile:boolean = true;

  public page_loading:boolean = false;
  
  constructor(
    private MenuHelper: MenuHelperService,
    private service: LexiDashforgeTopService,
    private auth: LexiAuthService,
    private router: Router,
    private api: ApiService,
    private data: DsmeDataService,
    private ngbModal: NgbModal,
    @Inject("environment") environment: any) { 

    this.dsme = environment.dsme ?? false;

    this.getProfiles();

    this.profile_path = environment.profile_path ?? ['/post/user/profile']
    this.login_path = environment.login_path ?? ["/auth/login"]
    this.create_profile_path = environment.create_profile_path ?? ['/post/profile/create']
    this.signout_api = environment.signout_api ?? '/user/signout';

    this.config = this.service.get();
    this.router.events.subscribe(event =>{
      if(event instanceof NavigationStart) {
        this.page_loading = true;
      }

      if(event instanceof NavigationCancel || event instanceof NavigationError) {
        this.page_loading = false;
      }

      if (event instanceof NavigationEnd){
        this.page_loading = false;
        this.MenuHelper.setCurrentPath(event.url);
      }
    })

    this.data.profileOnChange.subscribe(async (profile) => {

      this.api.valueAppender("profile_uuid", this.data.getSelectedProfile().uuid)

      this.MenuHelper.reset()
      let m = await this.api.post("/user/menu/get", {profile_uuid: this.data.getSelectedProfile().uuid});
      this.MenuHelper.set(m.menu)
      this.menu = this.MenuHelper.get();
      setTimeout(() => {
        this.initMenu();
      }, 0)

    })

    this.getUser();

  }

  async getUser() {
    this.user = await this.auth.getUser();
    let selectedProfile = this.data.getSelectedProfile();
    if(selectedProfile) { 
      let m = await this.api.post("/user/menu/get", {profile_uuid: selectedProfile.uuid});
    }
    this.initMenu();
  }

  activate(menu: any) : boolean {
    return this.MenuHelper.activate(menu);
  }
  
  async getProfiles() {

    if (!this.dsme) {
      return;
    }

    this.profiles = await this.data.getProfiles();
    let profile = this.data.getSelectedProfile();
    this.selected_profile = profile;

    this.loading_profile = false;
  }
  
  switchProfile(profile:any) {
    this.data.setProfile(profile)
    this.selected_profile = profile;

    setTimeout(() => {
      let paths = this.router.url.split("/");
      paths[0] = "/"
      this.router.navigateByUrl('/', {skipLocationChange: true})
        .then(() => this.router.navigate(paths));  
    }, 1000)

  }

  ngAfterViewInit() : void {

    $('body').append('<div class="backdrop"></div>');

    
  }
  
  async signout() {
    this.api.post(this.signout_api, {});
    this.api.valueAppender("token", null);
    this.auth.update(false)
    this.auth.signout(this.login_path);
  }

  switchLanguage() {
    let modal = this.ngbModal.open(LanguageComponent, {backdrop: true});
    modal.componentInstance.closeEvent.subscribe((val:boolean) => { if(!val) {return} modal.close() })
  }

  initMenu() {
    var ps : any = null;

    const initPerfectScroll = function() {
      if (ps) {
        return;
      }

      ps = new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true,
        wheelSpeed: 2,
        wheelPropagation: true,
      });
    }

    if(window.matchMedia('(max-width: 991px)').matches) {
      initPerfectScroll();
    }

    $('#mainMenuOpen').on('click touchstart', () => {
      $('body').addClass('navbar-nav-show');
      $('.backdrop').addClass('show');
    });

    $('#mainMenuClose').on('click', () => {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    });

    $('.navbar-menu .with-sub .nav-link').click(function(this: typeof $) {

      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');
  
      if(window.matchMedia('(max-width: 991px)').matches) {
        initPerfectScroll();
        ps.update();
      }
    });

    $('.backdrop').click(function() {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    })

    $('.nav-sub-link').click(function() {
      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').removeClass('navbar-nav-show');
        $('.backdrop').removeClass('show');
      } else {
        $('.nav-item.with-sub').removeClass('show');
      }
    });

  }
}
