<div class="w-100 vh-100 bg-black d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="col-12 col-md-4">
            <img class="mb-4" src="https://static-dsme.sgp1.digitaloceanspaces.com/profile/70e9cee8-7e65-565f-946f-4f7b06558196/hamel_logo.svg" style="height:30px;" />
            
            <h4 class="text-light">Tell Us What You Think</h4>
            <p class="text-muted">Share your thoughts and help us improve! Take Hamel Malaysia's quick survey on our tinting, coating, and paint protection film solutions, as well as our customer service and overall experience. Your feedback is important to us!</p>
            <form (submit)="submit()">
                <div class="form-group text-light">
                    <label class="mb-2">Please Enter Your Car Plate Number<em class="form-required text-danger mx-1" >*</em></label>
                    <input type="text" [(ngModel)]="car_plate"  name="car_plate" class="form-control ">
                </div>
                <div class="text-danger" *ngIf="error">
                    <small>{{error}}</small>
                </div>
                <div class="form-group mb-0 mt-3">
                    <input type="submit" value="Submit" class="btn btn-primary py-3 px-5">
                </div>
                <div *ngIf="jobs.length > 0">
                    <p class="text-light mt-3">Below are the pending survey available. Select pending survey to continue</p>
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between" role="button" *ngFor="let job of jobs" (click)="getToSurvey(job.uuid)"><span>{{job.plate_no}}</span><span>{{job.create_on | date}}</span></li>
                      </ul>
                </div>
                
            </form>
        </div>
    </div>
</div>