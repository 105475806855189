import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent {

  public page: any = {style:{}};
  public loading: boolean = false;
  @Input() set setPage(val:any) {
    this.page = val;
  }
  @Output() listenSave = new EventEmitter<boolean>();
  
  constructor(private api: ApiService) {
  }

  async saveMetaData() {
    console.log(this.page)
    this.loading = true;
    await this.api.post("/page/save-meta", this.page);
    this.listenSave.emit(true)
    this.loading = false;
  }

}
