<div class="row">
    <div class="col-12 col-md-4">
        <h4>Warranty Operation</h4>
        <hr/>
        <label for="customer">Search Customer's Old Warranty</label>
        <form method="post" (submit)="search()">
            <div class="input-group mb-3">
                <input type="text" id="customer" name="search" class="form-control" placeholder="Search Customer By name, phone, email or car plate" [(ngModel)]="val.search" />
                <button class="btn btn-primary" type="submit">Search</button>
            </div>
        </form>
    </div>
</div>

<div class="row" *ngIf="results.length > 0">
    <div class="col-12 col-md-6">
        <table class="w-100">
            <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Plate No</th>
                <th>Modal</th>
                <th>INV No</th>
                <th>Installation</th>
                <th>Tint</th>
                <th>Coating</th>
            </tr>
            <tr *ngFor="let result of results; let i = index">
                <td>{{i + 1}}</td>
                <td class="text-nowrap px-2">{{result.name}}</td>
                <td class="px-2 text-nowrap">{{result.email_address}}</td>
                <td class="px-2 text-nowrap">{{result.mobile_no}}</td>
                <td class="px-2 text-nowrap">{{result.plate_no}}</td>
                <td class="px-2 text-nowrap">{{result.modal}}</td>
                <td class="px-2 text-nowrap">{{result.invoice_no}}</td>
                <td class="px-2 text-nowrap">{{result.installation_date | date}}</td>
                <td class="px-2 text-nowrap">{{result.warranty}}</td>
                <td class="px-2 text-nowrap">{{result.coating}}</td>
                
            </tr>
        </table>
    </div>
</div>
