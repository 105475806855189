import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.css']
})
export class RowComponent implements AfterViewInit {

  @Input() position:any;
  @Input() set setComponent(val:any) {

    if(!val.style) {
      val.style = {'row': 'row'}
    }
    if(!val.wrapper_style) {
      val.wrapper_style = {'width': 'container-fluid'}
    }
    this.component = val;
  };

  @ViewChild("rowWrapper", {read: ViewContainerRef}) rowWrapper!: ViewContainerRef;
  @ViewChild("wrapper", {static: true}) wrapper! : ElementRef;

  public component:any = {};

  constructor(private builder: LexiPagebuilderService) { }

  ngAfterViewInit(): void {
    this.buildColumns();
  }

  async buildColumns() {
    if(this.component.components && this.component.components.length > 0) {
      for(var x = 0; x < this.component.components.length; x++) {
        const component = ((await import("../cell/cell.component")).CellComponent);
        const componentInstance = this.rowWrapper.createComponent(component);
        let current = this.component.components[x];

        current.type = "COLUMN"
        if(current.size) {
          componentInstance.location.nativeElement.classList = current.size;
        }

        this.builder.setStyle(componentInstance.location.nativeElement, current)
        componentInstance.location.nativeElement.classList.add("d-flex");
        componentInstance.location.nativeElement.classList.add("flex-column");
        componentInstance.location.nativeElement.classList.add("align-items-center");

        if(this.builder.editable) {
          componentInstance.location.nativeElement.classList.add("position-relative");
          componentInstance.location.nativeElement.classList.add("component");
        }

        componentInstance.instance.setComponent = current;
    //    this.builder.appendEditComponent(componentInstance.location, current.uid)
        componentInstance.instance.buildComplete.subscribe((v:any) => {
          this.builder.appendEditComponent(componentInstance.instance, current.uid, componentInstance.location)
//          this.builder.appendEditComponentAppendToVC(componentInstance.instance.content, current.uid)
          this.builder.appendAddComponent(componentInstance.instance.content, current.uid);
        })

      }
    }
  //  this.builder.appendEditComponent(this.rowWrapper, this.component.uid, true)
  }

}
