<h3>Survey Data</h3>
<div class="d-flex flex-row ">
    <select class="form-control rounded-0" id="branch" name="branch" [(ngModel)]="data.branch_uuid" required>
        <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
    </select>
    <input class="form-control rounded-0" type="date" placeholder="Date From" [(ngModel)]="data.date_from"/>
    <input class="form-control rounded-0" type="date" placeholder="Date To" [(ngModel)]="data.date_to"/>
    <input class="form-control flex-fill rounded-0" type="text" placeholder="Search By Name, Email or Mobile" [(ngModel)]="data.search"/>
    <button class="btn btn-primary rounded-0" (click)="filter()">Filter</button>
    <button class="btn btn-outline-primary rounded-0" (click)="export()">Export</button>
</div>
<hr/>
<table class="table table-sm table-striped small">
    <thead>
        <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Data</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let survey of surveys">
            <td>{{survey.value}}</td>
            <td>{{survey.create_on | date: 'd-M-Y'}}</td>
            <td>
                <p class="mb-0" *ngFor="let content of survey.survey"><small [innerHTML]="content"></small></p>
            </td>
        </tr>
    </tbody>
</table>