import { Component, Input, AfterViewInit, ViewContainerRef, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.css']
})
export class CellComponent implements AfterViewInit {

  @ViewChild("content", {read: ViewContainerRef}) content! : ViewContainerRef
  @ViewChild("wrapper", {static: true}) wrapper! : ElementRef

  public components:any;

  @Input() set setComponent (val: any) {
    this.components = val;
  };

  @Output() buildComplete = new EventEmitter<ViewContainerRef>();

  constructor(private builder: LexiPagebuilderService) { }

  ngAfterViewInit(): void {
    this.buildCell();
  }

  async buildCell() {

    if(!this.components || !this.components.components || this.components.components.length == 0) {
      this.buildComplete.emit(this.content);
      return;
    }

    let components = this.components.components;

    for(var x = 0; x < components.length; x++) {
      let current = components[x]
      await this.builder.generateComponentType(current.type, this.content, current);      
    }

    this.buildComplete.emit(this.content);
  }

}
