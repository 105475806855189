<div class="container pt-4">
    <div class="form-group mb-3 row g-0">
        <label for="page" class="form-label col-4 m-0">Select page<em>*</em></label>
        <select class="form-control col" id="page" [(ngModel)]="data" name="data">
            <option *ngFor="let page of pages" value="{{page.language}}">{{page.language_title}}</option>
        </select>
    </div>

    <hr/>
    <div class="d-flex justify-content-evenly mb-4">
        <button type="submit" class="btn btn-primary w-100" (click)="submit()">Confirm</button>
    </div>
</div>