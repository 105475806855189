import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LexiCalendarComponent, LexiCalendarModule } from 'lexi-calendar';
import { LexiDashforgeTopModule, LexiDashforgeTopOptions } from 'lexi-dashforge-top';
import { FormModule } from 'lexi-form';
import { LexiTranslationCoreModule, LexiTranslationModule } from 'lexi-translation';
import { ColorPickerModule } from 'ngx-color-picker';
import { PlatformModule } from 'platform';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';

const config : LexiDashforgeTopOptions = {
  app_title : "Hamel&nbsp;<span>Marketing</span>",
  app_desc : "Hamel Window Film",
  company_name: "Hamel Malaysia Sdn Bhd",
  web_url: "https://digitalsme.tech/v2",
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PlatformModule,
    LexiDashforgeTopModule.forRoot(config),
    LexiTranslationCoreModule,
    LexiTranslationModule.load({func: () => {
      return [{path:"./assets/test/i18n/"}]
    }, key: "dashforge"}),
    ColorPickerModule
  ],
  providers: [
    {provide: "environment", useValue: environment}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
