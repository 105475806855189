import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-survey-entry',
  templateUrl: './survey-entry.component.html',
  styleUrls: ['./survey-entry.component.css']
})
export class SurveyEntryComponent {

  public car_plate: any;
  public error:any;
  public jobs:any = [];
  constructor(
    private api: ApiService,
    private router: Router
  ) { }

  async submit(){
    this.error = null;
    const rtn:any = await this.api.post('/profile/validate/carplate', {car_plate: this.car_plate});

    if(!rtn){
      this.error = "There was an error while retriving the data. please retry."
      return
    } else if (rtn.jobs.length == 0) {
      this.error = "There is no pending survey to filled."
      return
    }

    this.jobs = rtn.jobs;
  }

  getToSurvey(uuid:string) {
    return this.router.navigate(["/survey", uuid])
  }
}
