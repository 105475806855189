import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { LexiDashforgeTopOptions } from './lexi-dashforge-top.options';
import { LexiDashforgeTopService } from './lexi-dashforge-top.service';
import { LexiDashforgeTopRoutingModule } from './lexi-dashforge-top-routing.module';
import { MenuHelperModule } from 'menu-helper';
import { ModuleMenu } from './lexi-dashforge-top.menu';
import { RouterModule } from '@angular/router';
import { DsmeDataService } from 'dsme-data';
import { LexiTranslationModule } from 'lexi-translation';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    LexiDashforgeTopRoutingModule,
    MenuHelperModule.forRoot({ func: ModuleMenu, key: "dashforge" }),
    LexiTranslationModule.load({func: () => {
      return [{path:"./assets/lexi-dashforge-top/i18n/"}]
    }, key: "dashforge"})
  ],
  providers: [
    DsmeDataService
  ]
})

export class LexiDashforgeTopModule {
  static forRoot(config?: LexiDashforgeTopOptions): ModuleWithProviders<LexiDashforgeTopModule> {
    return { 
        ngModule: LexiDashforgeTopModule,
        providers: [
          LexiDashforgeTopService, 
          { provide: 'config', useValue: config }
        ]
    };
  }
}
