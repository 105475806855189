<h3>Report</h3>
<div class="d-flex flex-row col-6">
    <select class="form-control" id="branch" name="branch" [(ngModel)]="data.branch_uuid" required>
        <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
    </select>
    <input class="form-control" type="date" placeholder="Date From" [(ngModel)]="data.date_from" required/>
    <input class="form-control" type="date" placeholder="Date To" [(ngModel)]="data.date_to" required/>
    <button class="btn btn-primary" (click)="filter()">Filter</button>
</div>
<div class="p-3">
<div class="row">
    <div class="col-6">
        <h5>Total Sales from each branches accumulate to Overall Sales</h5>
        <ng-template #template1></ng-template>
    </div>
    <div class="col-6">
        <h5>Total % of Sales Value from different product category </h5>
        <ng-template #template2></ng-template>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <h5>How many ppl will recommend HAMEL? (Yes, No, Maybe) in %</h5>
        <ng-template #template9></ng-template>
    </div>
    <div class="col-6">
        <h5>How many new, old, recon cars in each product category in % (car_type by cat)</h5>
        <ng-template #template4></ng-template>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <h5>How many walk in, fb, insta, whatsapp, salesman & return customer </h5>
        <ng-template #template5></ng-template>
    </div>
    <div class="col-6">
        <h5>How many first-time customer & return customer in %</h5>
        <ng-template #template6></ng-template>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <h5>Where did customer hear of HAMEL from</h5>
        <ng-template #template7></ng-template>
    </div>
    <div class="col-6">
        <h5>Why customer choose Hamel</h5>
        <ng-template #template8></ng-template>
    </div>
</div>
<div class="row">
    <div class="col-8">
        <h5>Breakdown (all branches) of each product category in % (Product Percentage by Category)</h5>
        <ng-template #template3></ng-template>
    </div>
</div>
</div>