import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'lib-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.css']
})
export class ComponentComponent {

  public showLayout: boolean = true;
  @Input() set setShowLayout (val: boolean) {
    this.showLayout = val;
    setTimeout(() => {
      this.initOnClick();
    },0)
  }
  @Output() getSelectedComponent = new EventEmitter<string>();

  constructor() { }

  initOnClick() {
    var headers = document.getElementsByClassName("header")

    for(var x = 0; x < headers.length; x++) {
      let header = headers[x];

      header.addEventListener("click", (event) => {

        let sibling = header.nextElementSibling;
        if(!sibling) {
          return;
        }

        for(var y = 0; y < headers.length; y++) {
          let otherHeader = headers[y]
          let othersibling = otherHeader.nextElementSibling;
          if(othersibling && othersibling != sibling) {
            otherHeader.classList.remove("active");
            othersibling.classList.remove("active");
          }
        }

        header.classList.toggle("active");
        sibling.classList.toggle("active");
      })
    }
  }

  select(type:string) {
    this.getSelectedComponent.emit(type);
  }
}
