import { ElementRef, EmbeddedViewRef, Injectable, Renderer2, RendererFactory2, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LexiPagebuilderService {

  private component:any;
  public editable: boolean = false;
  private renderer : Renderer2;
  private sections : any = [];
  private vcr: ViewContainerRef | undefined;
  private _dataEmitter: any;
  private doc:any;
  public mapApi: any;
  public mapObservable: Observable<boolean>;
  public langauge:any;
  public selectedPage:any;

  constructor(rendererFactory: RendererFactory2, private httpClient: HttpClient) { 
    this.renderer = rendererFactory.createRenderer(null, null)
    this.editable = true;
    this.mapObservable = new Observable();
  }

  // setDataEmitter(emitter:any) {
  //   this._dataEmitter = emitter;
  // }
  // getDataEmitter() {
  //   return this._dataEmitter;
  // }

  setDoc(doc: any) {
    this.doc = doc;
  }
  getDoc() {
    return this.doc;
  }
  setVCR(vcr: ViewContainerRef) {
    this.vcr = vcr;
  }

  getSection() {
    return this.sections;
  }

  setSection(sections:any) {
    this.sections = sections;
  }

  setComponent(component:any) {
    this.component = component;
  }
  
  builderIDGenerator() {
    const stringArr = [];
    for(let i = 0; i < 12; i++){
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join("")
  }

  numberOfEditingComponent(vc: ViewContainerRef) {
    var num:number = 0;
    for(var x = 0; x < vc.length; x++) {
      let ref = vc.get(x) as EmbeddedViewRef<any>;

      if(!ref || ref == null) {
        continue;
      }

      let roof = ref.rootNodes[0] as HTMLElement

      if( ["LIB-NEW-COMPONENT", "LIB-EDIT-COMPONENT", "LIB-NEW-SECTION"].indexOf(roof.tagName) > -1 ){
        num ++;
      }

    }
    return num;
  }

  setRootStyle(style:any, doc = document) {

    if(style.primary_color) {
      doc.documentElement.style.setProperty('--primary', style.primary_color);
    }

    if(style.secondary_color) {
      doc.documentElement.style.setProperty('--secondary', style.secondary_color);
    }

    if(style.tertiary_color) {
      doc.documentElement.style.setProperty('--tertiary', style.tertiary_color);
    }

    if(style.quaternary_color) {
      doc.documentElement.style.setProperty('--quaternary', style.quaternary_color);
    }

    if(style.quinary_color) {
      doc.documentElement.style.setProperty('--quinary', style.quinary_color);
    }

    let fonts = ["primary", "secondary", "tertiary", "quaternary"];

    var stylesheet = doc.createElement('style');
    stylesheet.type = 'text/css';
    stylesheet.innerHTML = '';

    for(var x = 0; x < fonts.length; x++) {
      let current:any = style[fonts[x] + "_font"];

      if(!current) {
        continue;
      }

      if(current.import) {

      }

      stylesheet.innerHTML += ".font-" + fonts[x] + " {font-family: " + current['rules'] + "}" 
      doc.getElementsByTagName('head')[0].appendChild(stylesheet);      
    }
 
    let body = doc.getElementsByTagName('body')[0]
    if(style.default_bg_color) {
      body.classList.add(style.default_bg_color)
    }

    if(style.default_text_color) {
      body.classList.add(style.default_text_color)
    }

    // let s = doc.createElement("script")
    // s.setAttribute("async", "true");
   // s.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDsPofVaC4ftEta5Vtzu6JyYPYzOWKoBxw"
   // doc.getElementsByTagName('head')[0].appendChild(s);      

  }

  async setStyle(wrapper:any, component:any, key:string = "style") {

    if(component[key] && (wrapper || (wrapper && wrapper.nativeElement))) {

      if(wrapper.nativeElement) {
        wrapper = wrapper.nativeElement;
      }

      if(!component.type || component.type == "section") {
        wrapper.classList = ""
        wrapper.classList.add("d-flex")
        wrapper.classList.add("align-items-center")
        wrapper.classList.add("flex-column")
        if(this.editable) {
          wrapper.classList.add("component")
          wrapper.classList.add("position-relative")
        }
      } else if(component.type.startsWith("columns") && key == "style") {
        console.log(component.type, key, component.type.startsWith("columns"))
          wrapper.classList = ""
          wrapper.classList.add("row")
          wrapper.classList.add("flex-fill")
          if(this.editable) {
            wrapper.classList.add("component")
            wrapper.classList.add("position-relative")
          }
      } else if(component.type.startsWith("columns") && key == "wrapper_style") {
          wrapper.classList = ""
          wrapper.classList.add("component")
          wrapper.classList.add("position-relative")
          wrapper.classList.add("flex-fill")
      } else if(component.type == "COLUMN") {
          wrapper.classList = ""
          console.log(component)
          let size = component.size.split(" ");
          for(var x = 0; x < size.length; x++) {
            wrapper.classList.add(size[x])
          }
          wrapper.classList.add("d-flex")
          wrapper.classList.add("flex-column")
          if(this.editable) {
            wrapper.classList.add("component")
            wrapper.classList.add("position-relative")
          }
      } else if(key == "wrapper_style") {
        wrapper.classList = ""
        wrapper.classList.add("d-flex")
        if(this.editable) {
          wrapper.classList.add("component")
          wrapper.classList.add("position-relative")
        }
      } else {
        wrapper.classList = ""
      }

      if(component.background_type == "image" && component.background) {
        this.renderer.setStyle(wrapper, "background-image", "url('" + component.background + "')")

        let bg_view = wrapper.getElementsByClassName("background-video");

        if(bg_view.length > 0) {
          for(var x = 0; x < bg_view.length; x++) {
            this.renderer.removeChild(wrapper, bg_view[x]);
          }
        }
      }

      if(!component.set_border) {
        delete component[key].border_style;
      }

      if(component.type == "button" && !component.general_btn) {
        delete component[key].default_color;
      }

      if(component.type == "button" && !component.advance_btn) {
        delete component[key].btn_color;
        delete component[key].font_color;
      }

      if(component.background_type == "video" && component.background) {
        wrapper.classList.add("position-relative")

        let videoWrapper = document.createElement("div");
        videoWrapper.classList.add("background-video");
        videoWrapper.classList.add("w-100");
        videoWrapper.classList.add("h-100");
        videoWrapper.classList.add("position-absolute");
        this.renderer.setStyle(videoWrapper, "z-index", "1")
        this.renderer.setStyle(videoWrapper, "top", "0")
        this.renderer.setStyle(videoWrapper, "left", "0")
        this.renderer.setStyle(videoWrapper, "right", "0")
        this.renderer.setStyle(videoWrapper, "bottom", "0")
        this.renderer.setStyle(videoWrapper, "overflow", "hidden")

        let video = document.createElement("video");
        video.classList.add("w-100");
        video.setAttribute("autoplay", "true")
        video.setAttribute("loop", "true")
        video.setAttribute("muted", "true")
        video.setAttribute("controls", "false")
        video.classList.add("position-absolute");
        this.renderer.setStyle(video, "z-index", "1")
        let source = document.createElement("source");
        source.src = component.background;
        source.type = "video/mp4";

        video.append(source)

        videoWrapper.append(video)

        wrapper.appendChild(videoWrapper)

        this.renderer.setStyle(wrapper, "background-image", "")

      }
      // if(current.size) {
      //   componentInstance.location.nativeElement.classList = current.size;
      // }

      if(this.editable) {
        // wrapper.classList.add("component")
      }

      let styles = Object.values(component[key]);

      styles = styles.filter((el:any) => {
        return el != null && el != undefined && el != "";
      });
      
      if (styles.length == 0) {
        return;
      }
      
      for(var x = 0; x < styles.length; x++) {
        wrapper.classList.add(styles[x])
      }
    }
  }

  async generateSection(vc: ViewContainerRef, component:any) {

    const section = ((await import("./builder/layout/section/section.component")).SectionComponent);

    let option = {index: vc.length - this.numberOfEditingComponent(vc)}

    const sectionInstance = vc.createComponent(section, option);
    sectionInstance.instance.setUID = component.uid;;
    sectionInstance.location.nativeElement.classList = "d-flex flex-column align-items-center flex-fill"
    
    this.setStyle(sectionInstance.location, component)
    this.appendEditComponent(sectionInstance, component.uid);
    
    if(!this.editable || !sectionInstance) {
      return;
    }

    let com : ElementRef = sectionInstance.location;
    com.nativeElement.onclick = (e:any) => {
      let actives = this.doc.querySelectorAll(".component.active, lib-section.active");

      if(e.target == com.nativeElement && actives) {
        for(var x = 0; x <  actives.length; x ++) {
          if(actives[x] != e.target) {
            actives[x].classList.remove("active")
          }
        }
      }

      if(e.target == com.nativeElement) {
        com.nativeElement.classList.toggle("active")
      }
    }

  }
  async generateAddNewSection(vc: ViewContainerRef) {
    
    if(!this.editable) {
      return;
    }

    const section = ((await import("./builder/layout/new-section/new-section.component")).NewSectionComponent);
    const sectionInstance = vc.createComponent(section);
    sectionInstance.instance.setViewer = vc;

  }

  async generateComponentType(type:string, vc: ViewContainerRef, components?:any) {

    let option = {index: vc.length - this.numberOfEditingComponent(vc)}

    let component;
    let componentInstance;
    switch(type) {

      case "text":
        component = ((await import("./builder/component-view/text/text.component")).TextComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "button":
        component = ((await import("./builder/component-view/button/button.component")).ButtonComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "html":
        component = ((await import("./builder/component-view/html/html.component")).HtmlComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;
        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "image":
        component = ((await import("./builder/component-view/image/image.component")).ImageComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "list":
        component = ((await import("./builder/component-view/list/list.component")).ListComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "map":
        component = ((await import("./builder/component-view/map/map.component")).MapComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "slider":
        component = ((await import("./builder/component-view/slider/slider.component")).SliderComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "video":
        component = ((await import("./builder/component-view/video/video.component")).VideoComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "card":
        component = ((await import("./builder/component-view/card/card.component")).CardComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;

      case "accordion":
        component = ((await import("./builder/component-view/accordion/accordion.component")).AccordionComponent);
        componentInstance = vc.createComponent(component, option) 
        componentInstance.instance.setComponent = components;

        this.setStyle(componentInstance.instance.wrapper, components);
        this.appendEditComponent(componentInstance.instance, components.uid);
      break;
    }

    if(type.startsWith("columns")) {
      component = ((await import("./builder/layout/row/row.component")).RowComponent);
      componentInstance = vc.createComponent(component, option) 
      componentInstance.instance.setComponent = components
      this.setStyle(componentInstance.instance.wrapper, components);
      this.appendEditComponent(componentInstance.instance, components.uid);
    }

    if(components && components.wrapper_style && componentInstance && componentInstance.location && componentInstance.location.nativeElement) {
      componentInstance.location.nativeElement.classList += " d-flex " + Object.values(components.wrapper_style).join(" ")
    }

    if(this.editable && componentInstance) {
      componentInstance.location.nativeElement.classList.add("component");
      componentInstance.location.nativeElement.classList.add("position-relative");
    }

    if(this.editable && componentInstance) {
      let com : ElementRef = componentInstance.location;

      if(com.nativeElement.tagName != "LIB-ROW") {
        com.nativeElement.onclick = (e:any) => {
          let actives = this.doc.querySelectorAll(".component.active, lib-section.active");
          if(e.target.parentElement == com.nativeElement && actives) {
            for(var x = 0; x <  actives.length; x ++) {
              if(actives[x] != e.target) {
                actives[x].classList.remove("active")
              }
            }
          }
          if(e.target.parentElement, com.nativeElement) {
            com.nativeElement.classList.toggle("active")
          }
        }
      }

    }

    return componentInstance;
  }

  async appendAddComponent(vc: ViewContainerRef, uid:string) {

    if(!this.editable || !vc) {
      return
    }

    const component = ((await import("./builder/layout/new-component/new-component.component")).NewComponentComponent);
    const ci = vc.createComponent(component, {index: vc.length});
    ci.instance.parentVC = vc;
    ci.instance.setParentUID = uid;
    ci.location.nativeElement.classList += "d-flex justify-content-center flex-row"
    return ci;
  }

  async appendEditComponentAppendToVC(vc: ViewContainerRef, uid: string, instance?:any) {
    const component = ((await import("./builder/layout/edit-component/edit-component.component")).EditComponentComponent);
    
    const ci = vc.createComponent(component);
    ci.location.nativeElement.classList += "position-absolute component-toolbar"
    ci.instance.setUID = uid;

    if(instance) {
      ci.instance.setInstance = instance;
    }
    return ci;
  }

  async appendEditComponent(component: any, uid: any, element?:any) {

    if(!this.editable || !this.vcr) {
      return
    }

    let ci = await this.appendEditComponentAppendToVC(this.vcr, uid);
    
    if(!element) {
      element = component.wrapper as ElementRef;
    }

    if(!element) {
      element = component.location
    }

    if(!element) {
      element = component.element
    }
    
    ci.instance.setElement = element;
    ci.instance.setInstance = component;

    if(!element) {
      console.log(element, "element")
      return;
    }

    let appendTo = element.nativeElement;
    
    if(appendTo.tagName != "LIB-SECTION" && appendTo.tagName != "LIB-CELL") {
      appendTo = element.nativeElement.parentElement
    }

    this.renderer.appendChild(appendTo, ci.location.nativeElement)
  }

  getPosition(uid:string) {
  
    // a = section_index;
    // b = row_index;
    // c = column_index;
    // d = element_index;
    let sections = this.sections;
  
    if(sections.length == 0) {
      return;
    }
    
    for(var a = 0; a < sections.length; a++) {

      let current_section = sections[a];

      if(current_section.uid == uid) {
        return {section_index: a, component: current_section};
      }

      if(current_section.components && current_section.components.length > 0) {

        for(var b = 0; b < current_section.components.length; b++) {
          let current_row = current_section.components[b];

          if(current_row.uid == uid) {
            return {section_index: a, row_index:b, component: current_row};
          }

          if(current_row.components && current_row.components.length > 0) {

            for(var c = 0; c < current_row.components.length; c++) {
              let current_column= current_row.components[c];
    
              if(current_column.uid == uid) {
                return {section_index: a, row_index:b, column_index:c, component: current_column};
              }
    
              if(current_column.components && current_column.components.length > 0) {

                for(var d = 0; d < current_column.components.length; d++) {
                  let current_element= current_column.components[d];
        
                  if(current_element.uid == uid) {
                    return {section_index: a, row_index:b, column_index:c, element_index: d, component: current_element};
                  }
        
                }
        
              }
            }
    
          }
    
        }

      }

    }
    return null;
    
  }

  columnsBuilder(type:string) {

    let number = 2;
    let components:any = [];

    if(type == "columns-type-1") {
      number = 1;
    } else if (type == "columns-type-3") {
      number = 3;
    } else if (type == "columns-type-4") {
      number = 4;
    }

    for(var x = 0; x < number; x++) {
      let uid = this.builderIDGenerator();
      let obj:any = {uid, components: [], style: {}}

      if(type == "columns-type-1") {
        obj.size = "col"
      }

      if(type == "columns-type-2") {
        obj.size = "col-12 col-md-6"
      }

      if(type == "columns-type-3") {
        obj.size = "col-12 col-md-4"
      }

      if(type == "columns-type-4") {
        obj.size = "col-md-3 col-12"
      }

      if(type == "columns-type-5") {
        if(x == 0) {
          obj.size = "col-md-5 col-12"
        }

        if(x == 1) {
          obj.size = "col-md-7 col-12"
        }
      }

      if(type == "columns-type-6") {
       
        if(x == 0) {
          obj.size = "col-md-7 col-12"
        }

        if(x == 1) {
          obj.size = "col-md-5 col-12"
        }
      }

      components.push(obj)
    }

    return components;
  }

  validateColumeType(type:string) {
    return type.startsWith("columns")
  }

  loadMapApi() {
    // console.log("1")

    // if(this.mapApi) {
    //   console.log("loadded")
    //   return this.mapObservable;
    // }
    // console.log("2")

    // this.mapApi = true;

    this.mapObservable = this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDsPofVaC4ftEta5Vtzu6JyYPYzOWKoBxw', 'callback')
        .pipe(
          map(() => {return true}),
          catchError((e:any) => {
            console.log(e)
            return of(false)
          }),
        );

        console.log(this.mapObservable)
    return this.mapObservable
  }

  setPageLangauge(lang:any) {
    this.langauge = lang;
  }

  getPageLangauge() {
    return this.langauge;
  }

  setPage(page:any) {
    this.selectedPage = page;
  }

  getPage() {
    return this.selectedPage;
  }
}
