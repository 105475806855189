<div #wrapper>
    <div class="card">
        <div *ngIf="component.set_image" class="card-img-top" [ngStyle]="{'background-image': 'url(\'' + component.value + '\')', 'min-height': '200px', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat'}" >
            <img [src]="component.value" class="w-100">
        </div>
        <div class="card-body">
            <div *ngIf="component.set_title" class="card-title {{component.title_font_size}} {{component.title_font_color}} {{component.title_font_family}} {{component.title_text_align}} {{component.title_letter_spacing}} {{component.title_letter_spacing}}" [innerHTML]="component.title"></div>
            <div *ngIf="component.set_descr" class="card-descr {{component.descr_font_size}} {{component.descr_font_color}} {{component.descr_font_family}} {{component.descr_text_align}} {{component.descr_letter_spacing}} {{component.descr_letter_spacing}}" [innerHTML]="component.descr"></div>
            <!-- <lib-button [setComponent]="component.button"></lib-button> -->
            <button *ngIf="component.set_btn" class="btn {{component.btn.size}} {{component.btn.font_family}} {{component.btn.corner}} {{component.btn.default_color}} {{component.btn.btn_color}} {{component.btn.font_color}} ">{{component.button}}</button>
        </div>
    </div>
</div>