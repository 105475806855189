<div class="row">
    <div class="col-12 col-md-3">
        <div class="card mb-4">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Customer Info</strong></div>
            </div>
            <div class="card-body">
                <p class="mb-0">{{data.customer.name}}</p>
                <p class="mb-0">{{data.customer.email}}</p>
                <p class="mb-0">{{data.customer.mobile_no}}</p>
                <p class="mb-0">{{data.customer.from_source}}</p>
                
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Service Info</strong></div>
            </div>
            <div class="card-body">
                <div class="form-group mb-3">
                    <label for="name">Installation Branch <em>*</em></label>
                    <ng-select [(ngModel)]="data.installation_branch" required placeholder="Select Branch" [disabled]="noneditable">
                        <ng-option *ngFor="let branch of init.branch" [value]="branch.uuid">{{branch.title}}</ng-option>
                    </ng-select>
                </div>
        
                <div class="form-group mb-3">
                    <label for="installation_date">Installation Date</label>
                    <input type="date" id="installation_date" class="form-control" name="installation_date" [(ngModel)]="data.installation_date" [disabled]="noneditable">
                </div>
                <div class="form-group mb-3">
                    <label for="time_from">Installation From</label>
                    <select class="form-control" [(ngModel)]="data.time_from" [disabled]="noneditable" [disabled]="noneditable">
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                    </select>
                </div>
                <div class="form-group mb-3">
                    <label for="time_to">Installation To</label>
                    <select class="form-control" [(ngModel)]="data.time_to" [disabled]="noneditable">
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                    </select>
                </div>
                <div class="form-group mb-3">
                    <label for="invoice_no">Invoice No</label>
                    <input type="text" id="invoice_no" class="form-control" name="invoice_no" [(ngModel)]="data.invoice_no" [disabled]="noneditable">
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-9">
        <nav class="card d-flex flex-row justify-content-between align-items-center bg-white mb-3 py-2 px-3 toolbar">
            <strong>Toolbar</strong>
            <div class="d-flex flex-row">
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid" (click)="getInspection()">INSPECTION</button>
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid && data.servey_form_data" (click)="sendWarranty()">RESEND WARRANTY</button>
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid && !data.servey_form_data" (click)="copySurveyLink()">COPY SURVEY LINK</button>
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid" (click)="printJobOrder()">PRINT JOB ORDER</button>
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid && data.servey_form_data" (click)="printWarranty()">PRINT WARRANTY</button>
                <button class="btn btn-sm btn-primary me-2" *ngIf="job_uuid" (click)="setCoatingReminder()">SET REMINDER</button>
                <button class="btn btn-sm btn-primary" *ngIf="!noneditable" (click)="save()">{{job_uuid ? "SAVE":"CREATE JOB"}}</button>
            </div>
        </nav>

        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Vehicle Info</strong></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group mb-3 col">
                        <label for="plate_no">Plate No</label>
                        <input type="text" class="form-control" name="plate_no" id="plate_no" [(ngModel)]="data.plate_no" [disabled]="noneditable"/>
                    </div>
                    <div class="form-group mb-3 col">
                        <label for="car_maker">Car Maker</label>
                        <ng-select [(ngModel)]="data.car_maker" (change)="filterModel()" placeholder="Select Car Maker" [disabled]="noneditable">
                            <ng-option *ngFor="let maker of init.maker" [value]="maker.id">{{maker.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col">
                        <label for="car_model">Car Model</label>
                        <ng-select [(ngModel)]="data.car_model" [disabled]="!data.car_maker" placeholder="Select Car Model" [disabled]="noneditable">
                            <ng-option *ngFor="let model of init.filter_model" [value]="model.id">{{model.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col">
                        <label for="car_type">Car Type</label>
                        <select class="form-control" id="car_type" [(ngModel)]="data.car_type" [disabled]="noneditable">
                            <option [value]="'new'">New</option>
                            <option [value]="'old'">Old</option>
                            <option [value]="'recon'">Recon</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0 justify-content-between d-flex flex-row">
                    <strong>Product Info</strong> 
                    <button class="btn border-0 p-0" (click)="addNewProduct()" *ngIf="!noneditable">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                        ADD
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row" *ngFor="let product of data.product; let i = index;">
                    <div class="form-group mb-3 col-2">
                        <label [for]="'product_type_' + i">Category</label>
                        <ng-select [id]="'product_type_' + i" [(ngModel)]="product.category" (change)="filterCategory(product)" [disabled]="noneditable">
                            <ng-option *ngFor="let category of init.categories" [value]="category.id">{{category.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col-6">
                        <label [for]="'product_' + i">Product</label>
                        <ng-select [(ngModel)]="product.product_id" [id]="'product_' + i" [disabled]="!product.category || noneditable" placeholder="Select Product" (change)="filterProduct(product)">
                            <ng-option *ngFor="let p of product.filter_product" [value]="p.id">{{p.title}}</ng-option>
                        </ng-select> 
                    </div>
                    <div class="form-group mb-3 col-2">
                        <label for="name">Price</label>
                        <input type="text" class="form-control" name="appointment_date" [(ngModel)]="product.price" (input)="calculateTotal()" [disabled]="noneditable"/>
                    </div>
                    <div class="form-group mb-3 col-2">
                        <label for="name">JPJ Compliance</label>
                        <select [(ngModel)]="product.jpj_compliance" class="form-control" [disabled]="noneditable">
                            <option [value]="'yes'">YES</option>
                            <option [value]="'no'">NO</option>
                        </select>
                    </div>
                    <div class="form-group mb-3 col-2">
                        <label *ngIf="(product.product || product.product_id) && product.category != 9">Warranty</label><br/>
                        <span *ngIf="(product.product || product.product_id) && product.category != 9" [innerHTML]="showWarranty(product)" ></span>
                    </div>
                    <div class="form-group mb-3 col-6">
                        <label [for]="'service_area_' + i">Service Area</label>
                        <ng-select [multiple]="true" [(ngModel)]="product.service_area" [id]="'service_area_' + i" [disabled]="!product.category" placeholder="Select Product" [disabled]="noneditable">
                            <ng-option [value]="'Front Windscreen'">Front Windscreen</ng-option>
                            <ng-option [value]="'Rear Windscreen'">Rear Windscreen</ng-option>
                            <ng-option [value]="'Whole Car'">Whole Car</ng-option>
                            <ng-option [value]="'L1'">L1</ng-option>
                            <ng-option [value]="'L2'">L2</ng-option>
                            <ng-option [value]="'L3'">L3</ng-option>
                            <ng-option [value]="'R1'">R1</ng-option>
                            <ng-option [value]="'R2'">R2</ng-option>
                            <ng-option [value]="'R3'">R3</ng-option>
                            <ng-option [value]="'sunroof'">SunRoof</ng-option>
                            <ng-option [value]="'sunvisor'">Sunvisor</ng-option>
                            <ng-option [value]="'other'">Other</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col-2 text-end">
                        <button class="btn border-0 p-0" (click)="removeProduct(i)" *ngIf="data.product.length > 1 && !noneditable" >
                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card mb-3">
                    <div class="card-header bg-gray">
                        <div class="card-title mb-0 justify-content-between d-flex flex-row">
                            <strong>Installation Spec</strong> 
                            <button class="btn border-0 p-0" (click)="addNewSpecification()" *ngIf="!noneditable">
                                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                                ADD
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div *ngIf="data.specification.length == 0 && !noneditable">
                            <button class="btn btn-outline-secondary btn-sm" (click)="addNewSpecification()">Add Specification</button>
                        </div>
                        <table class="w-100" *ngIf="data.specification.length > 0">
                            <thead>
                                <tr><th>Area</th><th>Inventory</th><th>Usage(sq in/ml)</th><th></th></tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let specification of data.specification; let i = index">
                                    <tr>
                                        <td class="col-3 p-0">
                                            <ng-select [multiple]="true" class="w-100" [(ngModel)]="specification.location" [disabled]="noneditable">
                                                <ng-option [value]="'F/W'">F/W</ng-option>
                                                <ng-option [value]="'R/W'">R/W</ng-option>
                                                <ng-option [value]="'S1'">S1</ng-option>
                                                <ng-option [value]="'S2'">S2</ng-option>
                                                <ng-option [value]="'S3'">S3</ng-option>
                                                <ng-option [value]="'L1'">L1</ng-option>
                                                <ng-option [value]="'L2'">L2</ng-option>
                                                <ng-option [value]="'L3'">L3</ng-option>
                                                <ng-option [value]="'R1'">R1</ng-option>
                                                <ng-option [value]="'R2'">R2</ng-option>
                                                <ng-option [value]="'R3'">R3</ng-option>
                                                <ng-option [value]="'SUNROOF'">SUNROOF</ng-option>
                                                <ng-option [value]="'SUNVISOR'">SUNVISOR</ng-option>
                                                <ng-option [value]="'WHOLE-CAR'">WHOLE CAR</ng-option>
                                                <ng-option [value]="'OTHER'">OTHER</ng-option>
                                            </ng-select>
                                        </td>
                                        <td class="col-7 p-0">
                                            <ng-select class="w-100" [(ngModel)]="specification.title" [disabled]="noneditable">
                                                <ng-option *ngFor="let product of alacarte" [value]="product.id">{{product.code | uppercase}} - {{product.title}}</ng-option>
                                            </ng-select>
                                        </td>
                                        <td class="col-2 p-0">
                                            <input class="form-control form-control-sm" [(ngModel)]="specification.usage" type="number" (input)="calculateTotal()" [disabled]="noneditable"/>
                                        </td>
                                        <td>
                                            <button class="btn border-0 p-0" (click)="removeSpec(i)" *ngIf="data.specification.length > 1 && !noneditable">
                                                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <input class="form-control form-control-sm"  [(ngModel)]="specification.remark" type="text" [placeholder]="'Remark'" [disabled]="noneditable"/>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card mb-3">
                    <div class="card-header bg-gray">
                        <div class="card-title mb-0 justify-content-between d-flex flex-row">
                            <strong>Extra Charge</strong> 
                            <button class="btn border-0 p-0" (click)="addExtraCharge()" *ngIf="!noneditable">
                                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                                ADD
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="w-100" *ngIf="data.extra.length > 0">
                            <thead>
                                <tr><th>Type</th><th>Rate</th></tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let extra of data.extra">
                                    <td class="col-8 p-0">
                                        <ng-select [(ngModel)]="extra.uuid" class="w-100" (change)="updateExtra(extra)">
                                            <ng-option *ngFor="let ex of init.extra_charges" [value]="ex.uuid">{{ex.type}}{{ex.variant ? " - " + ex.variant : ""}}{{ex.price ? " - RM " + ex.price : ""}}</ng-option>
                                            <ng-option [value]="'other'">Other</ng-option>
                                        </ng-select>
                                    </td>
                                    <td class="col-4 p-0">
                                        <input type="number" class="form-control" (input)="calculateTotal()" [(ngModel)]="extra.price"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="data.extra.length == 0 && !noneditable">
                            <button class="btn btn-outline-secondary btn-sm" (click)="addExtraCharge()">Add New Extra Charge</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 offset-md-6 col-md-6">
                <div class="card mb-3">
                    <div class="card-body">
                        <table class="w-100">
                            <tr>
                                <th>Subtotal</th>
                                <td class="text-end">RM {{data.subtotal | number: '1.2-2'}}</td>
                            </tr>
                            <tr>
                                <th>Extra Charges</th>
                                <td class="text-end">RM {{data.extra_charges | number: '1.2-2'}}</td>
                            </tr>
                            <tr>
                                <th>Discount (-)</th>
                                <td class="text-end">
                                    <input type="number" class="d-inline-block form-control form-control-sm" [(ngModel)]="data.discount" (input)="calculateTotal()" [disabled]="noneditable"/>
                                </td>
                            </tr>
                            <tr>
                                <th>Total Payable</th>
                                <td class="text-end">RM {{data.total_payable | number: '1.2-2'}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Other Information</strong></div>
            </div>
            <div class="card-body">
                <div class="form-group mb-3">
                    <label for="time_to">Remark</label>
                    <textarea class="form-control" rows="5" [(ngModel)]="data.remark" [disabled]="noneditable">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</div>
