import { state } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspectionComponent } from '../inspection/inspection.component';
import { ClipboardService } from 'ngx-clipboard';
import { LexiNotifyService } from 'lexi-notify';
import { SetReminderComponent } from '../set-reminder/set-reminder.component';

@Component({
  selector: 'lib-customer-job',
  templateUrl: './customer-job.component.html',
  styleUrls: ['./customer-job.component.css']
})
export class CustomerJobComponent {

  private environment:any;
  public data:any = {customer: {}, product: [{filter_product: []}], car_maker: null, car_model: null, specification: [], extra: [], coating_reminder: [{}]};
  public init: any = {};
  public job_uuid: string | undefined;
  public alacarte : any = [];
  public haveCoating:any = false;
  private initialize: boolean = false;
  public noneditable:boolean = false;
  
  constructor(private router: Router, private api: ApiService, private activatedRoute:ActivatedRoute, private ngbModal: NgbModal, @Inject('environment') environment: any, private _clipboardService: ClipboardService, private notify: LexiNotifyService) {

    this.environment = environment;

    this.activatedRoute.params.subscribe(async (val:any) => {

      let state_data = this.router.getCurrentNavigation()?.extras.state

      await this.initForm();

      if(val.job_uuid) {
        this.job_uuid = val.job_uuid
        await this.call();
      } else if(!state_data || !state_data['customer']) {
        this.router.navigate(['/new'])
        return;
      } else {

        if(state_data['data']) {
          this.data = state_data['data'];
          this.data.state_data = true;
          delete this.data.uuid
        }
        if(state_data['customer']) {
          this.data.customer = state_data['customer'];
        }

        this.data.product.forEach((v:any) => {
          v.jpj_compliance = "no"
        })
        this.data.specification = []
        this.data.extra = [];
        this.data.subtotal = 0;
        this.data.extra_charges = 0;
        this.data.total_payable = 0;

        if(state_data['appointment']) {
          let appointment = state_data['appointment'];
          this.data.car_maker = appointment.car_maker
          this.filterModel()
          this.data.car_model = appointment.car_model
          this.data.plate_no = appointment.plate_no
          this.data.time_from = appointment.time
          this.data.time_to = appointment.time_to
          this.data.installation_branch_uuid = appointment.installation_branch_uuid;
          this.data.installation_date = appointment.appointment_date;
          this.data.product = appointment.product;
          this.data.product.forEach((v:any) => {
            this.filterCategory(v);
          })
          this.data.appointment_uuid = appointment.uuid;
        } else {
          this.filterModel(this.data.car_model)
        }
       await this.call();

      }

    })

  }

  async initForm() {
    this.initialize = true;
    this.init = await this.api.post("/profile/init/form", {});

    this.init.filter_model = [];
    this.filterModel();
    if(this.init.branch.length == 0) {
      this.router.navigate(['/new'])
      return;
    }

    this.alacarte = this.init.inventory

    this.data.installation_branch = this.init.branch[0]['uuid']

    if(this.data.installation_branch_uuid) {
      this.data.installation_branch = this.data.installation_branch_uuid;
    }
    
  }
  async call() {

    if(this.job_uuid) {
      await this.getJob()
    }

    this.calculateTotal();
  }

  async getJob() {

    if(!this.job_uuid || this.job_uuid == "0") {
      return;
    }

    this.data = await this.api.post("/profile/job/get", {job_uuid: this.job_uuid});

    let car_model = this.data.car_model;
    this.filterModel()
    this.data.car_model = car_model
    this.data.subtotal = 0;
    this.data.extra_charges = 0;
    this.data.total_payable = 0
    this.data.car_maker = parseInt(this.data.car_maker)
    this.data.car_model = parseInt(this.data.car_model)

    if(this.job_uuid && this.data.servey_form_data && !this.data.super_admin) {
      this.noneditable = true;
    }

    this.data.product.forEach((v:any) => {
      this.filterCategory(v);
    })
  }

  filterModel(modal_id = null) {
    this.init.filter_model = this.init.model.filter((v:any) => {
      return v.car_maker_id == this.data.car_maker;
    })
    if(modal_id) {
      this.data.car_model = modal_id;
    } else {
      this.data.car_model = null
    }
  }

  filterCategory(product:any) {

    product.filter_product = this.init.product.filter((v:any) => {
      return v.category_id == product.category;
    })

    let get = this.init.product.find((v:any) => {
      return v.id == product.product_id;
    });
    
    // delete product.price;
    // delete product.product;
    // delete product.product_id;
    // delete product.title;

  }

  showWarranty(product:any) {
    if(!product.product_id && !product.product) {
      return;
    }

    let p = product.filter_product.find((v:any) => {
      return v.id == (product.product_id ?? product.product);
    });

    if(!p) {
      return;
    }

    let warranty = "";

    if(p.tint_warranty) {
      warranty += "Tint: " + p.tint_warranty + " years<br/>";
    }
    if(p.coating_warranty) {
      warranty += "Coating: " + p.coating_warranty + " years<br/>";
      this.haveCoating = true;
    }
    if(p.coating_maintenance) {
      warranty += "Maintenance: " + p.coating_maintenance + " time<br/>";
    }
    if(p.ppf_warranty) {
      warranty += "PPF: " + p.ppf_warranty + " years<br/>";
    }

    return warranty;
  }

  addNewProduct() {
    this.data.product.push({filter_product: []})
  }

  addNewSpecification() {
    this.data.specification.push({filter_product: []})
  }
  addExtraCharge() {
    this.data.extra.push({})
  }

  updateExtra(extra:any) {
    let match = this.init.extra_charges.find((v:any) => {
      return v.uuid == extra.uuid;
    })
    extra.price = match ? match.price : 0;
    extra.title = match ? (match.type + (match.variant ? " - " + match.variant : "")) : "Other"
    this.calculateTotal();
  }

  removeProduct(index:number) {
    if(this.data.product.length <= 1) {
      return;
    }
    this.data.product.splice(index, 1)
  }

  async removeSpec(index:number) {
    if(this.data.specification.length <= 1) {
      return;
    }

    if (this.data.specification[index].uuid) {
      await this.api.post("/profile/job/specification/remove", {uuid: this.data.specification[index].uuid});
    }

    this.data.specification.splice(index, 1)
  }

  calculateTotal() {
    let total = 0;
    if (this.data.product.length > 0 ) {
      for(var x = 0; x < this.data.product.length; x++) {
        total += parseFloat(this.data.product[x].price ?? 0)
      }  
    }

    this.data.subtotal = total;
    let extra = 0;

    if (this.data.extra.length > 0 ) {
      for(var x = 0; x < this.data.extra.length; x++) {
        extra += parseFloat(this.data.extra[x].price ?? 0)
      }  
    }

    this.data.extra_charges = extra;

    this.data.total_payable = parseFloat(this.data.subtotal) + parseFloat(this.data.extra_charges) - parseFloat(this.data.discount ?? 0);
  }

  async save() {
    let data = this.data;
    data.job_uuid = this.job_uuid

    let rtn = await this.api.post("/profile/job/save", data);

    if(!rtn.status) {
      alert(rtn.message)
      return;
    }

    this.job_uuid = rtn.job_uuid;
    await this.call();

    this.router.navigate(['/customer/job', rtn.job_uuid])
  }

  async copySurveyLink() {
    this._clipboardService.copy("https://crm.hamelfilm.com/survey/" + this.job_uuid)
    this.notify.success("Survey Link Copied.")
  }

  async sendWarranty() {
    await this.api.post("/profile/job/warranty/send", {job_uuid: this.job_uuid});
    this.notify.success("Survey Link Copied.")
  }

  async getInspection() {

    let inspection = await this.api.post("/profile/job/inspection/get", {job_uuid : this.job_uuid});

    let modal = this.ngbModal.open(InspectionComponent, {size:'xl'})
    modal.componentInstance.setInspection = inspection.inspection

    modal.componentInstance.closeEvent.subscribe((v:any) => {
      modal.close()
    })

    modal.componentInstance.saveEvent.subscribe(async (v:any) => {
      let data:any = {};
      data.job_uuid = this.job_uuid
      data.inspection = v;
      let rtn = await this.api.post("/profile/job/inspection/save", data);
      modal.close()
    })

    let date = new Date();
    modal.componentInstance.setLabel = "PLATE NO: " + this.data.plate_no + " " + date.toLocaleString('en-US', { timeZone: 'Asia/Kuala_Lumpur' });
  }

  async printWarranty() {
    window.open(this.environment.api_url + "/profile/warranty/print/" + this.job_uuid, "_blank")
  }
  
  async printJobOrder() {
    window.open(this.environment.api_url + "/profile/job/print/" + this.job_uuid, "_blank")
  }

  async setCoatingReminder() {

    let modal = this.ngbModal.open(SetReminderComponent, {size:'md', backdrop:true})
    modal.componentInstance.setJobUUID = this.job_uuid

    modal.componentInstance.closeEvent.subscribe((v:any) => {
      modal.close()
    })

  }
  async sendCoatingReminderByIndex(val:any) {

    if(!val.title || !val.date) {
      this.notify.error("All information are required. Notification send failed.");
      return;
    }

    val.job_uuid = this.job_uuid
    await this.api.post("/profile/job/coating/reminderByIndex", val);
    this.notify.success("Coating reminder send.");
  }
  addCoatingReminder() {
    this.data.coating_reminder.push({})
  }

  removeReminder(index:number) {
    if(this.data.product.length <= 1) {
      return;
    }
    this.data.product.splice(index, 1)
  }

  filterProduct(product:any) {
    let match = product.filter_product.find((v:any) => {
      return v.id == product.product_id;
    })

    if(!match) {
      return;
    }

    product.title = match.title;
    product.uuid = match.uuid;
    product.price = match.price;

    if(match.tint_warranty) {
      product.tint_warranty = match.tint_warranty
      product.warranty = match.tint_warranty
    }

    if(match.coating_warranty) {
      product.coating_warranty = match.coating_warranty
      product.warranty_coating = match.coating_warranty
    }

    if(match.ppf_warranty) {
      product.ppf_warranty = match.ppf_warranty
      product.warranty_ppf = match.ppf_warranty
    }

  }
}
