import { Component, AfterViewInit, ElementRef, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {  } from '@angular/google-maps';
declare var google:any;


@Component({
  selector: 'lib-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {

    if(!val.value) {
      val.value = "Select your location"
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }
    
    this.component = val;
  } 
  options: google.maps.MapOptions = {
    center: {lat: 40, lng: -20},
    zoom: 4
  };

  public loaded = false;
  public component:any;
  apiLoaded: Observable<boolean>;

  constructor(public builder: LexiPagebuilderService, httpClient: HttpClient) { 
   // this.apiLoaded = this.builder.loadMapApi();

    // if(!this.loaded) {
      console.log("map here")
        this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDsPofVaC4ftEta5Vtzu6JyYPYzOWKoBxw', 'callback')
        .pipe(
          map(() => {this.loaded = true; return true}),
          catchError((e:any) => {
            console.log("e",e)
            return of(false)
          }),
        );

    
    // }
  }

  ngAfterViewInit() {
    // this.builder.setStyle(this.wrapper, this.component);
    // this.builder.appendEditComponent(this.vcr, this.wrapper, this.component.uid);
  }
}