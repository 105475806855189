import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public data: any = {};

  constructor(private api: ApiService, private notify: LexiNotifyService) { }

  ngOnInit(): void {
  }

  async submit() {
    if (!this.data.password || !this.data.confirm_password) {
      this.notify.error("All fields are mandatory")
      return;
    }

    if(this.data.password != this.data.confirm_password) {
      this.notify.error("Password not match")
      return;
    }

    let rtn = await this.api.post("/user/change-password", this.data);

    if (!rtn.status) {
      this.notify.error(rtn.message);
      return;
    }

    this.notify.success("Password update successfully.")
  }
}
