import { Component } from '@angular/core';
import { DsmeDataService } from 'dsme-data';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent {

  public branches: any = [];
  public inventories: any = [];
  public filters: any = [];
  public editable: boolean = false;
  public branch: string | undefined;
  constructor(private api: ApiService, private data: DsmeDataService) { 
    this.init();
  }

  async init() {
    await this.getInventory();
  }
  
  async getInventory() {
    setTimeout(async () => {
      let profile = this.data.getSelectedProfile();
      this.branch = profile.uuid;
      let inventory = await this.api.post("/profile/inventory", {branch: this.branch});
      this.inventories = inventory.inventories;
      this.filters = this.inventories;
      this.editable = inventory.editable;
    }, 1000)
  }

  calc(inventory:any) {
    inventory.new_balance = parseFloat(inventory.balance ?? 0) + parseFloat(inventory.debit ?? 0) - parseFloat(inventory.credit ?? 0)
  }

  async save() {
    let inventories = this.inventories.filter((v:any) => {return v.debit || v.credit})

    if(inventories.length == 0) {
      alert("Nothing to save")
      return;
    }

    let inventory = await this.api.post("/profile/inventory/save", {inventories, branch: this.branch});

    if(inventory.status) {
      alert("Inventory Saved")
      await this.getInventory();
    }
  }

  search($event:any) {

    let searchVal = $event.target.value;

    if(!searchVal) {
      this.filters = this.inventories;
      return;
    }

    let filters = this.inventories.filter((v:any) => {
      return v.title.toLowerCase().indexOf(searchVal.toLowerCase()) > -1;
    })

    this.filters = filters;

  }
}
