import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { LexiTranslationHelper } from 'lexi-translation';

@Component({
  selector: 'lib-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent {

  @Output() closeEvent = new EventEmitter<boolean>();

  constructor(private translate: LexiTranslationHelper) { }

  switchLanguage(lang:string) {
    this.translate.switch(lang)
    this.closeEvent.emit(true)
  }
}
