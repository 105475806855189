import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-view-popup',
  templateUrl: './view-popup.component.html',
  styleUrls: ['./view-popup.component.css']
})
export class ViewPopupComponent implements OnInit {

  @Input() set page_uuid(key : any) {
    this.pkey = key;
    this.initData()
  }
  @Output() closeModal = new EventEmitter();

  public pages:any = [];
  public pkey:any;
  public data:any;
  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
  }

  async initData() {
    this.pages = await this.api.post('/page/get-all', {page_uuid: this.pkey})
  }

  submit() {
    var d = this.pages.filter((p:any) => {
      return p.language == this.data;
    })
    this.closeModal.emit(d[0])
  }

}
