import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-customer-new',
  templateUrl: './customer-new.component.html',
  styleUrls: ['./customer-new.component.css']
})
export class CustomerNewComponent implements OnInit {

  @Output() setClose = new EventEmitter<any>();
  public data: any = {}
  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

  async create() {
    console.log(this.data)
    let customer = await this.api.post("/profile/customer/new", this.data);
console.log(customer, "customer")
    if(!customer.status) {
      alert(customer.message)
      return;
    }

    this.setClose.emit(true)
  }

  close() {
    this.setClose.emit(true)
  }

}
