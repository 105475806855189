import { Component } from '@angular/core';
import { DsmeDataService } from 'dsme-data';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent {

  constructor(private api: ApiService, private data: DsmeDataService) {
    let profile = this.data.getSelectedProfile();
console.log(profile)
    if(profile && profile.uuid) {
      this.api.valueAppender("profile_uuid", profile.uuid)
    }

  }

}
 