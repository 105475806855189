import { Component } from '@angular/core';
import { DsmeDataService } from 'dsme-data';
import { ApiService } from 'lexi-api';
import { LexiAuthService } from 'lexi-auth';
import { LexiTableService } from 'lexi-table';
import { MenuHelperService } from 'menu-helper';

@Component({
  selector: 'lib-platform',
  template: `
  <router-outlet></router-outlet>
  `,
  styles: [
  ]
})
export class PlatformComponent{

  constructor(private menu: MenuHelperService, private api: ApiService, private auth: LexiAuthService, private tableService: LexiTableService, private data: DsmeDataService) {
    this.api.valueAppender("token", this.auth.getToken())
    let profile_uuid = this.data.getLocalstorageProfile();
    if(profile_uuid) {
      this.api.valueAppender("profile_uuid", profile_uuid) 
    }
    this.init();
  }

  async init() {
 
    // this.tableService.setFunction("login", async (val:any) => {
//      await this.api.post("/admin/carevo/select", val);
    // user_id, listing_id
    // post to db to save this data, who is the 1 press this data
      //this.router.navigate(['/member'])
    // })
  }

  

}
