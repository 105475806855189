<header class="navbar navbar-header navbar-header-fixed">
    <a href="javascript:void(0)" id="mainMenuOpen" class="burger-menu"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>
    <div class="navbar-brand">
      <a [routerLink]="['/']" class="df-logo" [innerHTML]="config.app_title"></a>
    </div><!-- navbar-brand -->
    <div id="navbarMenu" class="navbar-menu-wrapper">
      <div class="navbar-menu-header">
        <a [routerLink]="['/']" class="df-logo" [innerHTML]="config.app_title"></a>
        <a id="mainMenuClose" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>
      </div><!-- navbar-menu-header -->
      <ul class="nav navbar-menu">
        <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">
          <span>{{'dashforge.main' | translate}}</span>
        </li>
        <li class="nav-item" *ngFor="let m of menu.primary_menu" [ngClass]="{'with-sub': m.sub_menu && m.sub_menu.length > 0, 'active': activate(m)}">
          <ng-container *ngIf="!m.sub_menu || m.sub_menu.length == 0">
            <a [routerLink]="[m.path]" class="nav-link">{{m.title}}</a>
          </ng-container>
          <ng-container *ngIf="m.sub_menu && m.sub_menu.length > 0">
            <a href="javascript:void(0)" class="nav-link">{{m.title}}</a>
          </ng-container>
          <ng-container *ngIf="m.sub_menu && m.sub_menu.length > 0">
            <div class="navbar-menu-sub">
              <div class="d-lg-flex">
                <ul *ngFor="let s of m.sub_menu">
                  <li class="nav-label" *ngIf="s.label">{{s.label}}</li>
                  <li class="nav-sub-item" *ngFor="let sm of s.menu" [ngClass]="{'active font-weight-bold': activate(sm)}">
                    <a [routerLink]="[sm.path]" class="nav-sub-link">
                      {{sm.title}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </li>
      </ul>
    </div>
    <div class="navbar-right">
      <div class="dropdown dropdown-profile" *ngIf="dsme && loading_profile">
        <a href="javascript:void(0)" class="dropdown-link" >
          {{'dashforge.loading' | translate}}
        </a>
      </div>
      <div class="dropdown dropdown-profile" *ngIf="dsme && !loading_profile && profiles.length > 0 ">
        <a href="javascript:void(0)" class="dropdown-link" data-toggle="dropdown" data-display="static">
          <div class="profile-selector">
            {{selected_profile.title}}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill ms-1" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill ms-1" viewBox="0 0 16 16">
              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
          </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right tx-13 p-0">
            <div class="list-group list-group-flush">
              <div class="profile-wrapper">
                <a href="javascript:void(0)" class="list-group-item disabled">
                  <span>{{'dashforge.switch' | translate}}</span>
                </a>
                <a href="javascript:void(0)" *ngFor="let profile of profiles" class="list-group-item list-group-item-action" (click)="switchProfile(profile)" [ngClass]="{'active': selected_profile.uuid == profile.uuid}">{{profile.title}}</a>
                
              </div>
            </div>
          </div>
      </div>
      
      <div class="dropdown dropdown-profile">
        <a href="javascript:void(0)" class="dropdown-link" data-toggle="dropdown" data-display="static">
          <div class="avatar avatar-sm"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"/></g></svg></div>
        </a>
        <div class="dropdown-menu dropdown-menu-right tx-13">
          <!-- <div class="avatar avatar-lg mg-b-15"><img src="https://via.placeholder.com/500" class="rounded-circle" alt=""></div>
          <h6 class="tx-semibold mg-b-5">{{user.name}}</h6>
          <p class="mg-b-0 tx-10 tx-color-03">{{user.email}}</p>
          <p class="mg-b-25 tx-10 tx-color-03">{{user.position}}</p>-->
  
          
          <a href="javascript:void(0)" (click)="signout()" class="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>{{'dashforge.sign_out' | translate}}</a>
        </div>
      </div>
    </div>
  </header>
  <div class="progress-bar" *ngIf="page_loading">
      <span class="progress-bar-fill"></span>
  </div>
