
export const environment = {
  production: false,
  //api_url: "https://digitalsme.tech/api-v2",
  api_url: "https://crm-api2.hamelfilm.com",

  // Authentication Setting
  token_validation_url: "/user/validate/token",
  token_user_url: "/user/validate/user",
  token_user_logout: "/user/logout",
  login_url: "/user/login",
  negative_guard_failed_redirect: ["/dashboard"],
  positive_guard_failed_redirect: ["/auth"],
  negative_child_guard_failed_redirect: ["/dashboard"],
  positive_child_guard_failed_redirect: ["/auth"],
  method_guard_failed_redirect: ["/auth"],
  user_guard_failed_redirect: ["/auth"],

  // Lexi DashforgeTop Setting
  profile_path: ["/user/profile"],
  success_login_url: ['/dashboard'],
  get_profile_url: ['/profile/get/user/profiles'],

  dsme: true,
  debug: true
};
