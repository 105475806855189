import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreRoutingModule } from './pre-routing.module';
import { PreComponent } from './pre.component';


@NgModule({
  declarations: [
    PreComponent
  ],
  imports: [
    CommonModule,
    PreRoutingModule
  ]
})
export class PreModule { }
