  <div class="d-flex align-items-center vh-100 bg-black">
    <div class="col-12 col-md-4 p-5">
      <form #loginForm="ngForm" (submit)="loginForm.valid && submit()" class="sign-wrapper w-100" [ngClass]="{'was-validated': loginForm.submitted && !loginForm.valid}">
        <div class="wd-100p">
          <img class="w-50" src="https://static-dsme.sgp1.digitaloceanspaces.com/profile/70e9cee8-7e65-565f-946f-4f7b06558196/hamel_logo.svg" />
          <p class="mb-5 text-light small mt-2">CLIENT MANAGEMENT SYSTEM</p>
          <h3 class="text-light mg-b-5">{{'dashforge.sign_in' | translate}}</h3>
          <p class="tx-color-03 tx-16 mg-b-40">{{'dashforge.sign_in_welcome_message' | translate}}</p>
          <div class="form-group">
            <label class="text-light">{{'dashforge.email' | translate}}</label>
            <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="data.email" [placeholder]="placeholder.login_username_placeholder" required />
            <div *ngIf="loginForm.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors?.['required']">{{'dashforge.email_required' | translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between mg-b-5">
              <label class="mg-b-0-f text-light">{{'dashforge.password' | translate}}</label>
            </div>
            <input type="password" class="form-control" name="password" #password="ngModel" [(ngModel)]="data.password" [placeholder]="placeholder.login_password_placeholder" minlength="6" maxlength="16" required />
            <div *ngIf="loginForm.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors?.['required']">{{'dashforge.password_required' | translate}}</div>
            </div>
          </div>
          <button class="btn btn-outline-light btn-block mb-3">{{'dashforge.sign_in' | translate}}</button>
          <p class="text-light small mt-5">&copy; 2010-2023 Hamel Marketing Sdn Bhd. All Rights Reserved.</p>
          <p class="text-light small">Developed by <a href="https://lexiglobal.my" target="_blank">https://lexiglobal.my</a></p>
        </div>
      </form>
    </div>
    <div class="col-md-8 vh-100 d-none d-md-block bg-img border-start border-light">

    </div>
  </div>
