import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SliderComponent implements AfterViewInit {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;
  @ViewChild("carousel", {static: true}) carousel! : NgbCarousel;

  @Input() set setComponent(val: any) {

    if(!val.fields) {
      val.fields = [{title: "Title 1", description: "Description 1", image : "https://lexisoft98.b-cdn.net/resource/placeholder.jpg"}, {title: "Title 2", description: "Description 2", image : "https://lexisoft98.b-cdn.net/resource/placeholder.jpg"}]
    }
    
    if(!val.default) {
      val.default = {title: "Title", description: "Description", image : "https://lexisoft98.b-cdn.net/resource/placeholder.jpg", responsive: "https://lexisoft98.b-cdn.net/resource/placeholder.jpg"}
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }

    this.component = val;
    console.log(this.component)
    this.setImage();
  } 

  public component:any = {fields: []};

  constructor(private changeDetectorRef: ChangeDetectorRef) { 
    
  }

  ngAfterViewInit() {
    this.setImage();
    this.changeDetectorRef.detectChanges();
    this.carousel.interval = this.component.interval *1000;
    this.carousel.keyboard = this.component.keyboard;
    this.carousel.pauseOnHover = this.component.pauseOnHover;
    this.carousel.showNavigationArrows = this.component.showNavigationArrows;
    this.carousel.wrap = this.component.wrap;
  }

  onResize(event:any){
    this.setImage(event.target.innerWidth); 
  }

  setImage(size?:number) {

    if(!size) {
      size = window.innerWidth;
    }

    for(var x = 0; x < this.component.fields.length; x++) {
      let current = this.component.fields[x];
      if(!current.image || (size <= 576 && current.mobile_image)) {
        current.responsive = current.mobile_image;
        this.component.fields[x] = current;
        continue;
      }
  
      current.responsive = current.image;
      this.component.fields[x] = current;

    }
    
  }
}