import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostRoutingModule } from './post-routing.module';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { PostComponent } from './post.component';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';
import { LexiTranslationModule } from 'lexi-translation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguageComponent } from '../language/language.component';

export let AppInjector: LexiDashforgeTopService;

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PostComponent,
    LanguageComponent
  ],
  imports: [
    CommonModule,
    PostRoutingModule,
    LexiTranslationModule,
    NgbModule
  ]
})

export class PostModule { 
  constructor(private injector: LexiDashforgeTopService ) {
    AppInjector = this.injector;
  }
}
