import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-calender-event',
  templateUrl: './calender-event.component.html',
  styleUrls: ['./calender-event.component.css']
})
export class CalenderEventComponent {

  public branches: any = [];
  public data: any = {"event_id": "on-leave"};
  @Output() close = new EventEmitter();

  constructor(private api: ApiService) { 
    this.init()
  }

  async init() {
    let rtn = await this.api.post("/profile/appointment/init", {});
    this.branches = rtn.branch;
    this.data.installation_branch = this.branches[0].uuid
  }

  async submit() {
    let rtn = await this.api.post("/profile/appointment/save", this.data);
    console.log(rtn)

    if(!rtn.status) {
      alert(rtn.message)
      return;
    }

    this.close.emit(true)

  }
}
