import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'lib-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {
console.log(val)
    if(!val) {
      val = {}
    }

    if(!val.value) {
      val.value = "ENTER TEXT HERE"
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }

    this.component = val;
  } 

  public component:any;

  public manupulateData(val:string) {
    val = val.replace('style="text-decoration: underline;"', 'class="text-decoration-underline"')
    return val;
  }

}
