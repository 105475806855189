import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-old-warranty',
  templateUrl: './old-warranty.component.html',
  styleUrls: ['./old-warranty.component.css']
})
export class OldWarrantyComponent {

  public val:any = {};
  public results:any = [];
  public is_loading:boolean = false;

  constructor(private api: ApiService) { }

  async search() {

    if(!this.val.search) {
      alert("Value could not be empty")
      return;
    }

    this.is_loading = true;
    this.results = await this.api.post("/profile/search/warranty", this.val)
    this.is_loading = false;
  }

}
