import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { saveAs } from 'file-saver';

@Component({
  selector: 'lib-inventory-statement',
  templateUrl: './inventory-statement.component.html',
  styleUrls: ['./inventory-statement.component.css']
})
export class InventoryStatementComponent {

  public data: any = {};
  public branches: any = {};
  public surveys:any =[]
  constructor(private api: ApiService) { 
    this.init();
  }

  async init() {
    let init = await this.api.post("/profile/inventory/init", {});
    this.branches = init.branches;
    this.data.branch_uuid = this.branches[0].uuid;

    this.filter()
  }

  async filter() {
    let init = await this.api.post("/profile/get/inventory-data", this.data);

    this.surveys = init.data;
  }

  async export() {
    let data = await this.api.post("/profile/export/inventory-data", this.data);

    const replacer = (key:any, value:any) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map((row:any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "data.csv");
  }
}
