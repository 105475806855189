<div class="bg-black py-5" style="min-height:100vh">
    <div class="container">
    <div class="col-12 col-md-6">
    <section class="ftco-section testimony-section tetx-light" *ngIf="!submitted || !success">
        <div class="container">
            <div class="d-flex flex-column align-items-start pb-3">
                <img class="mb-4" src="https://static-dsme.sgp1.digitaloceanspaces.com/profile/70e9cee8-7e65-565f-946f-4f7b06558196/hamel_logo.svg" style="height:30px;" />
                <div class="text-start heading-section ftco-animate">
                    <span class="subheading text-muted">Survey Form</span>
                <h2 class="mb-3 text-light">Let Us Know How We Can Improve</h2>
                </div>
            </div>
            <ol class="row ftco-animate">
                <ng-container *ngFor="let form of forms">
                    <ng-container [ngSwitch]="form.type">
                        <li class="text-light" *ngSwitchCase="'text'">
                            <div class="form-group">
                                <label class="mb-2 text-light">{{form.title}}<em class="form-required text-danger mx-1" *ngIf="form.required">*</em></label>
                                <input type="text" [(ngModel)]="data[form.name]"  [name]="form.name" class="form-control ">
                            </div>
                        </li>
                        <ng-container *ngSwitchCase="'radio'">
                            <li class="text-light" *ngIf="form.details && form.details.selection && form.details.selection.length > 0">
                                <div class="form-group" >
                                    <div class="mb-2">
                                        <label class="mb-0 text-light">{{form.title}}<em class="form-required text-danger mx-1" *ngIf="form.required">*</em></label>
                                        <p class="mt-0 p-0 mb-2" *ngIf="form.subtitle"><small><em>{{form.subtitle}}</em></small></p>
                                    </div>
                                    <div class="custom-control custom-radio" *ngFor="let s of form.details.selection; let sind = index">
                                        <input type="radio" [id]="form.name + sind" [(ngModel)]="data[form.name]" [name]="form.name" [value]="s.input" class="custom-control-input">
                                        <label class="custom-control-label text-light" [for]="form.name + sind">{{s.input}}</label>
                                    </div>
                                    <div class="form-group text-light" *ngIf="form.details.other">
                                        If other, please specified below :
                                        <input type="text" [id]="form.name + 'other'" [(ngModel)]="data[form.name]" [name]="form.name" class="form-control">
                                    </div>
                                </div>
                            </li>
                        </ng-container> 
                        <ng-container *ngSwitchCase="'checkbox'">
                            <li class="text-light" *ngIf="form.details && form.details.selection && form.details.selection.length > 0">
                                <div class="form-group" >
                                    <label class="mb-2 text-light">{{form.title}}<em class="form-required text-danger mx-1" *ngIf="form.required">*</em></label>
                                    <div class="custom-control custom-checkbox" *ngFor="let s of form.details.selection; let sind = index">
                                        <input type="checkbox" [id]="form.name + sind" [(ngModel)]="s.checked" [name]="form.name + sind" [value]="s.input" class="custom-control-input">
                                        <label class="custom-control-label text-light" [for]="form.name + sind">{{s.input}}</label>
                                    </div>
                                    <div class="form-group text-light" *ngIf="form.details.other">
                                        If other, please specified below :
                                        <input type="text" [id]="form.name + 'other'" [(ngModel)]="data[form.name]['other']" [name]="form.name" class="form-control">
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngSwitchCase="'satisfaction'">
                            <li class="text-light" *ngIf="form.details && form.details.max_score">
                                <div class="form-group">
                                    <div class="mb-2">
                                        <label class="mb-0 text-light">{{form.title}}<em class="form-required text-danger mx-1" *ngIf="form.required">*</em></label>
                                        <p class="mt-0 p-0 mb-2" *ngIf="form.subtitle"><small><em>{{form.subtitle}}</em></small></p>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 text-right d-none d-md-block">
                                            <h6 class=" text-light">Highly Dissatisfied</h6>
                                        </div>
                                        <div class="col d-flex justify-content-center">
                                            <div class="custom-control custom-radio custom-control-inline" *ngFor="let s of [].constructor(form.details.max_score); let sind = index">
                                                <input type="radio" [id]="form.name + sind" [(ngModel)]="data[form.name]" [name]="form.name" [value]="sind+1" class="custom-control-input">
                                                <label class="custom-control-label text-light" [for]="form.name + sind">{{sind+1}}</label>
                                            </div>
                                        </div>
                                        <div class="col-3 text-left d-none d-md-block text-light">
                                            <h6 class=" text-light">Highly Satisfied</h6>
                                        </div>
                                    </div>
                                    <div class="form-group text-light" *ngIf="form.details.other">
                                        If other, please specified below :
                                        <input type="text" [id]="form.name + 'other'" [(ngModel)]="data[form.name]" [name]="form.name" class="form-control">
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textarea'">
                            <li class="text-light">
                                <div class="form-group">
                                    <label class="mb-2 text-light">{{form.title}}<em class="form-required text-danger mx-1" *ngIf="form.required">*</em></label>
                                    <textarea [(ngModel)]="data[form.name]"  [name]="form.name" class="form-control w-100" rows="8"></textarea>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ol>
            <div class="form-group mb-0">
                <input type="submit" value="Submit" class="btn btn-primary py-3 px-5" (click)="submit()">
            </div>
        </div>
    </section>
    </div>

    <section class="ftco-section testimony-section" *ngIf="submitted && success">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-md-8 text-center heading-section text-light">
                    <span class="subheading">Survey Form</span>
                    <h2 class="mb-1 text-light">Thank You For Your Time <br>To Filling The Survey. </h2>
                    <h4 class="mb-3 text-light">Your Response Is Highly Appreciated.</h4>
                </div>
            </div>
        </div>
    </section>
</div>
</div>
