import { Component, AfterViewInit, ViewChild, Input, ElementRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;
  
  @Input() set setComponent(val: any) {
  
    if(!val.fields) {
      val.fields = [{title: "Title 1", descp: "Description 1"}, {title: "Title 2", descp: "Description 2"}]
    }

    if(!val.default) {
      val.default = {title: "Title", descp: "Description"}
    }
    
    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }

    this.component = val;
  } 
  
  public component:any = [];
  
  constructor(public builder: LexiPagebuilderService) {  }
  
}
