<div class="p-3">
<h2>Maintenance Reminder</h2>
<table class="w-100 table">
    <tr *ngIf="reminders.length > 0">
        <th>Title</th>
        <th>Notify Date</th>
        <th>Manual Notify</th>
    </tr>
    <tr *ngFor="let reminder of reminders; let i = index">
        <td>
            {{reminder.title}}
        </td>
        <td>
            {{reminder.notify_date}}
        </td>
        <td>
            <button class="btn btn-sm btn-outline-dark py-0" (click)="sendCoatingReminder(reminder)" *ngIf="dateConverter(reminder.odate) >= dateConverter(today_date)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z"/></svg>
                ({{reminder.sended}})
            </button>
        </td>
    </tr>
    <tr *ngIf="reminders.length == 0">
        <th colspan="3">No Reminder Available</th>
    </tr>
</table>
</div>
<div class="p-3 border-top border-dark">
    <h3>Add Reminder</h3>
    <div class="mb-3">
        <label for="title" class="form-label">Title</label>
        <input class="form-control" name="title" id="title" type="text" [(ngModel)]="data.title">
    </div>
    <div class="mb-3">
        <label for="date" class="form-label">Maintenance Date</label>
        <input class="form-control" name="date" id="date" type="date" [min]="today_date" [(ngModel)]="data.date" (input)="dateChange()">
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input id="reminder_one_week" type="checkbox" class="form-check-input" value="true" [(ngModel)]="data.reminder_one_week" />
            <label for="reminder_one_week" class="form-check-label">Reminder Period 1 week before ({{seven_day}})</label>
        </div>
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input id="reminder_two_week" type="checkbox" class="form-check-input" value="true" [(ngModel)]="data.reminder_two_week" />
            <label for="reminder_two_week" class="form-check-label">Reminder Period 2 week before ({{fortheen_day}})</label>
        </div>
    </div>
    <button class="btn btn-success w-100 mb-3" (click)="save()">SAVE</button>
    <button class="btn btn-outline-danger w-100" (click)="close()">CLOSE</button>

    
</div>