<div class="btn-group btn-group-sm" role="group" aria-label="Edit">
    <button type="button" class="btn lexi-pagebuilder-btn" disabled>{{type}}</button>
    <button type="button" class="btn lexi-pagebuilder-btn" (click)="edit()" *ngIf="type != 'SECTION' && type != 'COLUMN' && type != 'ROW'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
    </button>
    <button type="button" class="btn lexi-pagebuilder-btn" (click)="editStyle()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M18 4V3c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6h1v4h-9c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-9h7c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1h-2z"/></svg>
    </button>
    <button type="button" class="btn lexi-pagebuilder-btn" (click)="delete()"  *ngIf="type != 'COLUMN' ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
    </button>
</div>