import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';
import { ComponentComponent } from '../../component/component.component';

@Component({
  selector: 'lib-new-component',
  templateUrl: './new-component.component.html',
  styleUrls: ['./new-component.component.css']
})
export class NewComponentComponent {

  private parentUID:any; 

  @Input() position:any;
  @Input() parentVC:any;
  @Input() set setParentUID(val:any) {
    if (val) {
      this.parentUID = val;
    }
  }

  constructor(private builder: LexiPagebuilderService, private modalService : NgbModal) { }

  edit() {
    console.log(this.position)
  }

  remove() {
    console.log(this.position)
  }

  async addComponent() {

    this.position = this.builder.getPosition(this.parentUID);

    let modalRef = this.modalService.open(ComponentComponent, {size:"md"});
    console.log((this.position.component.type == "CELL" ? false : true))
    modalRef.componentInstance.setShowLayout = (this.position.component.type == "COLUMN" ? false : true);
    modalRef.componentInstance.getSelectedComponent.subscribe(async (type:string) => {
      
      let uid = this.builder.builderIDGenerator();

      let obj:any = {uid, type, style: {}};

      if(this.builder.validateColumeType(type)) {
        obj.components = this.builder.columnsBuilder(type);
      }

      let sections = this.builder.getSection();

      if(this.position.column_index != undefined) {
        if(!sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components) {
          sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components = []
        }
        sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components.push(obj)
      } else if (this.position.row_index != undefined) {
        sections[this.position.section_index].components[this.position.row_index].components.push(obj)
      } else if (this.position.section_index != undefined) {
        sections[this.position.section_index].components.push(obj)
      } else {
        sections.push(obj)
      }
  
      this.builder.setSection(sections)

      let component = await this.builder.generateComponentType(type, this.parentVC, obj);
      // let ComponentInstance = this.parentVC.createComponent(component, {index:this.parentVC.length - 1}) 
      // console.log(this.position, "position")
      // ComponentInstance.instance.position = this.position;
      // let element_index = undefined;
      // if(row_index == undefined) {
      //   obj.position = {section_index, row_index: section.components.length}
      //   section.components.push(obj)
      // } else if (column_index == undefined && row_index != undefined) {
      //   obj.position = {section_index, row_index}
      // } else if(column_index != undefined && row_index != undefined) {
      //   if(section.components[row_index].components && !section.components[row_index].components[column_index].components) {
      //     section.components[row_index].components[column_index].components = [];
      //   }
      //   element_index = section.components[row_index].components[column_index].components.length;
      //   obj.position = {section_index, row_index, column_index, element_index}
      //   section.components[row_index].components[column_index].components.push(obj);
      // }

      // this.changedObject = obj;
      // this.sections[section_index] = section;

      modalRef.close();
    })

  }
  
}
