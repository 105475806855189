<div class="row">
    <div class="col-12 col-md-4">
        <div class="mb-3">
            <label for="password" class="form-label">New Password</label>
            <input type="password" class="form-control" id="password" placeholder="New Password" [(ngModel)]="data.password">
        </div>
        <div class="mb-3">
            <label for="confirm_password" class="form-label">Confirm New Password</label>
            <input type="password" class="form-control" id="confirm_password" placeholder="Confirm New Password" [(ngModel)]="data.confirm_password">
        </div>
        <button class="btn btn-primary" (click)="submit()">SUBMIT</button>
    </div>
</div>