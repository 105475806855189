<div class="p-3 vh-100 theme-wrapper" >
    <div class="switchSetting setting">

    <div class="d-flex flex-row justify-content-between">
        <h3 class="mb-0">Theme Setting</h3>
        <div class="d-flex align-items-center">
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary d-flex align-items-center" (click)="saveTheme()" aria-label="Save">
                    <svg class="me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"/></svg>
                    <span class="text-white">Save</span>
                </button>
                <button type="button" class="btn btn-outline-secondary d-flex align-items-center" (click)="closeTheme()" aria-label="Close">
                    <svg class="me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45 "><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
                    <span class="text-white">Close</span>
                </button>
            </div>
        </div>
    </div>
    <hr/>
    <h5>Set Your Prefer Color</h5>
    <div class="d-flex flex-row justify-content-start pb-4">
        <div class="col-2 me-2 position-relative">
            <label for="primary_color" class="w-100 color-wrapper" [ngStyle]="{'background': theme.primary_color}">
                <div class="label">
                    Primary
                </div>
            </label>
            <input id="primary_color" type="button" class="color-picker"
            [cpOutputFormat] ="'hex'"
            [colorPicker]="theme.primary_color"
            (colorPickerChange)="theme.primary_color=$event"
            (click) = "$event.stopPropagation()"/>
        </div>
        <div class="col-2 me-2 position-relative">
            <label for="secondary_color" class="w-100 color-wrapper" [ngStyle]="{'background': theme.secondary_color}">
                <div class="label">
                    Secondary
                </div>
            </label>
            <input id="secondary_color" type="button" class="color-picker"
                [cpOutputFormat] ="'hex'"
                [colorPicker]="theme.secondary_color"
                (colorPickerChange)="theme.secondary_color=$event"/>
        </div>
        <div class="col-2 me-2 position-relative">
            <label for="tertiary_color" class="w-100 color-wrapper" [ngStyle]="{'background': theme.tertiary_color}">
                <div class="label">
                    Tertiary
                </div>
            </label>
            <input id="tertiary_color" type="button" class="color-picker"
                [cpOutputFormat] ="'hex'"
                [colorPicker]="theme.tertiary_color"
                (colorPickerChange)="theme.tertiary_color=$event"/>
        </div>
        <div class="col-2 me-2 position-relative">
            <label for="quaternary_color" class="w-100 color-wrapper" [ngStyle]="{'background': theme.quaternary_color}">
                <div class="label">
                    Quaternary
                </div>
            </label>
            <input id="quaternary_color" type="button" class="color-picker"
                [cpOutputFormat] ="'hex'"
                [colorPicker]="theme.quaternary_color"
                (colorPickerChange)="theme.quaternary_color=$event"/>
        </div>
        <div class="col-2 position-relative">
            <label for="quinary_color" class="w-100 color-wrapper" [ngStyle]="{'background': theme.quinary_color}">
                <div class="label">
                    Quinary
                </div>
            </label>
            <input id="quinary_color" type="button" class="color-picker"
                [cpOutputFormat] ="'hex'"
                [colorPicker]="theme.quinary_color"
                (colorPickerChange)="theme.quinary_color=$event"/>
        </div>
    </div>
    <h5 class="mt4">Default Color</h5>
    <div class="d-flex flex-row justify-content-start pb-4">
        <div class="col-2 color-wrapper bg-dark me-2">
            <div class="label">
                Dark
            </div>
        </div>

        <div class="col-2 color-wrapper bg-light me-2">
            <div class="label">
                Light
            </div>
        </div>

        <div class="col-2 color-wrapper bg-danger me-2">
            <div class="label">
                Error
            </div>
        </div>

        <div class="col-2 color-wrapper bg-success">
            <div class="label">
                Success
            </div>
        </div>
    </div>
    
    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Default Background Color</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.default_bg_color" class="form-control-sm form-control-edit rounded-0" >
                <option [ngValue]="null" [disabled]="theme.primary_font">Select Default Background Color</option>
                <option [value]="'bg-primary'">Primary Color</option>
                <option [value]="'bg-secondary'">Secondary Color</option>
                <option [value]="'bg-tertiary'">Tertiary Color</option>
                <option [value]="'bg-quaternary'">Quaternary Color</option>
                <option [value]="'bg-quinary'">Quinary Color</option>
                <option [value]="'bg-dark'">Dark Color</option>
                <option [value]="'bg-light'">Light Color</option>
                <option [value]="'bg-danger'">Error Color</option>
                <option [value]="'bg-success'">Success Color</option>
            </select>     
        </div>
    </div>

    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Default Text Color</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.default_text_color" class="form-control-sm form-control-edit rounded-0">
                <option [ngValue]="null" [disabled]="theme.primary_font">Select Default Text Color</option>
                <option [value]="'text-primary'">Primary Color</option>
                <option [value]="'text-secondary'">Secondary Color</option>
                <option [value]="'text-tertiary'">Tertiary Color</option>
                <option [value]="'text-quaternary'">Quaternary Color</option>
                <option [value]="'text-quinary'">Quinary Color</option>
                <option [value]="'text-dark'">Dark Color</option>
                <option [value]="'text-light'">Light Color</option>
                <option [value]="'text-danger'">Error Color</option>
                <option [value]="'text-success'">Success Color</option>
            </select>     
        </div>
    </div>
    <hr/>
    <h5>Font Family</h5>
    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Primary Font</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.primary_font"  class="form-control-sm form-control-edit rounded-0">
                <option [ngValue]="null" [disabled]="theme.primary_font">Select Primary Font</option>
                <option [ngValue]="font" *ngFor="let font of fonts">{{font.value}}</option>
            </select>   
        </div>
    </div>
    <p class="text-center my-2" [ngStyle]="{'font-family': theme.primary_font && theme.primary_font.rules ? theme.primary_font.rules : ''}">The quick brown fox jumps over the lazy dog</p>

    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Secondary Font</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.secondary_font"  class="form-control-sm form-control-edit rounded-0">
                <option [ngValue]="null" [disabled]="theme.secondary_font">Select Secondary Font</option>
                <option [ngValue]="font" *ngFor="let font of fonts">{{font.value}}</option>
            </select>   
        </div>
    </div>
    <p class="text-center my-2" [ngStyle]="{'font-family': theme.secondary_font && theme.secondary_font.rules ? theme.secondary_font.rules : ''}">The quick brown fox jumps over the lazy dog</p>

    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Tertiary Font</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.tertiary_font"  class="form-control-sm form-control-edit rounded-0">
                <option [ngValue]="null" [disabled]="theme.tertiary_font">Select Tertiary Font</option>
                <option [ngValue]="font" *ngFor="let font of fonts">{{font.value}}</option>
            </select>   
        </div>
    </div>
    <p class="text-center my-2" [ngStyle]="{'font-family': theme.tertiary_font && theme.tertiary_font.rules ? theme.tertiary_font.rules : ''}">The quick brown fox jumps over the lazy dog</p>

    <div class="btn-toolbar mt-2" role="toolbar">
        <span class="btn btn-dark disabled text-start rounded-0 flex-fill btn-sm">Quaternary Font</span>
        <div class="btn-group btn-group-sm" role="group">
            <select [(ngModel)]="theme.quaternary_font"  class="form-control-sm form-control-edit rounded-0">
                <option [ngValue]="null" [disabled]="theme.quaternary_font">Select Quaternary Font</option>
                <option [ngValue]="font" *ngFor="let font of fonts">{{font.value}}</option>
            </select>   
        </div>
    </div>
    <p class="text-center my-2" [ngStyle]="{'font-family': theme.quaternary_font && theme.quaternary_font.rules ? theme.quaternary_font.rules : ''}">The quick brown fox jumps over the lazy dog</p>

    </div>
</div>