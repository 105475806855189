<div class="d-flex align-items-center vh-100">
    <div class="col-12 col-md-4 p-5">
      <form #forgotPasswordForm="ngForm" (submit)="forgotPasswordForm.valid && submit()" class="sign-wrapper w-100" [ngClass]="{'was-validated': forgotPasswordForm.submitted && !forgotPasswordForm.valid}">
        <div class="wd-100p">
          <h3 class="tx-color-01 mg-b-5">{{'dashforge.forgot_password' | translate}}</h3>
          <p class="tx-color-03 tx-16 mg-b-40">{{'dashforge.forgot_password_message' | translate}}</p>
         
          <div class="form-group">
            <label>{{'dashforge.email' | translate}}</label>
            <input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="data.email" [placeholder]="placeholder.email_placeholder" required />
            <div *ngIf="forgotPasswordForm.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors?.['required']">{{'dashforge.email_required' | translate}}</div>
            </div>
          </div>
          <button class="btn btn-brand-02 btn-block">{{'dashforge.submit' | translate}}</button>
        </div> 
        <div class="mt-3 d-flex justify-content-between" *ngIf="dsme">
            <a [routerLink]="['/auth/login']">{{'dashforge.sign_in' | translate}}</a>
        </div>
        </form>
    </div>
    <div class="col-md-8 bg-dark vh-100">

    </div>
  </div>
