<div class="row">
    <div class="col-12 col-md-3">
        <div class="form-group">
            <label for="fb-page">Facebook Page</label>
            <select class="form-select" id="fb-page">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>
        <div class="form-group">
            <label for="fb-page">Message Status</label>
            <select class="form-select" id="fb-page">
                <option value="new">New</option>
                <option value="complete">Complete</option>
                <option value="spam">Spam</option>
            </select>
        </div>
        <div class="chat-messages" style="max-height: 65vh;">
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="badge bg-success float-right">5</div>
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Vanessa Tucker
                    <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="badge bg-success float-right">2</div>
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    William Harris
                    <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Sharon Lessman
                    <div class="small"><span class="fas fa-circle chat-online"></span> Online</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Christina Mason
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Fiona Green
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Doris Wilder
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Haley Kennedy
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Jennifer Chang
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Christina Mason
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Fiona Green
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Doris Wilder
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Haley Kennedy
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action border-0">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1 ml-3">
                    Jennifer Chang
                    <div class="small"><span class="fas fa-circle chat-offline"></span> Offline</div>
                </div>
            </div>
        </a>
    </div>
    </div>
    <div class="col-12 col-md-6 bg-white">
        <div class="position-relative">
            <div class="chat-messages p-4">

            <div class="chat-message-right pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:33 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:34 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                </div>
            </div>

            <div class="chat-message-right mb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:35 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Cum ea graeci tractatos.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:36 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit.
                    Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:37 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci.
                </div>
            </div>

            <div class="chat-message-right mb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:38 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:39 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                </div>
            </div>

            <div class="chat-message-right mb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:40 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Cum ea graeci tractatos.
                </div>
            </div>

            <div class="chat-message-right mb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:41 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Morbi finibus, lorem id placerat ullamcorper, nunc enim ultrices massa, id dignissim metus urna eget purus.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:42 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit.
                    Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus.
                </div>
            </div>

            <div class="chat-message-right mb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:43 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div class="font-weight-bold mb-1">You</div>
                    Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.
                </div>
            </div>

            <div class="chat-message-left pb-4">
                <div>
                    <div class="text-muted small text-nowrap mt-2">2:44 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                    <div class="font-weight-bold mb-1">Sharon Lessman</div>
                    Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                </div>
            </div>

        </div>
    </div>

    <div class="flex-grow-0 py-3 px-4 border-top">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Type your message">
            <button class="btn btn-primary">Send</button>
        </div>
    </div>
    </div>
</div>