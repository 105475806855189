<ul class="component-menu text-light bg-dark mb-0">
  <li class="p-3 border-bottom">Pick your preferred component</li>
  <li class="header active">Basic</li>
  <li class="detail active">
    
    <div class="d-flex flex-row flex-wrap">
      <div class="col-2 component">
        <div class="content row g-0" (click)="select('text')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="m40.5 29.05-3.55-3.55 1.45-1.45q.4-.4 1.05-.4t1.05.4l1.45 1.45q.4.4.4 1.05t-.4 1.05ZM24 42v-3.55l10.8-10.8 3.55 3.55L27.55 42ZM6 31.5v-3h15v3Zm0-8.25v-3h23.5v3ZM6 15v-3h23.5v3Z"/>
          </svg>
          <p class="m-0">Text</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('image')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h18.95q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q28.6 9 27.95 9H9v30h30V20.1q0-.65.425-1.075.425-.425 1.075-.425.65 0 1.075.425Q42 19.45 42 20.1V39q0 1.2-.9 2.1-.9.9-2.1.9Zm27.5-24.9q-.65 0-1.075-.425Q35 16.25 35 15.6v-2.55h-2.55q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075.425-.425 1.075-.425H35V7.5q0-.65.425-1.075Q35.85 6 36.5 6q.65 0 1.075.425Q38 6.85 38 7.5v2.55h2.55q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425H38v2.55q0 .65-.425 1.075-.425.425-1.075.425ZM13.55 33.9H34.5q.5 0 .7-.4.2-.4-.1-.8l-5.7-7.6q-.25-.3-.6-.3t-.6.3l-5.75 7.55-4.1-5.4q-.25-.3-.6-.3t-.6.3l-4.2 5.45q-.25.4-.025.8t.625.4ZM9 20.1V39 9v11.1Z"/>
          </svg>
          <p class="m-0">Image</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('button')" >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M23.25 2q4.55 0 7.775 3.175Q34.25 8.35 34.25 12.9q0 2.6-1.125 4.9Q32 20.1 29.95 21.7h-1.7v-2.5q1.45-1.15 2.225-2.8.775-1.65.775-3.5 0-3.3-2.35-5.6T23.25 5q-3.3 0-5.65 2.3-2.35 2.3-2.35 5.6 0 1.85.775 3.5t2.225 2.8v3.6q-2.8-1.45-4.4-4.1-1.6-2.65-1.6-5.8 0-4.55 3.225-7.725T23.25 2Zm-1.9 42q-.85 0-1.6-.325-.75-.325-1.3-.875L8.15 32.5l2.8-2.9q.7-.7 1.575-1.075t1.825-.125l3.9.9V13q0-2.1 1.45-3.55Q21.15 8 23.25 8q2.1 0 3.55 1.45 1.45 1.45 1.45 3.55v8.6h1.3q.25 0 .45.1t.45.2l7.4 3.6q1.2.55 1.775 1.775.575 1.225.325 2.525l-1.8 10.9q-.25 1.45-1.4 2.375t-2.6.925Zm-.4-3H35l2.15-12.45L28 24h-2.75V13q0-.9-.55-1.45-.55-.55-1.45-.55-.9 0-1.45.55-.55.55-.55 1.45v19.95l-7.7-1.65-1.15 1.15ZM35 41H20.95 35Z"/>
          </svg>
          <p class="m-0">Button</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('video')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="m7 8 3.7 7.6h6.5L13.5 8h4.45l3.7 7.6h6.5L24.45 8h4.45l3.7 7.6h6.5L35.4 8H41q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8Zm0 10.6V37h34V18.6Zm0 0V37Z"/>
          </svg>
          <p class="m-0">Video</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('html')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M1.2 30q-.5 0-.85-.35T0 28.8v-9.6q0-.5.35-.85T1.2 18q.5 0 .85.35t.35.85v3.25h5.4V19.2q0-.5.35-.85T9 18q.5 0 .85.35t.35.85v9.6q0 .5-.35.85T9 30q-.5 0-.85-.35t-.35-.85v-3.95H2.4v3.95q0 .5-.35.85T1.2 30ZM17 30q-.5 0-.85-.35t-.35-.85v-8.4h-2.3q-.5 0-.85-.35t-.35-.85q0-.5.35-.85t.85-.35h7q.5 0 .85.35t.35.85q0 .5-.35.85t-.85.35h-2.3v8.4q0 .5-.35.85T17 30Zm24.6 0q-.65 0-1.075-.425-.425-.425-.425-1.075v-9.3q0-.5.35-.85t.85-.35q.5 0 .85.35t.35.85v8.4h4.3q.5 0 .85.35t.35.85q0 .5-.35.85t-.85.35ZM25 30q-.5 0-.85-.35t-.35-.85v-9.1q0-.75.475-1.225Q24.75 18 25.5 18h10q.75 0 1.225.475.475.475.475 1.225v9.1q0 .5-.35.85T36 30q-.5 0-.85-.35t-.35-.85v-8.4h-3.1v6.3q0 .5-.35.85t-.85.35q-.5 0-.85-.35t-.35-.85v-6.3h-3.1v8.4q0 .5-.35.85T25 30Z"/>
          </svg>
          <p class="m-0">HTML</p>
        </div>
      </div>
    </div>

  </li>
  <li class="header" *ngIf="showLayout">Layout</li>
  <li class="detail" *ngIf="showLayout">
    <div class="d-flex flex-row flex-wrap">
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-1')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M37.9,9.3H12.1c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h25.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C40.3,10.4,39.2,9.3,37.9,9.3z"/>
          </svg>
          <p class="m-0">1/1</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-2')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M20.9,9.3h-8.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h8.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C23.3,10.4,22.2,9.3,20.9,9.3z"/>
            <path d="M37.9,9.3h-8.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h8.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C40.3,10.4,39.2,9.3,37.9,9.3z"/>
          </svg>
          <p class="m-0">1/2</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-3')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M15.9,9.3h-3.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h3.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C18.3,10.4,17.2,9.3,15.9,9.3z"/>
            <path d="M26.9,9.3h-3.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h3.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C29.3,10.4,28.2,9.3,26.9,9.3z"/>
            <path d="M37.9,9.3h-3.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h3.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C40.3,10.4,39.2,9.3,37.9,9.3z"/>
          </svg>
          <p class="m-0">1/3</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-4')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M13.9,9.3h-1.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h1.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C16.3,10.4,15.2,9.3,13.9,9.3z"/>
            <path d="M37.9,9.3h-1.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h1.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C40.3,10.4,39.2,9.3,37.9,9.3z"/>
            <path d="M30,9.3h-1.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4H30c1.3,0,2.4-1.1,2.4-2.4v-6.6C32.4,10.4,31.3,9.3,30,9.3z"/>
            <path d="M22,9.2h-1.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4H22c1.3,0,2.4-1.1,2.4-2.4v-6.6C24.4,10.3,23.3,9.2,22,9.2z"/>
          </svg>
          <p class="m-0">1/4</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-5')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M37.9,9.3H24.1c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h13.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C40.3,10.4,39.2,9.3,37.9,9.3z"/>
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M16.9,9.3h-4.8c-1.3,0-2.4,1.1-2.4,2.4v6.6c0,1.3,1.1,2.4,2.4,2.4h4.8c1.3,0,2.4-1.1,2.4-2.4v-6.6C19.3,10.4,18.2,9.3,16.9,9.3z"/>
          </svg>
          <p class="m-0">1/3 | 2/3</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('columns-type-6')">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 30" style="enable-background:new 0 0 50 30;" xml:space="preserve">
            <path d="M12.1,9.3h13.8c1.3,0,2.4,1.1,2.4,2.4v6.6c0,1.3-1.1,2.4-2.4,2.4H12.1c-1.3,0-2.4-1.1-2.4-2.4v-6.6C9.7,10.4,10.8,9.3,12.1,9.3z"/>
            <path d="M43,3.7H7c-1.4,0-2.6,1.2-2.6,2.6v17.3c0,1.4,1.2,2.6,2.6,2.6H43c1.4,0,2.6-1.2,2.6-2.6V6.3C45.5,4.9,44.4,3.7,43,3.7zM44.3,23.7c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V6.3C5.7,5.6,6.3,5,7,5H43c0.8,0,1.4,0.6,1.4,1.4V23.7z"/>
            <path d="M33.1,9.3h4.8c1.3,0,2.4,1.1,2.4,2.4v6.6c0,1.3-1.1,2.4-2.4,2.4h-4.8c-1.3,0-2.4-1.1-2.4-2.4v-6.6C30.7,10.4,31.8,9.3,33.1,9.3z"/>
          </svg>
          <p class="m-0">2/3 | 1/3</p>
        </div>
      </div>
    </div>
  </li>
  <!-- <li class="header">Component</li>
  <li class="detail">
    
    <div class="d-flex flex-row flex-wrap">
      <div class="col-2 component">
        <div class="content">
          Product Cart
        </div>
      </div>
      <div class="col-2 component">
        <div class="content">
          Forms
        </div>
      </div>
    </div>
  </li> -->
  <li class="header">Feature</li>
  <li class="detail">
    <div class="d-flex flex-row flex-wrap">
      <div class="col-2 component">
        <div class="content" (click)="select('slider')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="m21.65 30.95 8.8-5.7q.35-.25.525-.55.175-.3.175-.7 0-.4-.175-.7-.175-.3-.525-.55l-8.8-5.7q-.75-.5-1.525-.075-.775.425-.775 1.325v11.4q0 .9.775 1.325.775.425 1.525-.075ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30Zm0 0V9v30Z"/>
          </svg>
          <p class="m-0">Silder</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('card')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M39 35v-9.5H9V35h30Zm0-12.5V13H9v9.5h30ZM9 38q-1.2 0-2.1-.9Q6 36.2 6 35V13q0-1.2.9-2.1.9-.9 2.1-.9h30q1.2 0 2.1.9.9.9.9 2.1v22q0 1.2-.9 2.1-.9.9-2.1.9Z"/>
          </svg>
          <p class="m-0">Card</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('accordion')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"/>
          </svg>
          <p class="m-0">Accordion</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('list')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M6.5 14q-1.05 0-1.775-.725Q4 12.55 4 11.5q0-1.05.725-1.775Q5.45 9 6.5 9q1.05 0 1.775.725Q9 10.45 9 11.5q0 1.05-.725 1.775Q7.55 14 6.5 14Zm.05 12.5q-1.05 0-1.8-.725T4 24q0-1.05.75-1.775.75-.725 1.8-.725 1 0 1.725.75Q9 23 9 24t-.725 1.75q-.725.75-1.725.75Zm0 12.5q-1.05 0-1.8-.725T4 36.55q0-1.05.75-1.8t1.8-.75q1 0 1.725.75.725.75.725 1.8 0 1-.725 1.725Q7.55 39 6.55 39ZM14 13v-3h26v3Zm0 12.5v-3h22.65q-2.3 0-4.35.8t-3.65 2.2ZM14 38v-3h10.25q0 .8.1 1.55t.3 1.45Zm22.65 6.3q-3.9 0-6.65-2.775-2.75-2.775-2.75-6.575 0-3.9 2.75-6.675t6.65-2.775q3.85 0 6.625 2.775t2.775 6.675q0 3.8-2.775 6.575Q40.5 44.3 36.65 44.3Zm-.75-3h1.65v-5.5h5.5v-1.65h-5.5v-5.5H35.9v5.5h-5.5v1.65h5.5Z"/>
          </svg>
          <p class="m-0">List</p>
        </div>
      </div>
      <div class="col-2 component">
        <div class="content" (click)="select('map')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="m30.6 42-13.15-4.65L8.5 40.9q-.85.45-1.675-.05Q6 40.35 6 39.35v-27.9q0-.65.375-1.15.375-.5.975-.75L17.45 6l13.15 4.6 8.9-3.55q.85-.4 1.675.075Q42 7.6 42 8.6v28.25q0 .55-.375.95-.375.4-.925.6Zm-1.7-3.75V13l-9.8-3.3v25.25Zm3 0L39 35.9V10.3L31.9 13ZM9 37.65l7.1-2.7V9.7L9 12.05ZM31.9 13v25.25ZM16.1 9.7v25.25Z"/>
          </svg>
          <p class="m-0">Maps</p>
        </div>
      </div>
    </div>
  </li>
  <li class="header">Custom Template</li>
  <li class="detail">
    <div class="d-flex flex-row flex-wrap">
      <p class="px-3">No Custom Template Available</p>
    </div>
  </li>
  <li class="header">Feature Template</li>
  <li class="detail">
    <div class="d-flex flex-row flex-wrap">
      <p class="px-3">No Custom Template Available</p>
    </div>
  </li>
</ul>
