import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-component-view',
  templateUrl: './component-view.component.html',
  styleUrls: ['./component-view.component.css']
})
export class ComponentViewComponent {
  public component :any = {};
  @Input() set setComponent(val:any) {
    this.component = val;
    console.log(this.component)
  };
  constructor() { }

  buildClass(key:string = "style") {
    if(!this.component[key]) {
      return ""
    }
    let classname = Object.values(this.component[key]);

    if(classname.length && key == "wrapper_style") {
      classname.push("d-flex")
    }

    return classname.join(" ")
  }

  buildStyle() {
    let background = this.component.background;

    if(!background) {
      return {}
    }

    let y = {'background': 'url(' + background + ");"}
    return y
  }
  

}
