import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';
import { ResourceManagerComponent } from 'lexi-resource';

@Component({
  selector: 'lib-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.css']
})
export class StyleComponent {

  public component: any = {style: {}}
  public loading: boolean = false;

  @Input() set setComponent(val:any) {
    val.style = val.style ?? {}
    val.wrapper_style = val.wrapper_style ?? {}
    this.component = val;
  };
  @Output() update = new EventEmitter();

  constructor(private modalService: NgbModal, public builder: LexiPagebuilderService) {
   
  }

  public type: string = "element";

  setStyle(key:string, val:string, data_key:string = "style") {
    if(!this.component[data_key]) {
      this.component[data_key] = {}
    }
    this.component[data_key][key] = val;
    this.triggerUpdate()
  }
  setStyleOnChange(event:any, key:string, data_key:string = "style") {
    if(!event.target.value) {
      return;
    }

    if(!this.component[data_key]){
      this.component[data_key] = {}
    }
    
    this.component[data_key][key] = event.target.value;
    this.triggerUpdate()
  }

  init() {
    var headers = document.getElementsByClassName("option-header")

    for(var x = 0; x < headers.length; x++) {
      let header = headers[x];
      header.addEventListener("click", (event) => {

        let sibling = header.nextElementSibling;
        if(!sibling) {
          return;
        }

        for(var y = 0; y < headers.length; y++) {
          let otherHeader = headers[y]
          let othersibling = otherHeader.nextElementSibling;
          if(othersibling && othersibling != sibling) {
            otherHeader.classList.remove("active");
            othersibling.classList.remove("active");
          }
        }

        header.classList.toggle("active");
        sibling.classList.toggle("active");
      })
    }
  }

  selectMediaWithKey(key?:string) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.getSelected.subscribe((v:any) => {

      if(v && v[0] && key) {
        this.component[key] = v[0]['key']
      } else if(v && v[0]) {
        this.component.value = v[0]['key']
      }
      this.triggerUpdate();

      modal.close();

    })
  }

  selectMedia(i?:number, key?:string) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.getSelected.subscribe((v:any) => {

      if(i != undefined && key != undefined) {
        this.component.items[i][key] =  v[0]['key'];
        this.triggerUpdate();
        modal.close();
        return;
      }

      if(v && v[0]) {
        this.component.value = v[0]['key']
        this.triggerUpdate();
        modal.close();
      }
    })
  }

  directStyle() {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.getSelected.subscribe((v:any) => {
      if(v && v[0]) {
        let file = v[0]['key']
        let ext = file.split(".").pop()
        if(ext == "mp4") {
          this.component.background_type = 'video'
        } else {
          this.component.background_type = 'image'
        }
        this.component.background = v[0]['key']
        modal.close();
        this.triggerUpdate();
      }
    })
  }
  
  toggleStyle(key: string, value: string, data_key:string = "style") {
    let innerHtml :string = "";
    if(!this.component[data_key]){
      this.component[data_key] = {};
      this.component.innerHTML = "On";
    }
    if(this.component[data_key] && this.component[data_key][key]) {
      delete this.component[data_key][key];
      this.component.innerHTML = "Off";
    } else {
      this.component[data_key][key] = value;
    }

    this.triggerUpdate();
      

  }

  toggleOption(event:any) {
    let accrodion = event.target.parentNode.classList.contains("lexi-accordion")
    if(accrodion) {
      event.target.parentNode.classList.toggle("active")
      return;
    }
    accrodion = event.target.parentNode.parentNode.classList.contains("lexi-accordion")
    if(accrodion) {
      event.target.parentNode.parentNode.classList.toggle("active")
      return;
    }
    event.target.parentNode.parentNode.parentNode.classList.toggle("active")
  }

  triggerUpdate(): void {
    this.update.emit(this.component);
  }
}
