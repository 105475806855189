import { NgModule } from '@angular/core';
import { LexiPagebuilderRoutingModule } from './lexi-pagebuilder-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LexiPagebuilderRoutingModule,
  ],
  exports: [
  ]
})
export class LexiPagebuilderModule { }
