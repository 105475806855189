import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';

@Component({
  selector: 'lib-set-reminder',
  templateUrl: './set-reminder.component.html',
  styleUrls: ['./set-reminder.component.css']
})
export class SetReminderComponent {

  @Input() set setJobUUID(v:any) {
    this.job_uuid = v;
    this.init();
  };

  @Output() closeEvent = new EventEmitter()

  public reminders:any = [];
  public data:any = {period: ""};
  public today_date:any;

  private job_uuid:any;

  public seven_day:any;
  public fortheen_day:any;


  constructor(private api: ApiService, private notify: LexiNotifyService) {

    this.today_date = new Date().toISOString().split('T')[0];
    var date = new Date();
    date.setDate(date.getDate() + 7);
    this.data.date = date.toISOString().split('T')[0];
    this.dateChange();
  }

  async init() {
    let rtn = await this.api.post("/profile/job/coating/reminder", {job_uuid: this.job_uuid});
    this.reminders = rtn.reminders;
  }

  async save() {

    if(!this.data.date || !this.data.title) {
      this.notify.error("All field are manadatory");
      return;
    }

    this.data.job_uuid = this.job_uuid
    let rtn = await this.api.post("/profile/job/coating/reminder/save", this.data);

    if(!rtn.status) {
      this.notify.error(rtn.message);
      return
    }

    this.notify.success("Reminder Set Successfully.")
    await this.init();
    this.data = {}
  }

  async sendCoatingReminder(reminder:any) {
      let y = await this.api.post("/profile/job/coating/reminderByIndex", reminder);
      console.log(y)
      this.notify.success("Coating reminder send.");
      await this.init();
  }

  close() {
    this.closeEvent.emit(true)
  }

  dateConverter(date:string) {
    return new Date(date)
  }

  dateChange() {
    let y = this.data.date

    let k = new Date(y)
    k.setDate(k.getDate() - 7);
    this.seven_day = k.toISOString().split('T')[0];
    let z = new Date(y)
    z.setDate(z.getDate() - 14);
    this.fortheen_day = z.toISOString().split('T')[0];
  }
}
