<div class="setting">
    <div class="mb-2" *ngFor="let s of setup">
        <container-element [ngSwitch]="s.type" >
            <ng-container *ngSwitchCase="'fields'">
                <div *ngFor="let comp of component.fields; let i = index" class="lexi-accordion">
                    <div class="header" (click)="toggleOption($event)">
                        <h6 class="d-flex flex-row justify-content-between align-items-center">
                            <span>Item {{i+1}}</span>
                            <div class="d-flex flex-row">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
                                <button *ngIf="component.fields.length > 1" class="border-0 bg-dark" (click)="removeItem(i)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                                </button>
                            </div>
                        </h6>
                    </div>
                    <div class="options">
                        <div *ngFor="let fields of s.fields">
                            <lib-setting [setSetup]="fields" [setComponent]="comp" (update)="updatezreseult($event)"></lib-setting>
                        </div>
                    </div>
                </div>
                <button class="btn btn-outline-light w-100 btn-sm" (click)="addItem()">Add Item</button>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <lib-setting [setSetup]="s" [setComponent]="component" (update)="updatezreseult($event)"></lib-setting>
            </ng-container>
        </container-element>
    </div>
</div>
