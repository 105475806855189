<div class="row">
    <div class="col-12 col-md-2">
        <div>
            <label for="branch"><strong>Calender Function</strong></label>
            <div class="btn-group w-100 mb-2" role="group" >
                <button class="btn btn-outline-primary btn-sm col" (click)="addEvent()">Event</button>
                <button [routerLink]="['/new']" class="btn btn-outline-primary btn-sm col">Appointment</button>
            </div>
              
            <div class="form-group">
                <label for="branch" class="me-2">Select Branch</label>
                <select class="form-control" id="branch" [(ngModel)]="selected" (change)="getCalender()">
                    <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
                </select>
            </div>
        </div>
        <div>
            Identicator:
            <div class="d-flex flex-column">
                <div *ngFor="let branch of branches" class="d-flex flex-row align-items-center mb-2">
                    <div [ngStyle]="{'background-color': branch.color}" style="width:30px; height:18px; border-radius: 3px;" class="me-2" *ngIf="branch.uuid != 'all'"></div>
                    <span *ngIf="branch.uuid != 'all'">{{branch.title}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-10">
        <lib-lexi-calendar #calendar></lib-lexi-calendar>
    </div>
</div>
