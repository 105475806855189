import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceManagerComponent } from 'lexi-resource';

@Component({
  selector: 'lib-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent {

  public component:any;
  public setup:any;

  @Input() set setComponent(v:any) {
    this.component = v;
  }
  @Input() set setSetup(v:any) {
    this.setup = v;
  };

  @Output() update = new EventEmitter();
  
  constructor(private modalService: NgbModal) {
    
  }

  triggerUpdate(): void {
    this.update.emit(this.component);
  }

  getData($event:any) {
    this.component[this.setup.key] = $event;
    this.triggerUpdate()
  }

  selectMediaWithKey(key?:string) {
    let modal = this.modalService.open(ResourceManagerComponent, {size: "xl"});
    modal.componentInstance.setDefault = "/upload"
    modal.componentInstance.selectType = "single"
    modal.componentInstance.setSupportedFileType = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4']
    modal.componentInstance.getSelected.subscribe((v:any) => {

      if(v && v[0] && key) {
        this.component[key] = v[0]['key']
      } else if(v && v[0]) {
        this.component.value = v[0]['key']
      }
      this.triggerUpdate();

      modal.close();

    })
  }

  setStyle(key:string, val:string, data_key:string = "style") {
    if(!this.component[data_key]) {
      this.component[data_key] = {}
    }
    this.component[data_key][key] = val;
    console.log(this.component)
    this.triggerUpdate()
  }

  toogleSetting(key:string) {
    this.component[key] = !this.component[key]
    this.triggerUpdate()
  }

  setType(key:string, val:string) {
    this.component[key] = val;
  }


}
