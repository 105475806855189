import { Component, AfterViewInit, ViewChild, Input, ElementRef, ViewContainerRef} from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements AfterViewInit {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {

    if(!val.value) {
      val.value = "https://lexisoft98.b-cdn.net/resource/placeholder.jpg"
      val.title = "Card Title"
      val.descr = "Write Your Description Here"
      val.set_image = true;
      val.set_title = true;
      val.set_descr = true;
      val.set_btn = false;
    }

    if (!val.btn) {
      val.btn = {}
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }



    this.component = val;
  } 

  public component:any;

  constructor(public builder: LexiPagebuilderService, private vcr: ViewContainerRef) {  }

  ngAfterViewInit() { 

    this.wrapper.nativeElement.parentElement.classList.add("h-100")
    // this.builder.setStyle(this.wrapper, this.component);
    // this.builder.appendEditComponent(this.vcr, this.wrapper, this.component.uid);
  }
}