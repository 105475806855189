<div #wrapper>
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel *ngFor="let item of component.fields; let i = index">
            <ng-template ngbPanelTitle>
                <div class="w-100 {{component.color}} {{component.size}} {{component.font_family}} {{component.style.title_text_align}} {{component.title_letter_spacing}} {{component.title_line_height}}" [innerHTML]="item.title"></div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="{{component.dcolor}} {{component.dsize}} {{component.dfont_family}} {{component.style.descr_text_align}} {{component.descr_letter_spacing}} {{component.descr_line_height}}" [innerHTML]="item.descp"></div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>