import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'lib-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.css']
})
export class WarrantyComponent {
  public uuid:any
  constructor(private activatedRoute :ActivatedRoute, public _sanitizer: DomSanitizer) { 
    const form_uuid: Observable<string> = this.activatedRoute.params.pipe(map(p => p['uuid']));
    form_uuid.subscribe((val:any) => {
      if (val) {
        this.uuid = val;
      }
    })
  }

}
