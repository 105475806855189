<nav class="navbar navbar-light bg-light d-flex justify-content-between">
    <strong>INSPECTION</strong>
    <div class="d-flex flex-row">
        <div class="btn-group" role="group">
            <button (click)="undo()" type="button" class="btn btn-secondary">UNDO</button>
            <button (click)="redo()" type="button" class="btn btn-secondary">REDO</button>
            <button (click)="erase()" type="button" class="btn btn-secondary">ERASE</button>
            <button (click)="save()" type="button" class="btn btn-secondary">SAVE</button>
        </div>
        <div class="btn-group" role="group"  class="ms-3">
            <button (click)="close()" type="button" class="btn btn-secondary">CLOSE</button>
        </div>
    </div>
</nav>
<div style="width:100%; overflow: auto;">
    <ng-whiteboard [canvasWidth]="800" [fullScreen]="true" [canvasHeight]="600" (save)="onsave($event)" style="width:800px; height:600px; display: block;" (dataChange)="getData($event)" [data]="data" [strokeColor]="'#0000FF'"></ng-whiteboard>
</div>
