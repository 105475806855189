import { Component, AfterViewInit, ElementRef, Input, ViewChild } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements AfterViewInit {

    @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;
  
    @Input() set setComponent(val: any) {
  
      if(!val.fields) {
        val.fields = [{descp: "Enter your text here"},{descp: "Enter your text here"}]
      }

      if(!val.default) {
        val.default = {descp: "Enter your text here"}
      }

      if(!val.style || Object.keys(val.style).length == 0) {
        val.style = {'width': "w-100"};
      }

      if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
        val.wrapper_style = {'width': "w-100"};
      }

      this.component = val;
    } 
  
    public component:any;
  
    constructor(public builder: LexiPagebuilderService) {  }
  
    ngAfterViewInit() {
      // this.builder.setStyle(this.wrapper, this.component);
      // this.builder.appendEditComponent(this.vcr, this.wrapper, this.component.uid);
    }
  }
