<div class="p-3 bg-dark text-light">
    <p class="mb-2 mt-2">Page Title * </p>
    <input type="text" class="form-control form-control-sm" [(ngModel)]="page.title"/>

    <p class="mb-2 mt-2">Meta Title * </p>
    <input type="text" class="w-100 resize-none" [(ngModel)]="page.meta_title" />

    <p class="mb-2 mt-2">Meta Description * </p>
    <textarea class="w-100 resize-none" [(ngModel)]="page.meta_description" rows="6">
    </textarea>

    <p class="mb-2 mt-2">Meta Keyword (Split by comma) * </p>
    <textarea class="w-100 resize-none" [(ngModel)]="page.meta_keywords" rows="6">
    </textarea>

    <div class="btn-group btn-group-sm w-100 mt-2" role="group" aria-label="Width">
        <span class="btn btn-dark btn-xs border-secondary disabled">Background Color</span>
        <select [(ngModel)]="page.style.background_color">
            <option [value]="">None</option>
            <option [value]="'bg-primary'">Primary Background Color</option>
            <option [value]="'bg-secondary'">Secondary Background Color</option>
            <option [value]="'bg-tertiary'">Tertiary Background Color</option>
            <option [value]="'bg-quaternary'">Quaternary Background Color</option>
        </select>                      
    </div>

    <div class="d-flex justify-content-center">
        <button class="mb-2 mt-2 rounded-pill py-2 w-75 text-center btn btn-secondary" type="button" (click)="saveMetaData()">SAVE META DATA</button>
    </div>
</div>