import { Component, AfterViewInit, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.css']
})
export class HtmlComponent implements AfterViewInit {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {
    if(!val.value) {
      val.value = "<p>ENTER HTML HERE</p>"
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    if(!val.wrapper_style || Object.keys(val.wrapper_style).length == 0) {
      val.wrapper_style = {'width': "w-100"};
    }
    
    this.component = val;
  } 

  public component:any = {value: "<p>ENTER HTML HERE</p>"};

  constructor(public builder: LexiPagebuilderService, public sanitizer: DomSanitizer) {  }

  ngAfterViewInit() {
  //  this.builder.setStyle(this.wrapper, this.component);
 //   this.builder.appendEditComponent(this.vcr, this.wrapper, this.component.uid);
  }

}
