import { Component, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'lexi-api';
import { LexiPagebuilderService } from '../../lexi-pagebuilder.service';
import { PageComponent } from '../options/page/page.component';
import { ThemeComponent } from '../theme/theme.component';

@Component({
  selector: 'lib-view-toolbar',
  templateUrl: './view-toolbar.component.html',
  styleUrls: ['./view-toolbar.component.css']
})
export class ViewToolbarComponent {

  private page:any = {style: {}};
  private page_uuid:any;
  private pageList:any = [];
  public loading = false;
  public view_url:any;
  public preview_window:any;
  private closeTimer:any;
  private themeOffCanvas:any;

  public screen_size = 992;
  public languages:any = [];
  public myLangauge:any = 'en';

  @Output() screenUpdate = new EventEmitter<number>();
  @Output() themeUpdate = new EventEmitter<boolean>();
  @Output() langaugeUpdate = new EventEmitter<string>();
  
  constructor(private api: ApiService, private builder: LexiPagebuilderService, private route: ActivatedRoute, private offcanvasService: NgbOffcanvas, private modalService: NgbModal) {
    let page_uuid = this.route.snapshot.paramMap.get('page_uuid');

    if(!page_uuid) {
      return;
    }

    this.page_uuid = page_uuid;
    this.getLanguage();
    this.getPage(page_uuid)
  }

  async getLanguage() {
    this.languages = await this.api.post("/page/language", {});
  }

  async selectLangauge() {
    this.builder.setPageLangauge(this.myLangauge)
    this.langaugeUpdate.emit(this.myLangauge)

    this.getPage(this.page.page_uuid)
  }

  async getPage(page_uuid: string) {
    this.loading = true;
    this.page = await this.api.post("/page/get", {page_uuid, language: this.myLangauge});

    this.page.page_uuid = page_uuid;
    this.view_url = window.location.protocol + "//" + window.location.host + "/page/" + this.page.pkey
    this.loading = false;
  }


  resize(size: number) {
    this.screen_size = size;
    this.screenUpdate.emit(size)

    if(this.preview_window) {
      this.preview_window.resizeTo((this.screen_size == 992 ? screen.width : this.screen_size), screen.height)
    }
  }

  async save() {
    this.loading = true;
    let content = this.builder.getSection();

    await this.api.post("/page/save", {content: content, page_uuid: this.page_uuid, language: this.myLangauge, page: this.builder.getPage()});
    if(this.preview_window) {
      this.preview_window.location.reload();
    }
    this.loading = false;
  }

  preview() {

    if (this.preview_window) {
      this.preview_window.close();
      this.preview_window = null;
      return;
    }

    this.preview_window = window.open("about:blank", "Preview Window", "width=" + (this.screen_size == 992 ? screen.width : this.screen_size) + ",height=" + screen.height + ",resizable");
    this.preview_window.location.href = this.view_url;

    this.closeTimer = setInterval(() => {
      if(!this.preview_window || this.preview_window.closed) {
        clearInterval(this.closeTimer);
        this.closeTimer = null;
        this.preview_window = null;
      }
    }, 1000)
  }

  showTheme() {
    this.themeOffCanvas = this.offcanvasService.open(ThemeComponent, {panelClass: "bg-dark text-light"});

    this.themeOffCanvas.componentInstance.close.subscribe((v:any) => {

      this.themeOffCanvas.close()

      if(!v) {
        return;
      }

      if(this.preview_window) {
        this.preview_window.location.reload();
      }

      this.themeUpdate.emit(true)
    })
  }

  showPageSetting() {
    let page = this.modalService.open(PageComponent);
    page.componentInstance.setPage = this.page;
    page.componentInstance.listenSave.subscribe(() => {
      page.close();
    })
  }

  showEditing() {
    
  }
}
