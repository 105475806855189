import { Component } from '@angular/core';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  public data:any = {reminders: []}
  constructor(private api: ApiService) { 
    this.init()
  }

  async init() {
    let data = await this.api.post("/profile/dashboard", {})
    this.data = data;
    console.log(data)
  }
  

}
