<!-- <ng-container *ngIf="component.type == 'text'">

    <lib-text [setComponent]="component"></lib-text>

    <div [class]="buildClass('wrapper_style')">
        <div [class]="buildClass()" [ngStyle]="buildStyle()">
            {{component.value}}
        </div>
    </div>
    <lib-new-component [label]="'ENTER TEXT VALUE'" *ngIf="!component.value" ></lib-new-component>
</ng-container>

<ng-container *ngIf="component.type == 'image'">
    <div [class]="buildClass('wrapper_style')">
        <img *ngIf="component.value" [ngClass]="{'d-none': component.mobile_value,  'd-md-block': component.mobile_value}" [class]="buildClass()" [src]="component.value" [ngStyle]="buildStyle()"/>
        <img *ngIf="component.mobile_value" [ngClass]="{'d-block': component.value,  'd-md-none': component.value}" [class]="buildClass()" [src]="component.mobile_value"  [ngStyle]="buildStyle()"/>
    </div>
    <lib-new-component [label]="'SELECT IMAGE'" *ngIf="!component.value" ></lib-new-component>
</ng-container>

<ng-container *ngIf="component.type == 'button'">
    <div *ngIf="component.value" [class]="buildClass('wrapper_style')">
        <a [routerLink]="[component.link]" [class]="buildClass()" [ngStyle]="buildStyle()">{{component.value}}</a>
    </div>
    <lib-new-component [label]="'ENTER BUTTON VALUE'" *ngIf="!component.value" ></lib-new-component>
</ng-container>

<ng-container *ngIf="component.type == 'video'">
    <video *ngIf="component.value" [class]="buildClass()" [ngStyle]="buildStyle()">
        <source [src]="component.value" type="video/mp4">
    </video>
    <lib-new-component [label]="'SELECT VIDEO'" *ngIf="!component.value" ></lib-new-component>
</ng-container>

<ng-container *ngIf="component.type == 'html'">
    <div [class]="buildClass('wrapper_style')">
        <div [innerHTML]="component.value" [class]="buildClass()" [ngStyle]="buildStyle()"></div>
    </div>
    <lib-new-component [label]="'ENTER HTML'" *ngIf="!component.value" ></lib-new-component>
</ng-container>

<ng-container *ngIf="component.type == 'slider'">
    <div [class]="buildClass('wrapper_style')">
        <ngb-carousel [class]="buildClass()" *ngIf="component.items && component.items.length > 0">
            <ng-template *ngFor="let slider of component.items; let i = index" ngbSlide>
                <div class="picsum-img-wrapper">
                    <img [src]="slider.image" class="d-none d-md-block w-100" [alt]="slider.title" />
                    <img [src]="slider.image_mobile" class="d-block d-md-none w-100" [alt]="slider.title" />
                </div>
                <div class="carousel-caption">
                    <h3>{{slider.title}}</h3>
                    <p>{{slider.descp}}</p>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <lib-new-component [label]="'CREATE SLIDER'" *ngIf="!component.items || component.items.length == 0 "></lib-new-component>
</ng-container>
<!-- 
<ng-container *ngIf="component.type == 'list-point'">
    <lib-new-component [label]="'CREATE LIST POINT'" *ngIf="!component.value" ></lib-new-component>

    <div [class]="buildClass('wrapper_style')">
        <div class="row" [class]="buildClass()" >
            <div class="col-12 col-md-10 mx-auto" [ngStyle]="buildStyle()">
                <div class="row mb-5 " *ngFor="let point of component.items; let i = index" [ngClass]="{'flex-md-row flex-column flex-md-row-reverse': i % 2}">
                    <div class="col-12 col-md-5 d-flex justify-content-center">
                        <div class="d-flex justify-content-center align-items-center w-100">
                            <img [src]="point.image" class="w-100 mb-md-0 mb-3" *ngIf="!(point.video && point.image) && point.image" />
                            <video class="w-100 mb-md-0 mb-3" [poster]="point.image" *ngIf="point.image && point.video" controls>
                                <source [src]="point.video" type="video/mp4">
                            </video>
                        </div>
                    </div> 
                    <div class="col-12 col-md-7 d-flex justify-content-center flex-column">
                        <h4 [class]="point.title_color">{{point.title}}</h4>
                        <div [class]="point.description_color" [innerHTML]="point.descp"></div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</ng-container>
 --> -->
