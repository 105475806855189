import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'lexi-api';
import { LexiCalendarComponent } from 'lexi-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalenderEventComponent } from '../calender-event/calender-event.component';
import { AppointmentDetailComponent } from '../appointment-detail/appointment-detail.component';

@Component({
  selector: 'app-appointment-calendar',
  templateUrl: './appointment-calendar.component.html',
  styleUrls: ['./appointment-calendar.component.scss']
})
export class AppointmentCalendarComponent implements AfterViewInit {

  public branches: any = [];
  public selected: string | undefined;
  private date: any ;

  @ViewChild("calendar", {static: false}) calendar!: LexiCalendarComponent

  constructor(private api: ApiService, private ngbModal: NgbModal) { 
    this.init();
  }

  ngAfterViewInit() {
    this.calendar.eventMonthChange.subscribe(async (calender:any) => {
      this.date = {year: calender.year, month: calender.month + 1};
      await this.getCalender();
    })
  }

  async init() {
    let rtn = await this.api.post("/profile/appointment/init", {});
    this.branches = rtn.branch;
    this.selected = this.branches[0].uuid;
    await this.initCalender();
  }

  async initCalender() {
    let date:any = new Date();
    this.date = {year: date.getFullYear(), month: date.getMonth() + 1};
    await this.getCalender();
  }

  async getCalender() {
    let filter = this.date;
    filter.branch = this.selected;

    let rtn = await this.api.post("/profile/appointment", filter);
    let data = rtn.appointment;
    
    data.forEach((v:any) => {
      v.labels.forEach((element:any) => {
        element.onclick = () => {
          let model = this.ngbModal.open(AppointmentDetailComponent, {size: 'md'});
          model.componentInstance.setData = element;
          model.componentInstance.close.subscribe(() => {
            this.getCalender();
            model.close();
          })
        }
      });
    });

    this.calendar.setCalendarData(this.date, data)
    this.calendar.initCalendar();
    this.calendar.updateCalendarData();
  }

  async addEvent() {
    let modal = this.ngbModal.open(CalenderEventComponent, {size:"md"});

    modal.componentInstance.close.subscribe((val:boolean) => {
      if (val) {
        this.getCalender();
        modal.close();
      }
    })
  }
}
