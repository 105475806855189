<h3>Survey Data</h3>
<div class="d-flex flex-row ">
    <select class="form-control rounded-0" id="branch" name="branch" [(ngModel)]="data.branch_uuid" required>
        <option *ngFor="let branch of branches" [value]="branch.uuid">{{branch.title}}</option>
    </select>
    <input class="form-control rounded-0" type="date" placeholder="Date From" [(ngModel)]="data.date_from"/>
    <input class="form-control rounded-0" type="date" placeholder="Date To" [(ngModel)]="data.date_to"/>
    <button class="btn btn-primary rounded-0" (click)="filter()">Filter</button>
    <button class="btn btn-outline-primary rounded-0" (click)="export()">Export</button>
</div>
<hr/>
<table class="table table-sm table-striped small">
    <thead>
        <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Product Name</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Balance</th>
            <th>Remark</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let survey of surveys">
            <td>{{survey.create_on | date: 'd-M-Y'}}</td>
            <td>{{survey.code}}</td>
            <td>{{survey.name}}</td>
            <td class="border-start border-grey text-end">{{survey.debit}}</td>
            <td class="border-start border-grey text-end">{{survey.credit}}</td>
            <td class="border-start border-grey bg-grey text-end">{{survey.balance}}</td>
            <td class="border-start border-grey">{{survey.remark}}</td>
        </tr>
    </tbody>
</table>