import { Component } from '@angular/core';
import { MenuHelperService } from 'menu-helper';
import { LexiDashforgeTopService } from '../../lexi-dashforge-top.service';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  public config : any;
  public year: number;
  public year_from: number = 2019;
  public menu: any = {};
  constructor(
    private MenuHelper: MenuHelperService,
    private service: LexiDashforgeTopService 
  ) { 
    const c = new Date();
    this.year = c.getFullYear();
    this.config = this.service.get();
    this.menu = this.MenuHelper.get();
  }

}
