import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public data: any = {}
  public branches: any = [];

  @ViewChild("template1", {read:ViewContainerRef}) template1!: ViewContainerRef;
  @ViewChild("template2", {read:ViewContainerRef}) template2!: ViewContainerRef;
  @ViewChild("template3", {read:ViewContainerRef}) template3!: ViewContainerRef;
  @ViewChild("template4", {read:ViewContainerRef}) template4!: ViewContainerRef;
  @ViewChild("template5", {read:ViewContainerRef}) template5!: ViewContainerRef;
  @ViewChild("template6", {read:ViewContainerRef}) template6!: ViewContainerRef;
  @ViewChild("template7", {read:ViewContainerRef}) template7!: ViewContainerRef;
  @ViewChild("template8", {read:ViewContainerRef}) template8!: ViewContainerRef;
  @ViewChild("template9", {read:ViewContainerRef}) template9!: ViewContainerRef;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe((v:any) => {
      this.data.identifier = v.identifier
    })
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    let init = await this.api.post("/profile/inventory/init", {});
    this.branches = init.branches;
    this.data.branch_uuid = this.branches[0].uuid;
  }

  async filter() {
    console.log('hit')

    let rtn = await this.api.post("/profile/report", this.data)
    console.log(rtn)
    this.template1.clear()
    this.template2.clear()
    this.template3.clear()
    this.template4.clear()
    this.template5.clear()
    this.template6.clear()
    this.template7.clear()
    this.template8.clear()
    this.template9.clear()

    // for(var x = 0; x < rtn.reports.length; x++) {
    //   let report = rtn.reports[x];

    // }

    if (rtn) {
      let section = ((await import("lexi-chart-v2")).BarChartComponent);
      let ins = this.template1.createComponent(section);
      let insSales = rtn.salesByBranches.salesValue
      ins.instance.lineChartType = 'bar'
      ins.instance.lineChartData = rtn.salesByBranches.values
      ins.instance.lineChartLabels = rtn.salesByBranches.labels
      ins.instance.lineChartLegend = false
      ins.instance.lineChartOptions = {
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push('RM ' + insSales[context.dataIndex]);         
                return multistringText;
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Percentage'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Branches'
            }
          }
        }
      }

      let salesOnProdCat = this.template2.createComponent(section);
      let prodCatSalesValue = rtn.salesOnProdCat.salesValue
      salesOnProdCat.instance.lineChartData = rtn.salesOnProdCat.values
      salesOnProdCat.instance.lineChartLabels = rtn.salesOnProdCat.labels
      salesOnProdCat.instance.lineChartLegend = false
      salesOnProdCat.instance.lineChartOptions = {
        responsive: true,
        indexAxis:'y',
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push('RM ' + prodCatSalesValue[context.dataIndex]);         
                return multistringText;
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Percentage'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Product Category'
            }
          }
        }
      }

      salesOnProdCat.instance.lineChartType = 'bar'

      if (rtn.prodByCat.values[0].data.length > 0) {
        let prodByCat = this.template3.createComponent(section);
        let prodUnit = rtn.prodByCat.amount
        let prodName = rtn.prodByCat.values
        prodByCat.instance.lineChartData = rtn.prodByCat.values
        prodByCat.instance.lineChartLabels = rtn.prodByCat.labels.title
        prodByCat.instance.lineChartLegend = false
        prodByCat.instance.lineChartOptions = {
          responsive: true,
          plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push("RM " + prodUnit[context.datasetIndex].amount[context.dataIndex]);         
                multistringText.push("Product : " + prodName[context.datasetIndex].label);         
                return multistringText;
              }
            }
          }
        },
          scales: {
            y: {
              title: {
                display: true,
                text: 'Percentage'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Product Category'
              }
            }
          }
        }
  
        prodByCat.instance.lineChartType = 'bar'
      }

      let carTypeByCat = this.template4.createComponent(section);
      let numCar = rtn.carTypeByCat.carNum
      carTypeByCat.instance.lineChartData = rtn.carTypeByCat.values
      carTypeByCat.instance.lineChartLabels = rtn.carTypeByCat.labels.title
      carTypeByCat.instance.lineChartLegend = true
      carTypeByCat.instance.lineChartOptions = {
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push('Number of Car : '+ numCar[context.datasetIndex].carNum[context.dataIndex]);         
                return multistringText;
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Percentage'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Product Category'
            }
          }
        }
      }

      carTypeByCat.instance.lineChartType = 'bar'

      let custSource = this.template5.createComponent(section);
      custSource.instance.lineChartData = rtn.custSource.values
      custSource.instance.lineChartLabels = rtn.custSource.labels
      custSource.instance.lineChartLegend = false
      custSource.instance.lineChartOptions = {
        responsive:true,
        scales:{
          y:{
            title:{
              display:true,
              text:"Number of Customer"
            }
          },
          x:{
            title:{
              display:true,
              text:"Customer Source"
            }
          }
        }
      }
      custSource.instance.lineChartType = 'bar'

      let custmerType = this.template6.createComponent(section);
      let custNum = rtn.custmerType.custNum
      custmerType.instance.lineChartData = rtn.custmerType.values
      custmerType.instance.lineChartLabels = rtn.custmerType.labels
      custmerType.instance.lineChartLegend = false
      custmerType.instance.lineChartOptions = {
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push('Number of Customer : ' + custNum[context.dataIndex]);         
                return multistringText;
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Percentage'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Customer Type'
            }
          }
        }
      }

      custmerType.instance.lineChartType = 'bar'

      let headerOfHamel = this.template7.createComponent(section);
      headerOfHamel.instance.lineChartData = rtn.headerOfHamel.values
      headerOfHamel.instance.lineChartLabels = rtn.headerOfHamel.labels
      headerOfHamel.instance.lineChartLegend = false
      headerOfHamel.instance.lineChartOptions = {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: 'Number of Response'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Where did customer hear of HAMEL from'
            }
          }
        }
      }

      headerOfHamel.instance.lineChartType = 'bar'

      
      let reasonChooseHamel = this.template8.createComponent(section);
      reasonChooseHamel.instance.lineChartData = rtn.reasonChooseHamel.values
      reasonChooseHamel.instance.lineChartLabels = rtn.reasonChooseHamel.labels
      reasonChooseHamel.instance.lineChartLegend = false
      reasonChooseHamel.instance.lineChartOptions = {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: 'Number of Response'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Reason'
            }
          }
        }
      }

      reasonChooseHamel.instance.lineChartType = 'bar'

      let recommendPercent = this.template9.createComponent(section);
      let responseNum = rtn.recommendPercent.responseNum
      recommendPercent.instance.lineChartData = rtn.recommendPercent.values
      recommendPercent.instance.lineChartLabels = rtn.recommendPercent.labels
      recommendPercent.instance.lineChartLegend = false
      recommendPercent.instance.lineChartOptions = {
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: any) {
                var multistringText = [context.dataset.data[context.dataIndex] + '\%'];
                multistringText.push('Number of response : ' + responseNum[context.dataIndex]);         
                return multistringText;
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Percentage'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Selection'
            }
          }
        }
      }

      recommendPercent.instance.lineChartType = 'bar'
    }
  }
}
