import { Component, OnInit, Input, ViewChild, ViewContainerRef, AfterViewInit, ElementRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements AfterViewInit {

  private position:any = {};
  private uid:any;

  @Input() set setUID(val:any) {
    if(val) {this.uid = val;
      this.position = this.builder.getPosition(val)
    };
  }

  @ViewChild("wrapper", {read: ViewContainerRef}) wrapper!: ViewContainerRef;

  constructor(private builder: LexiPagebuilderService) { }

  ngAfterViewInit() {
    this.buildSection();
  }

  async buildSection() {

    let sections = this.builder.getSection();

    let current_section = sections[this.position.section_index];

    if(current_section && current_section.components) {
      for(var x = 0; x < current_section.components.length; x++) {
        let current_component =  current_section.components[x]
        let component = await this.builder.generateComponentType(current_component.type, this.wrapper, current_component);
        if(!component) {
          continue;
        }
      }  
    }

    if(this.builder.editable) {
      this.builder.appendAddComponent(this.wrapper, this.uid);
     // this.builder.appendEditComponent(this.wrapper, this.wrapper.element.nativeElement, this.uid, this)
     // this.builder.appendEditComponentAppendToVC(this.wrapper, this.uid, this);
    }

  }

}
