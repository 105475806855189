import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreComponent } from './pre.component';

const routes: Routes = [
    { path: '', component: PreComponent, children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', loadChildren: () => import("../../auth/login/login.module").then(m => m.LoginModule) },
      { path: 'register', loadChildren: () => import("../../auth/register/register.module").then(m => m.RegisterModule) },
      { path: 'forgot-password', loadChildren: () => import("../../auth/forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule) },
      { path: '**', pathMatch: 'full', loadChildren: () => import("../page-not-found/page-not-found.module").then(m => m.PageNotFoundModule) }
    ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreRoutingModule { }
