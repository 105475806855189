import { Component } from '@angular/core';
import { LexiPagebuilderService } from 'lexi-pagebuilder';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hamel Window Film Platform';
  constructor(private pb: LexiPagebuilderService) {
    this.pb.setDoc(document)
  }
}
