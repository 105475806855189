<div class="setting">
    <div class="lexi-accordion">
        <ng-container *ngIf="builder.validateColumeType(component.type)">
            <div class="header" (click)="toggleOption($event)">
                <h6 class="d-flex flex-row justify-content-between">
                    <span>Layout</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
                </h6>
            </div>
            <div class="options">
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">WIDTH</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-fluid', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'container-fluid'}">CF</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'container'}">C</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-sm', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'container-sm'}">C-SM</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-100', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'w-100'}">100%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-75', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'w-75'}">75%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-50', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'w-50'}">50%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-25', 'wrapper_style')" [ngClass]="{'active': component.wrapper_style.width == 'w-25'}">25%</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Column's Gap</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', '')" [ngClass]="{'active': this.component.style.gap == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-0')" [ngClass]="{'active': this.component.style.gap == 'g-0'}">0</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-1')" [ngClass]="{'active': this.component.style.gap == 'g-1'}">1</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-2')" [ngClass]="{'active': this.component.style.gap == 'g-2'}">2</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-3')" [ngClass]="{'active': this.component.style.gap == 'g-3'}">3</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-4')" [ngClass]="{'active': this.component.style.gap == 'g-4'}">4</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Color</span>
                    <select (change)="setStyleOnChange($event, 'background_color')">
                        <option [value]="">None</option>
                        <option [value]="'bg-primary'">Primary Color</option>
                        <option [value]="'bg-secondary'">Secondary Color</option>
                        <option [value]="'bg-tertiary'">Tertiary Color</option>
                        <option [value]="'bg-quaternary'">Quaternary Color</option>
                        <option [value]="'bg-quinary'">Quinary Color</option>
                        <option [value]="'bg-dark'">Dark Color</option>
                        <option [value]="'bg-light'">Light Color</option>
                        <option [value]="'bg-danger'">Error Color</option>
                        <option [value]="'bg-success'">Success Color</option>
                    </select>  
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image/Video</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="directStyle()">SELECT</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Size</span>
                    <select (change)="setStyleOnChange($event, 'background_size')">
                        <option [value]="">Auto</option>
                        <option [value]="'bg-size-cover'">Cover</option>
                        <option [value]="'bg-size-contain'">Contain</option>
                        <option [value]="'bg-size-double'">Cover Contain</option>
                    </select>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Repeat</span>
                    <select (change)="setStyleOnChange($event, 'background_repeat')">
                        <option [value]="">Auto</option>
                        <option [value]="'bg-no-repeat'">No Repeat</option>
                        <option [value]="'bg-repeat'">Repeat</option>
                        <option [value]="'bg-repeat-x'">Repeat X</option>
                        <option [value]="'bg-repeat-y'">Repeat Y</option>
                    </select>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="component.type == 'section' || component.type == 'COLUMN'">
            <div class="header" (click)="toggleOption($event)">
                <h6 class="d-flex flex-row justify-content-between">
                    <span>Layout</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
                </h6>
            </div>
            <div class="options">
                <div class="btn-toolbar" role="toolbar" *ngIf="component.type == 'section'">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Weight</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '')" [ngClass]="{'active': this.component.style.width == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-fluid')" [ngClass]="{'active': component.style.width == 'container-fluid'}">CF</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container')" [ngClass]="{'active': component.style.width == 'container'}">C</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-sm')" [ngClass]="{'active': component.style.width == 'container-sm'}">C-SM</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Height</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', '')" [ngClass]="{'active': this.component.style.height == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-100')" [ngClass]="{'active': this.component.style.height == 'vh-100'}">100%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-75')" [ngClass]="{'active': this.component.style.height == 'vh-75'}">75%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-50')" [ngClass]="{'active': this.component.style.height == 'vh-50'}">50%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-25')" [ngClass]="{'active': this.component.style.height == 'vh-25'}">25%</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Horizontal Align</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-start', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-start'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M3,2 C3.55228475,2 4,2.44771525 4,3 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L8,16 C6.8954305,16 6,15.1045695 6,14 L6,10 C6,8.8954305 6.8954305,8 8,8 L20,8 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-center', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-center'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z M16,20 C16,21.1045695 15.1045695,22 14,22 L10,22 C8.8954305,22 8,21.1045695 8,20 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,20 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-end', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-end'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M21,2 C20.4477153,2 20,2.44771525 20,3 L20,21 C20,21.5522847 20.4477153,22 21,22 C21.5522847,22 22,21.5522847 22,21 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M4,8 C2.8954305,8 2,8.8954305 2,10 L2,14 C2,15.1045695 2.8954305,16 4,16 L16,16 C17.1045695,16 18,15.1045695 18,14 L18,10 C18,8.8954305 17.1045695,8 16,8 L4,8 Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">SELF Vertical Align</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-start', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-start'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M2,3 C2,3.55228475 2.44771525,4 3,4 L21,4 C21.5522847,4 22,3.55228475 22,3 C22,2.44771525 21.5522847,2 21,2 L3,2 C2.44771525,2 2,2.44771525 2,3 Z M8,20 C8,21.1045695 8.8954305,22 10,22 L14,22 C15.1045695,22 16,21.1045695 16,20 L16,8 C16,6.8954305 15.1045695,6 14,6 L10,6 C8.8954305,6 8,6.8954305 8,8 L8,20 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-center', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-center'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L4,16 C2.8954305,16 2,15.1045695 2,14 L2,10 C2,8.8954305 2.8954305,8 4,8 L20,8 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-end', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-end'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M22,21 C22,20.4477153 21.5522847,20 21,20 L3,20 C2.44771525,20 2,20.4477153 2,21 C2,21.5522847 2.44771525,22 3,22 L21,22 C21.5522847,22 22,21.5522847 22,21 Z M16,4 C16,2.8954305 15.1045695,2 14,2 L10,2 C8.8954305,2 8,2.8954305 8,4 L8,16 C8,17.1045695 8.8954305,18 10,18 L14,18 C15.1045695,18 16,17.1045695 16,16 L16,4 Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Color</span>
                    <select (change)="setStyleOnChange($event, 'background_color')">
                        <option [value]="">None</option>
                        <option [value]="'bg-primary'">Primary Color</option>
                        <option [value]="'bg-secondary'">Secondary Color</option>
                        <option [value]="'bg-tertiary'">Tertiary Color</option>
                        <option [value]="'bg-quaternary'">Quaternary Color</option>
                        <option [value]="'bg-quinary'">Quinary Color</option>
                        <option [value]="'bg-dark'">Dark Color</option>
                        <option [value]="'bg-light'">Light Color</option>
                        <option [value]="'bg-danger'">Error Color</option>
                        <option [value]="'bg-success'">Success Color</option>
                    </select>  
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image/Video</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="directStyle()">SELECT</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Size</span>
                    <select (change)="setStyleOnChange($event, 'background_size')">
                        <option [value]="">Auto</option>
                        <option [value]="'bg-size-cover'">Cover</option>
                        <option [value]="'bg-size-contain'">Contain</option>
                        <option [value]="'bg-size-double'">Cover Contain</option>
                    </select>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Repeat</span>
                    <select (change)="setStyleOnChange($event, 'background_repeat')">
                        <option [value]="">Auto</option>
                        <option [value]="'bg-no-repeat'">No Repeat</option>
                        <option [value]="'bg-repeat'">Repeat</option>
                        <option [value]="'bg-repeat-x'">Repeat X</option>
                        <option [value]="'bg-repeat-y'">Repeat Y</option>
                    </select>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!builder.validateColumeType(component.type) && component.type != 'section' && component.type != 'COLUMN'">
            <div class="header" (click)="toggleOption($event)">
                <h6 class="d-flex flex-row justify-content-between">
                    <span>Layout</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
                </h6>
            </div>
            <div class="options">
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">WIDTH</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '')" [ngClass]="{'active': !component.style.width || component.style.width == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-100')" [ngClass]="{'active': this.component.style.width == 'w-100'}">100%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-75')" [ngClass]="{'active': this.component.style.width == 'w-75'}">75%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-50')" [ngClass]="{'active': this.component.style.width == 'w-50'}">50%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-25')" [ngClass]="{'active': this.component.style.width == 'w-25'}">25%</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Inner WIDTH</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-fluid', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container-fluid'}">CF</button>
                        <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container'}">C</button>
                        <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-sm', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container-sm'}">C-SM</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-100', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-100'}">100%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-75', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-75'}">75%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-50', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-50'}">50%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-25', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-25'}">25%</button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Height</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', '', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == ''}">Auto</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-100', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-100'}">100%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-75', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-75'}">75%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-50', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-50'}">50%</button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-25', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-25'}">25%</button>
                    </div>
                </div>
                
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Color</span>
                    <select (change)="setStyleOnChange($event, 'background_color', 'wrapper_style')">
                        <option [value]="">None</option>
                        <option [value]="'bg-primary'">Primary Color</option>
                        <option [value]="'bg-secondary'">Secondary Color</option>
                        <option [value]="'bg-tertiary'">Tertiary Color</option>
                        <option [value]="'bg-quaternary'">Quaternary Color</option>
                        <option [value]="'bg-quinary'">Quinary Color</option>
                        <option [value]="'bg-dark'">Dark Color</option>
                        <option [value]="'bg-light'">Light Color</option>
                        <option [value]="'bg-dange'">Error Color</option>
                        <option [value]="'bg-success'">Success Color</option>
                    </select>
                </div>

                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Size</span>
                    <select (change)="setStyleOnChange($event, 'background_size')">
                        <option [value]="'bg-size-auto'">Auto</option>
                        <option [value]="'bg-size-cover'">Cover</option>
                        <option [value]="'bg-size-contain'">Contain</option>
                        <option [value]="'bg-size-double'">Cover Contain</option>
                    </select>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image Repeat</span>
                    <select (change)="setStyleOnChange($event, 'background_repeat')">
                        <option [value]="">Auto</option>
                        <option [value]="'bg-no-repeat'">No Repeat</option>
                        <option [value]="'bg-repeat'">Repeat</option>
                        <option [value]="'bg-repeat-x'">Repeat X</option>
                        <option [value]="'bg-repeat-y'">Repeat Y</option>
                    </select>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Horizontal Align</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-start', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-start'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M3,2 C3.55228475,2 4,2.44771525 4,3 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L8,16 C6.8954305,16 6,15.1045695 6,14 L6,10 C6,8.8954305 6.8954305,8 8,8 L20,8 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-center', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-center'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z M16,20 C16,21.1045695 15.1045695,22 14,22 L10,22 C8.8954305,22 8,21.1045695 8,20 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,20 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-end', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-end'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M21,2 C20.4477153,2 20,2.44771525 20,3 L20,21 C20,21.5522847 20.4477153,22 21,22 C21.5522847,22 22,21.5522847 22,21 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M4,8 C2.8954305,8 2,8.8954305 2,10 L2,14 C2,15.1045695 2.8954305,16 4,16 L16,16 C17.1045695,16 18,15.1045695 18,14 L18,10 C18,8.8954305 17.1045695,8 16,8 L4,8 Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="btn-toolbar" role="toolbar">
                    <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Vertical Align</span>
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-start', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-start'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M2,3 C2,3.55228475 2.44771525,4 3,4 L21,4 C21.5522847,4 22,3.55228475 22,3 C22,2.44771525 21.5522847,2 21,2 L3,2 C2.44771525,2 2,2.44771525 2,3 Z M8,20 C8,21.1045695 8.8954305,22 10,22 L14,22 C15.1045695,22 16,21.1045695 16,20 L16,8 C16,6.8954305 15.1045695,6 14,6 L10,6 C8.8954305,6 8,6.8954305 8,8 L8,20 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-center', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-center'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L4,16 C2.8954305,16 2,15.1045695 2,14 L2,10 C2,8.8954305 2.8954305,8 4,8 L20,8 Z"/>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-end', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-end'}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M22,21 C22,20.4477153 21.5522847,20 21,20 L3,20 C2.44771525,20 2,20.4477153 2,21 C2,21.5522847 2.44771525,22 3,22 L21,22 C21.5522847,22 22,21.5522847 22,21 Z M16,4 C16,2.8954305 15.1045695,2 14,2 L10,2 C8.8954305,2 8,2.8954305 8,4 L8,16 C8,17.1045695 8.8954305,18 10,18 L14,18 C15.1045695,18 16,17.1045695 16,16 L16,4 Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
<!--         
        <div class="options">
            <ng-container *ngIf="component.type == 'section'">
                SECTION
            </ng-container>
            
            <ng-container *ngIf="component.type == 'COLUMN'">
                COLUMN
            </ng-container>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">                {{component.type}}
                    WIDTH</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-fluid')" [ngClass]="{'active': this.component.style.width == 'container-fluid'}">CF</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container')" [ngClass]="{'active': this.component.style.width == 'container'}">C</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-sm')" [ngClass]="{'active': this.component.style.width == 'container-sm'}">C-SM</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '')" [ngClass]="{'active': this.component.style.width == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-100')" [ngClass]="{'active': this.component.style.width == 'w-100'}">100%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-75')" [ngClass]="{'active': this.component.style.width == 'w-75'}">75%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-50')" [ngClass]="{'active': this.component.style.width == 'w-50'}">50%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-25')" [ngClass]="{'active': this.component.style.width == 'w-25'}">25%</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Height</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', '')" [ngClass]="{'active': this.component.style.height == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-100')" [ngClass]="{'active': this.component.style.height == 'vh-100'}">100%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-75')" [ngClass]="{'active': this.component.style.height == 'vh-75'}">75%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-50')" [ngClass]="{'active': this.component.style.height == 'vh-50'}">50%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-25')" [ngClass]="{'active': this.component.style.height == 'vh-25'}">25%</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Column's Gap</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', '')" [ngClass]="{'active': this.component.style.gap == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-0')" [ngClass]="{'active': this.component.style.gap == 'g-0'}">0</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-1')" [ngClass]="{'active': this.component.style.gap == 'g-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-2')" [ngClass]="{'active': this.component.style.gap == 'g-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-3')" [ngClass]="{'active': this.component.style.gap == 'g-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-4')" [ngClass]="{'active': this.component.style.gap == 'g-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('gap', 'g-5')" [ngClass]="{'active': this.component.style.gap == 'g-5'}">5</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Horizontal Align</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-start', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M3,2 C3.55228475,2 4,2.44771525 4,3 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L8,16 C6.8954305,16 6,15.1045695 6,14 L6,10 C6,8.8954305 6.8954305,8 8,8 L20,8 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-center', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z M16,20 C16,21.1045695 15.1045695,22 14,22 L10,22 C8.8954305,22 8,21.1045695 8,20 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,20 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-end', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.horizontal_align == 'justify-content-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,2 C20.4477153,2 20,2.44771525 20,3 L20,21 C20,21.5522847 20.4477153,22 21,22 C21.5522847,22 22,21.5522847 22,21 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M4,8 C2.8954305,8 2,8.8954305 2,10 L2,14 C2,15.1045695 2.8954305,16 4,16 L16,16 C17.1045695,16 18,15.1045695 18,14 L18,10 C18,8.8954305 17.1045695,8 16,8 L4,8 Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Vertical Align</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-start', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M2,3 C2,3.55228475 2.44771525,4 3,4 L21,4 C21.5522847,4 22,3.55228475 22,3 C22,2.44771525 21.5522847,2 21,2 L3,2 C2.44771525,2 2,2.44771525 2,3 Z M8,20 C8,21.1045695 8.8954305,22 10,22 L14,22 C15.1045695,22 16,21.1045695 16,20 L16,8 C16,6.8954305 15.1045695,6 14,6 L10,6 C8.8954305,6 8,6.8954305 8,8 L8,20 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-center', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L4,16 C2.8954305,16 2,15.1045695 2,14 L2,10 C2,8.8954305 2.8954305,8 4,8 L20,8 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-end', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.vertical_align == 'align-items-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M22,21 C22,20.4477153 21.5522847,20 21,20 L3,20 C2.44771525,20 2,20.4477153 2,21 C2,21.5522847 2.44771525,22 3,22 L21,22 C21.5522847,22 22,21.5522847 22,21 Z M16,4 C16,2.8954305 15.1045695,2 14,2 L10,2 C8.8954305,2 8,2.8954305 8,4 L8,16 C8,17.1045695 8.8954305,18 10,18 L14,18 C15.1045695,18 16,17.1045695 16,16 L16,4 Z"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">SELF Horizontal Align</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-start', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M3,2 C3.55228475,2 4,2.44771525 4,3 L4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L8,16 C6.8954305,16 6,15.1045695 6,14 L6,10 C6,8.8954305 6.8954305,8 8,8 L20,8 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-center', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 Z M6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 Z M16,20 C16,21.1045695 15.1045695,22 14,22 L10,22 C8.8954305,22 8,21.1045695 8,20 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,20 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('horizontal_align', 'justify-content-end', 'style')" [ngClass]="{'active': this.component.style && this.component.style.horizontal_align == 'justify-content-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,2 C20.4477153,2 20,2.44771525 20,3 L20,21 C20,21.5522847 20.4477153,22 21,22 C21.5522847,22 22,21.5522847 22,21 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M4,8 C2.8954305,8 2,8.8954305 2,10 L2,14 C2,15.1045695 2.8954305,16 4,16 L16,16 C17.1045695,16 18,15.1045695 18,14 L18,10 C18,8.8954305 17.1045695,8 16,8 L4,8 Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">SELF Vertical Align</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-start', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M2,3 C2,3.55228475 2.44771525,4 3,4 L21,4 C21.5522847,4 22,3.55228475 22,3 C22,2.44771525 21.5522847,2 21,2 L3,2 C2.44771525,2 2,2.44771525 2,3 Z M8,20 C8,21.1045695 8.8954305,22 10,22 L14,22 C15.1045695,22 16,21.1045695 16,20 L16,8 C16,6.8954305 15.1045695,6 14,6 L10,6 C8.8954305,6 8,6.8954305 8,8 L8,20 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-center', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z M20,8 C21.1045695,8 22,8.8954305 22,10 L22,14 C22,15.1045695 21.1045695,16 20,16 L4,16 C2.8954305,16 2,15.1045695 2,14 L2,10 C2,8.8954305 2.8954305,8 4,8 L20,8 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('vertical_align', 'align-items-end', 'style')" [ngClass]="{'active': this.component.style && this.component.style.vertical_align == 'align-items-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M22,21 C22,20.4477153 21.5522847,20 21,20 L3,20 C2.44771525,20 2,20.4477153 2,21 C2,21.5522847 2.44771525,22 3,22 L21,22 C21.5522847,22 22,21.5522847 22,21 Z M16,4 C16,2.8954305 15.1045695,2 14,2 L10,2 C8.8954305,2 8,2.8954305 8,4 L8,16 C8,17.1045695 8.8954305,18 10,18 L14,18 C15.1045695,18 16,17.1045695 16,16 L16,4 Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div> -->
    </div>
    <!-- <div class="lexi-accordion" *ngIf="type != 'SECTION'">
        <div class="header" (click)="toggleOption($event)">
            <h6 class="d-flex flex-row justify-content-between">
                <span>Parent Layout</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
            </h6>
        </div>
        <div class="options">
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">WIDTH</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-fluid', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container-fluid'}">CF</button>
                    <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container'}">C</button>
                    <button type="button" *ngIf="!component.type" class="btn btn-xs rounded-0" (click)="setStyle('width', 'container-sm', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'container-sm'}">C-SM</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', '', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-100', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-100'}">100%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-75', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-75'}">75%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-50', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-50'}">50%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('width', 'w-25', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.width == 'w-25'}">25%</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Height</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', '', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-100', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-100'}">100%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-75', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-75'}">75%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-50', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-50'}">50%</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('height', 'vh-25', 'wrapper_style')" [ngClass]="{'active': this.component.wrapper_style && this.component.wrapper_style.height == 'vh-25'}">25%</button>
                </div>
            </div>
            
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Color</span>
                <select (change)="setStyleOnChange($event, 'background_color', 'wrapper_style')">
                    <option [value]="">None</option>
                    <option [value]="'bg-primary'">Primary Color</option>
                    <option [value]="'bg-secondary'">Secondary Color</option>
                    <option [value]="'bg-tertiary'">Tertiary Color</option>
                    <option [value]="'bg-quaternary'">Quaternary Color</option>
                    <option [value]="'bg-quinary'">Quinary Color</option>
                    <option [value]="'bg-dark'">Dark Color</option>
                    <option [value]="'bg-light'">Light Color</option>
                    <option [value]="'bg-error'">Error Color</option>
                    <option [value]="'bg-success'">Success Color</option>
                </select>
            </div>
        </div>
    </div> -->
    <div class="lexi-accordion">
        <div class="header" (click)="toggleOption($event)">
            <h6 class="d-flex flex-row justify-content-between">
                <span>Visibility</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
            </h6>
        </div>
        <div class="options">
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Hide On Desktop</span>
                <div class="toggler btn btn-secondary btn-xs position-relative p-0 rounded-0" [ngClass]="{'active': component.style.hide_on_dektop}" (click)="toggleStyle('hide_on_dektop', 'd-lg-none d-xl-block')">
                    <div class="h-100 d-flex align-items-center mx-1 position-relative">
                        <span class="w-50 on">ON</span>
                        <span class="w-50 button d-flex align-items-center position-absolute border border-dark" >
                            <p class="text-center text-dark m-0 w-100">||</p>
                        </span>
                        <span class="w-50 off">OFF</span>
                    </div>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Hide On Tablet</span>
                <div class="toggler btn btn-secondary btn-xs position-relative p-0 rounded-0" [ngClass]="{'active': component.style.hide_on_tablet}" (click)="toggleStyle('hide_on_tablet', 'd-md-none')">
                    <div class="h-100 d-flex align-items-center mx-1 position-relative">
                        <span class="w-50 on">ON</span>
                        <span class="w-50 button d-flex align-items-center position-absolute border border-dark" >
                            <p class="text-center text-dark m-0 w-100">||</p>
                        </span>
                        <span class="w-50 off">OFF</span>
                    </div>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Hide On Mobile</span>
                <div class="toggler btn btn-secondary btn-xs position-relative p-0 rounded-0" [ngClass]="{'active': component.style.hide_on_mobile}" (click)="toggleStyle('hide_on_mobile', 'd-none d-md-block')">
                    <div class="h-100 d-flex align-items-center mx-1 position-relative">
                        <span class="w-50 on">ON</span>
                        <span class="w-50 button d-flex align-items-center position-absolute border border-dark" >
                            <p class="text-center text-dark m-0 w-100">||</p>
                        </span>
                        <span class="w-50 off">OFF</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="lexi-accordion">
        <div class="header" (click)="toggleOption($event)">
            <h6 class="d-flex flex-row justify-content-between">
                <span>Background</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
            </h6>
        </div>
        <div class="options">
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Color</span>
                <select (change)="setStyleOnChange($event, 'background_color')">
                    <option [value]="">None</option>
                    <option [value]="'bg-primary'">Primary Color</option>
                    <option [value]="'bg-secondary'">Secondary Color</option>
                    <option [value]="'bg-tertiary'">Tertiary Color</option>
                    <option [value]="'bg-quaternary'">Quaternary Color</option>
                    <option [value]="'bg-quinary'">Quinary Color</option>
                    <option [value]="'bg-dark'">Dark Color</option>
                    <option [value]="'bg-light'">Light Color</option>
                    <option [value]="'bg-error'">Error Color</option>
                    <option [value]="'bg-success'">Success Color</option>
                </select>  
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Background Image/Video</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="directStyle()">SELECT</button>
                </div>
            </div>
        </div>
    </div> -->
    <div class="lexi-accordion">
        <div class="header" (click)="toggleOption($event)">
            <h6 class="d-flex flex-row justify-content-between">
                <span>Typography</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
            </h6>
        </div>
        <div class="options">
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">TEXT ALIGN</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_align', 'text-start')" [ngClass]="{'active': this.component.style.text_align == 'text-start'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M14 15H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zm0-8H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM4 13h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0 8h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 4c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_align', 'text-center')" [ngClass]="{'active': this.component.style.text_align == 'text-center'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M18,15 C18.5522847,15 19,15.4477153 19,16 C19,16.5522847 18.5522847,17 18,17 L6,17 C5.44771525,17 5,16.5522847 5,16 C5,15.4477153 5.44771525,15 6,15 L18,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M18,7 C18.5522847,7 19,7.44771525 19,8 C19,8.55228475 18.5522847,9 18,9 L6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 L18,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_align', 'text-end')" [ngClass]="{'active': this.component.style.text_align == 'text-end'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 L9,9 C8.44771525,9 8,8.55228475 8,8 C8,7.44771525 8.44771525,7 9,7 L21,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_align', 'text-justify')" [ngClass]="{'active': this.component.style.text_align == 'text-justify'}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 C2,19.4477153 2.44771525,19 3,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 C2,15.4477153 2.44771525,15 3,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,7 C21.5522847,7 22,7.44771525 22,8 C22,8.55228475 21.5522847,9 21,9 L3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 L21,7 Z M21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 L3,5 C2.44771525,5 2,4.55228475 2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Font Family</span>
                <select (change)="setStyleOnChange($event, 'font_family')">
                    <option [value]="">None</option>
                    <option [value]="'font-primary'">Primary Font</option>
                    <option [value]="'font-secondary'">Secondary Font</option>
                    <option [value]="'font-tertiary'">Tertiary Font</option>
                    <option [value]="'font-quaternary'">Quaternary Font</option>
                </select> 
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Font Color</span>
                <select (change)="setStyleOnChange($event, 'text_color')">
                    <option [value]="">None</option>
                    <option [value]="'text-primary'">Primary Color</option>
                    <option [value]="'text-secondary'">Secondary Color</option>
                    <option [value]="'text-tertiary'">Tertiary Color</option>
                    <option [value]="'text-quaternary'">Quaternary Color</option>
                    <option [value]="'text-quinary'">Quinary Color</option>
                    <option [value]="'text-dark'">Dark Color</option>
                    <option [value]="'text-light'">Light Color</option>
                    <option [value]="'text-danger'">Error Color</option>
                    <option [value]="'text-success'">Success Color</option>
                </select>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">FONT WEIGHT</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_weight', 'fw-lighter')" [ngClass]="{'active': this.component.style.font_weight == 'fw-lighter'}">Lighter</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_weight', 'fw-light')" [ngClass]="{'active': this.component.style.font_weight == 'fw-light'}">Light</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_weight', 'fw-normal')" [ngClass]="{'active': this.component.style.font_weight == 'fw-normal'}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_weight', 'fw-bold')" [ngClass]="{'active': this.component.style.font_weight == 'fw-bold'}">Bold</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_weight', 'fw-bolder')" [ngClass]="{'active': this.component.style.font_weight == 'fw-bolder'}">Bolder</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">LETTER SPACING</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', '')" [ngClass]="{'active': this.component.style.font_weight == ''}">Normal</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', 'ls-1')" [ngClass]="{'active': this.component.style.font_weight == 'ls-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', 'ls-2')" [ngClass]="{'active': this.component.style.font_weight == 'ls-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', 'ls-3')" [ngClass]="{'active': this.component.style.font_weight == 'ls-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', 'ls-4')" [ngClass]="{'active': this.component.style.font_weight == 'ls-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('letter_spacing', 'ls-5')" [ngClass]="{'active': this.component.style.font_weight == 'ls-5'}">5</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Italic</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_italic', '')" [ngClass]="{'active': this.component.style.font_italic == ''}">None</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_italic', 'fst-italic')" [ngClass]="{'active': this.component.style.font_italic == 'fst-italic'}">YES</button>
                </div>
            </div>

            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">Text Decoration</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_decoration', '')" [ngClass]="{'active': this.component.style.text_decoration == ''}">None</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_decoration', 'text-decoration-underline')" [ngClass]="{'active': this.component.style.text_decoration == 'text-decoration-underline'}">Underline</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('text_decoration', 'text-decoration-line-through')" [ngClass]="{'active': this.component.style.text_decoration == 'text-decoration-line-through'}">Line-through</button>
                </div>
            </div>
            <div class="btn-toolbar" role="toolbar">
                <span class="btn btn-dark btn-xs disabled text-start rounded-0 flex-fill">FONT SIZE</span>
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', '')" [ngClass]="{'active': this.component.style.font_size == ''}">Auto</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-1')" [ngClass]="{'active': this.component.style.font_size == 'fs-1'}">1</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-2')" [ngClass]="{'active': this.component.style.font_size == 'fs-2'}">2</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-3')" [ngClass]="{'active': this.component.style.font_size == 'fs-3'}">3</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-4')" [ngClass]="{'active': this.component.style.font_size == 'fs-4'}">4</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-5')" [ngClass]="{'active': this.component.style.font_size == 'fs-5'}">5</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-6')" [ngClass]="{'active': this.component.style.font_size == 'fs-6'}">6</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-7')" [ngClass]="{'active': this.component.style.font_size == 'fs-7'}">7</button>
                    <button type="button" class="btn btn-xs rounded-0" (click)="setStyle('font_size', 'fs-8')" [ngClass]="{'active': this.component.style.font_size == 'fs-8'}">8</button>
                </div>
            </div>
            
            <!-- <div class="btn-group btn-group-sm w-100 mt-2" role="group" aria-label="Height">
                <span class="btn btn-dark btn-xs border-secondary disabled">SIZE</span>
                <div class="range-slider-container">
                    <input type="range" class="range-slider" min="1" max="8" value="1" step="1">
                </div>
                <p class="slider-counter-container">
                    Slider value: 
                    <span class="slider-counter">
                    </span>
                </p> 
            </div> -->
        </div>
    </div>
    <div class="lexi-accordion">
        <div class="header" (click)="toggleOption($event)">
            <h6 class="d-flex flex-row justify-content-between">
                <span>Margin & Padding</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="inactive"><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="active"><path d="M11.29 8.71L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.38-.39-1.02-.39-1.41 0z"/></svg>
            </h6>
        </div>
        <div class="options">
            <div class="border border-black" style="height: 200px;">
                <div class="row g-0 bg-dark" style="height: 20%;">
                    <div class="col-2 d-flex justify-content-start align-items-start">
                        <div>Margin</div>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-4 d-flex justify-content-center align-items-center">
                        <select [ngModel]="component.style.margin_top" (change)="setStyleOnChange($event, 'margin_top')">
                            <option [value]="null">Auto</option>
                            <option [value]="'mt-0'">0</option>
                            <option [value]="'mt-1'">1</option>
                            <option [value]="'mt-2'">2</option>
                            <option [value]="'mt-3'">3</option>
                            <option [value]="'mt-4'">4</option>
                            <option [value]="'mt-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-2"></div>
                </div>
                <div class="row g-0" style="height: 20%;">
                    <div class="col-2 bg-dark"></div>
                    <div class="col-2 border-start border-top border-black d-flex justify-content-start align-items-start bg-transparent">
                        <div>Padding</div>
                    </div>
                    <div class="col-4 border-top border-black d-flex justify-content-center align-items-center bs-gray-300">
                        <select [ngModel]="component.style.padding_top" (change)="setStyleOnChange($event, 'padding_top')">
                            <option [value]="'pt-0'">0</option>
                            <option [value]="'pt-1'">1</option>
                            <option [value]="'pt-2'">2</option>
                            <option [value]="'pt-3'">3</option>
                            <option [value]="'pt-4'">4</option>
                            <option [value]="'pt-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2 border-end border-top border-black bg-transparent"></div>
                    <div class="col-2 bg-dark"></div>
                </div>
                <div class="row g-0" style="height: 20%;">
                    <div class="col-2 d-flex justify-content-center align-items-center bg-dark">
                        <select [ngModel]="component.style.margin_left" (change)="setStyleOnChange($event, 'margin_left')">
                            <option [value]="null">Auto</option>
                            <option [value]="'ms-0'">0</option>
                            <option [value]="'ms-1'">1</option>
                            <option [value]="'ms-2'">2</option>
                            <option [value]="'ms-3'">3</option>
                            <option [value]="'ms-4'">4</option>
                            <option [value]="'ms-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2 border-start border-black d-flex justify-content-center align-items-center bg-transparent">
                        <select [ngModel]="component.style.padding_left" (change)="setStyleOnChange($event, 'padding_left')">
                            <option [value]="'ps-0'">0</option>
                            <option [value]="'ps-1'">1</option>
                            <option [value]="'ps-2'">2</option>
                            <option [value]="'ps-3'">3</option>
                            <option [value]="'ps-4'">4</option>
                            <option [value]="'ps-5'">5</option>
                        </select>
                    </div>
                    <div class="col-4 border border-black d-flex justify-content-center align-items-center bg-white">
                        <div class="text-black">Content</div>
                    </div>
                    <div class="col-2 border-end border-black d-flex justify-content-center align-items-center bg-transparent">
                        <select [ngModel]="component.style.padding_right" (change)="setStyleOnChange($event, 'padding_right')">
                            <option [value]="'pe-0'">0</option>
                            <option [value]="'pe-1'">1</option>
                            <option [value]="'pe-2'">2</option>
                            <option [value]="'pe-3'">3</option>
                            <option [value]="'pe-4'">4</option>
                            <option [value]="'pe-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center bg-dark">
                        <select [ngModel]="component.style.margin_right" (change)="setStyleOnChange($event, 'margin_right')">
                            <option [value]="null">Auto</option>
                            <option [value]="'me-0'">0</option>
                            <option [value]="'me-1'">1</option>
                            <option [value]="'me-2'">2</option>
                            <option [value]="'me-3'">3</option>
                            <option [value]="'me-4'">4</option>
                            <option [value]="'me-5'">5</option>
                        </select>
                    </div>
                </div>
                <div class="row g-0" style="height: 20%;">
                    <div class="col-2 bg-dark"></div>
                    <div class="col-2 border-start border-bottom border-black bg-transparent"></div>
                    <div class="col-4 border-bottom border-black d-flex justify-content-center align-items-center bg-transparent">
                        <select [ngModel]="component.style.padding_bottom" (change)="setStyleOnChange($event, 'padding_bottom')">
                            <option [value]="'pb-0'">0</option>
                            <option [value]="'pb-1'">1</option>
                            <option [value]="'pb-2'">2</option>
                            <option [value]="'pb-3'">3</option>
                            <option [value]="'pb-4'">4</option>
                            <option [value]="'pb-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2 border-end border-bottom border-black bg-transparent"></div>
                    <div class="col-2 bg-dark"></div>
                </div>
                <div class="row g-0 bg-dark" style="height: 20%;">
                    <div class="col-2"></div>
                    <div class="col-2"></div>
                    <div class="col-4 d-flex justify-content-center align-items-center">
                        <select [ngModel]="component.style.margin_bottom" (change)="setStyleOnChange($event, 'margin_bottom')">
                            <option [value]="null">Auto</option>
                            <option [value]="'mb-0'">0</option>
                            <option [value]="'mb-1'">1</option>
                            <option [value]="'mb-2'">2</option>
                            <option [value]="'mb-3'">3</option>
                            <option [value]="'mb-4'">4</option>
                            <option [value]="'mb-5'">5</option>
                        </select>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>