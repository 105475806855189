import { NgModule } from '@angular/core';
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { SurveyEntryComponent } from './survey-entry/survey-entry.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WarrantyComponent } from './warranty/warranty.component';



@NgModule({
  declarations: [
    SurveyFormComponent,
    SurveyEntryComponent,
    WarrantyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
  ]
})
export class SurveyModule { }
