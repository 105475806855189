import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostComponent } from './post.component';

const routes: Routes = [
  { path: '', component: PostComponent, children: [ 
    { path: '**', pathMatch: 'full', loadChildren: () => import("../page-not-found/page-not-found.module").then(m => m.PageNotFoundModule), data: {guard_failed_redirect: ["/auth"]} }
  ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostRoutingModule { 
  
}
