import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuilderComponent } from './builder.component';
import { ViewComponent } from './view/view.component';

const routes: Routes = [
  {path: "", component: BuilderComponent},
  {path: ":page_uuid", component: BuilderComponent},
  {path: "frame/:page_uuid", component: ViewComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuilderRoutingModule { }
