import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ApiService } from 'lexi-api';
import { LexiAuthService } from 'lexi-auth';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DsmeDataService {

  private _fingerprint:any;
  private _fingerprintData:any;

  private _profiles:any = [];
  private _profile:any = {};
  private _selected_profile:any;

  public get_profile_url: string = "";

  public profileOnChange: Subject<boolean> = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private document:Document,
    @Inject("environment") environment: any,
    private api: ApiService,
    private auth: LexiAuthService
  ) { 
    this.api.valueAppender("token", this.auth.getToken())
    
    if(environment.get_profile_url) {
      this.get_profile_url = environment.get_profile_url
    }
  }

  private getHostname() {
    const domain = this.document.location.hostname;
    return domain;
  }

  public getFingerprint() {
    if (this._fingerprintData) {
      return this._fingerprintData;
    }
    return new Promise((resolve) => {
      this._fingerprint = FingerprintJS.load();
      this._fingerprint.then((fp:any) => fp.get())
      .then((result:any) => {
        this._fingerprintData = {ip: null, device: result.components, unique: result.visitorId}
        resolve(this._fingerprintData);
      })
    })
  }

  async getProfiles() {

    try {
      let profile = await this.api.post(this.get_profile_url, {});
      if(profile && !profile.type) {
        this._profiles = profile
      }
    } catch (e:any) {
      this._profiles = []
    }

    return this._profiles;
  }

  async setProfile(profile:any) {
    this._selected_profile = profile;
    localStorage.setItem("profile", profile.uuid)
    await this.getProfile();
  }

  async getProfile() {
    this._profile = await this.api.post("/profile/get", {profile_uuid: this._selected_profile.uuid})
    this.profileOnChange.next(this._profile);
  }
  
  searchProfileByUUID(uuid:string) {
    return this._profiles.find((v:any) => {return v.uuid == uuid})
  }

  getLocalstorageProfile() {
    return localStorage.getItem("profile")
  }

  getSelectedProfile() {
    if(this._selected_profile) {
      return this._selected_profile;
    }

    if(this._profiles.length <= 0) {
      return;
    }

    let selected = localStorage.getItem("profile");
    if(selected) {

      let matched = this.searchProfileByUUID(selected);

      if(matched) {
        this._selected_profile = matched;
        this.setProfile(this._selected_profile);
        return matched;
      }

    }

    let profile = this._profiles[0]
    this._selected_profile = profile;
    this.setProfile(this._selected_profile);

    return profile;
  }

  clearStorage() {
    localStorage.clear();
  }

}
