import { Component, Input, ViewChild, ElementRef, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiPagebuilderService } from '../../lexi-pagebuilder.service';
import { ComponentComponent } from '../component/component.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPopupComponent } from '../view-popup/view-popup.component';

@Component({
  selector: 'lib-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements AfterViewInit {

  @ViewChild("viewer", {read: ViewContainerRef}) viewer!: ViewContainerRef;
  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  public pageStyle:any = "";
  public sections:any = [];
  public loading: boolean = false;
  public page_uuid:any;
  private doc:any;
  public myLangauge:any = 'en';

  @Input() set setDocument(doc : any) {
    this.doc = doc;
  };

  @Input() set updateLangauge(lang : any) {
    this.myLangauge = lang;
    this.builder.setSection(this.sections);
    this.createView();
  }

  @Input() set setPageData(page_data : any) {
    if(!page_data || !page_data.content) {
      return;
    }

    this.sections = page_data.content;
    this.builder.setSection(this.sections);
    this.createView();
  }
  
//   @Input() set setSections(sections : any) {
//     console.log(sections)
//     this.sections = sections;
//     this.builder.setSection(sections);
//     this.createView()
//   };

  constructor(
    private api:ApiService, 
    private route: ActivatedRoute, 
    private builder: LexiPagebuilderService, 
    private vcr: ViewContainerRef,
    private modal: NgbModal
  ) {

    let page_uuid = this.route.snapshot.paramMap.get('page_uuid');
    let page_identifier = this.route.snapshot.paramMap.get('page_identifier')
    if(!page_uuid && !page_identifier) {
      return;
    }

    this.builder.setVCR(this.vcr);
    
    this.page_uuid = page_uuid || page_identifier;
    // this.createView()
  }

  ngAfterViewInit() { 
    if(this.builder.editable) {
      this.wrapper.nativeElement.classList.add("editable")
    }
  }

  async getPage(page_uuid: string) {
    this.loading = true;
    let page = await this.api.post("/page/get", {page_uuid, get_data: true, language: this.myLangauge});
    console.log("_____page",page.variant)
    if(page.variant.length === 0){
      console.log(this.builder.getSection())
      // let modal = this.modal.open(ViewPopupComponent, {});
      //     modal.componentInstance.page_uuid = page_uuid;
      // modal.componentInstance.closeModal.subscribe( async (e:any) => {
      //   console.log("_____e", e)
      //   e.language = this.myLangauge
      //   this.builder.setPage(e)
      //   this.builder.setSection(e.content)
      //   modal.close()
        
      //   this.viewer.clear();
      //   let sections = this.builder.getSection();

      //   for(var x = 0 ; x < sections.length; x++) {
      //     await this.builder.generateSection(this.viewer, sections[x])
      //   }

      //   await this.builder.generateAddNewSection(this.viewer);
      // })
      return;
    }

    // if(Object.keys(page).length === 0){
    //   console.log(this.builder.getSection())
    //   let modal = this.modal.open(ViewPopupComponent, {});
    //       modal.componentInstance.page_uuid = page_uuid;
      
    //   modal.componentInstance.closeModal.subscribe( async (e:any) => {
    //     e.language = this.myLangauge
    //     this.builder.setPage(e)
    //     this.builder.setSection(e.content)
    //     modal.close()
        
    //     this.viewer.clear();
    //     let sections = this.builder.getSection();

    //     for(var x = 0 ; x < sections.length; x++) {
    //       await this.builder.generateSection(this.viewer, sections[x])
    //     }

    //     await this.builder.generateAddNewSection(this.viewer);
    //   })
    //   return;
    // }

    let theme = await this.api.post("/page/theme/get", {});
    this.builder.setDoc(this.doc)
    this.builder.setRootStyle(theme.theme.value, this.doc);

    if(!page.content) {
      this.loading = false;
      return;
    }

    this.sections = page.content;
    this.builder.setSection(this.sections);
  }

  async createView() {
    await this.getPage(this.page_uuid);
    this.viewer.clear();
    let sections = this.builder.getSection();

    for(var x = 0 ; x < sections.length; x++) {
      await this.builder.generateSection(this.viewer, sections[x])
    }

    await this.builder.generateAddNewSection(this.viewer);
  }

}
