<div #wrapper [ngSwitch]="component.heading_level">
    <ng-container *ngSwitchDefault >
        <p #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></p>
    </ng-container>

    <ng-container *ngSwitchCase="'paragraph'">
        <p #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></p>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_1'">
        <h1 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h1>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_2'">
        <h2 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h2>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_3'">
        <h3 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h3>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_4'">
        <h4 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h4>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_5'">
        <h5 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h5>
    </ng-container>

    <ng-container *ngSwitchCase="'heading_6'">
        <h6 #wrapper text-component="true" [innerHTML]="manupulateData(component.value)"></h6>
    </ng-container>
    
</div>
