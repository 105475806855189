<div #wrapper>
    <ngb-carousel class="w-100" #carousel>
        <ng-template *ngFor="let slider of component.fields; let i = index" ngbSlide class="w-100" >
            <div class="picsum-img-wrapper">
                <div class="carousel">
                    <img [src]="slider.responsive" class="w-100" [alt]="slider.title" />
                </div>
            </div>
            <div class="carousel-caption-modify" *ngIf="slider.title || slider.descr">
                <p class="{{component.color}} {{component.size}} {{component.font_family}} " [innerHTML]="slider.title"></p>
                <p class="{{component.dcolor}} {{component.dsize}} {{component.dfont_family}}" [innerHTML]="slider.descr"></p>
                <button *ngIf="slider.set_btn" class="btn {{component.btn.size}} {{component.btn.font_family}} {{component.btn.corner}} {{component.btn.default_color}} {{component.btn.btn_color}} {{component.btn.font_color}} ">{{component.button}}</button>
            </div>
        </ng-template>
    </ngb-carousel>
</div>