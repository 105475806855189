import { Component, OnInit, Input, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
  selector: 'lib-lexi-report',
  template: `<ng-template #template></ng-template>`,
  styles: [
  ]
})
export class LexiReportComponent implements AfterViewInit {

  @Input() type:any;
  @Input() data:any = [];
  @Input() showLabel:boolean = false;
  @ViewChild("template", {read: ViewContainerRef}) template!: ViewContainerRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.init(this.type)
  }

  async init(type:string) {

    let section:any;
    let instance:any;

    switch(type) {
      case "pie":
      section = ((await import("@swimlane/ngx-charts")).BarVerticalComponent);
      instance = this.template.createComponent(section);
      instance.instance.scheme = "horizon";
      instance.instance.gradient = false;
      instance.instance.labels = this.showLabel;
      instance.instance.legend = true;
      instance.instance.legendPosition = LegendPosition.Below;
      instance.instance.update()
      instance.instance.results = this.data
      break;


    }


  }

}
