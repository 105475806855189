import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';

@Component({
  selector: 'lib-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ImageComponent {

  @ViewChild("wrapper", {static: true}) wrapper!: ElementRef;

  @Input() set setComponent(val: any) {
    console.log(val)

    if(!val) {
      val = {}
    }

    if(!val.value) {
      val.value = "https://lexisoft98.b-cdn.net/resource/placeholder.jpg";
    }

    if(!val.style || Object.keys(val.style).length == 0) {
      val.style = {'width': "w-100"};
    }

    this.component = val;
    this.setImage();  

  } 

  public component:any;

  constructor(public builder: LexiPagebuilderService, private vcr: ViewContainerRef, private changeDetectorRef: ChangeDetectorRef) {  }

  onResize(event:any){
    this.setImage(event.target.innerWidth);
  }

  setImage(size?:number) {
    let doc = this.builder.getDoc();

    size = doc.body ? doc.body.clientWidth : null
console.log(doc, size)
    if(!size) {
      size = window.innerWidth;
    }

    if(!this.component.value || (size <= 576 && this.component.mobile_value)) {
      this.wrapper.nativeElement.src = this.component.mobile_value;
      return;
    }

    this.wrapper.nativeElement.src = this.component.value;

    if(this.component.title) {
      this.wrapper.nativeElement.alt = this.component.title;
    }

  }
}
