import { Component, ElementRef, Input } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { LexiPagebuilderService } from '../../../lexi-pagebuilder.service';
import { ContentComponent } from '../../options/content/content.component';
import { StyleComponent } from '../../options/style/style.component';

@Component({
  selector: 'lib-edit-component',
  templateUrl: './edit-component.component.html',
  styleUrls: ['./edit-component.component.css']
})
export class EditComponentComponent {

  private uid: string | undefined;
  private position:any;
  private element:ElementRef | undefined;
  private instance:any;
  public type: string = "COMPONENT"
  @Input() set setUID(val:string) {
    if(val) {
      this.uid = val;
      this.position = this.builder.getPosition(this.uid);
      this.type = this.position.component.type.startsWith("columns") ? "ROW" : this.position.component.type.toUpperCase()
    }
  }
  @Input() set setInstance(val:any) { this.instance = val }
  @Input() set setElement(val:ElementRef) { this.element = val }

  constructor(private offcanvasService: NgbOffcanvas, private builder: LexiPagebuilderService) { }

  edit() {

    if(!this.uid) {
      return;
    }

    let canvas = this.offcanvasService.open(ContentComponent, {position: "end", panelClass: "bg-dark", scroll: true})
    canvas.componentInstance.setType = this.type;
    canvas.componentInstance.setComponent = this.position.component;
    canvas.componentInstance.update.subscribe((value:any) => {

      let position = this.builder.getPosition(value.uid);
      console.log(value, this.position, position)

      this.builder.setStyle(this.element, value);

      if(this.element && this.element.nativeElement && this.element.nativeElement.parentElement) {
        if(this.position.component.size) {
          console.log(value)
          if(!value.wrapper_style) {
            value.wrapper_style = {};
          }
          value = value.wrapper_style.concat(this.position.component.size.split(" "))
        }
        this.builder.setStyle(this.element.nativeElement.parentElement, value, "wrapper_style");
      }

     let sections = this.builder.getSection()

      this.instance.setComponent = value;

      if(this.position.element_index != undefined) {
        console.log("here 1")
        sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components[this.position.element_index] = value;
      } else if(this.position.column_index != undefined) {
        console.log("here 2")
        sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index] = value;
      } else if(this.position.row_index != undefined) {
        console.log("here 3")
        sections[this.position.section_index].components[this.position.row_index] = value;
      } else if(this.position.section_index != undefined) {
        console.log("here 4")
        sections[this.position.section_index] = value;
      }
console.log(sections)
      this.builder.setSection(sections);

    })
  }

  editStyle() {

    if(!this.uid) {
      return;
    }
    this.position = this.builder.getPosition(this.uid);

    let canvas = this.offcanvasService.open(StyleComponent, {position: "end", panelClass: "bg-dark", scroll: true})
    canvas.componentInstance.setType = this.type;
    canvas.componentInstance.setComponent = this.position.component;
    canvas.componentInstance.update.subscribe((value:any) => {

      this.builder.setStyle(this.element, value);

      if(this.element && this.element.nativeElement && this.element.nativeElement.parentElement) {
        if(this.position.component.size) {
          console.log(value)
          if(!value.wrapper_style) {
            value.wrapper_style = [];
          }
          value = value.wrapper_style.concat(this.position.component.size.split(" "))
        }
        this.builder.setStyle(this.element.nativeElement.parentElement, value, "wrapper_style");
      }

     let sections = this.builder.getSection()
     console.log(this.instance)
      this.instance.setComponent = value;

      // if(this.position.element_index != undefined) {
      //   sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components[this.position.element_index] = value;
      // } else if(this.position.column_index != undefined) {
      //   sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index] = value;
      // } else if(this.position.row_index != undefined) {
      //   sections[this.position.section_index].components[this.position.row_index] = value;
      // } else if(this.position.section_index != undefined) {
      //   sections[this.position.section_index] = value;
      // }

  //    this.builder.setSection(sections);

    })
  }

  delete() {

    if(!this.uid || !this.element) {
      return;
    }

    let sections = this.builder.getSection();

    this.position = this.builder.getPosition(this.uid);

    if(this.position.element_index != undefined) {
      sections[this.position.section_index].components[this.position.row_index].components[this.position.column_index].components.splice(this.position.element_index,1);
    } else if(this.position.column_index != undefined) {
      sections[this.position.section_index].components[this.position.row_index].components.splice(this.position.column_index,1);
    } else if(this.position.row_index != undefined) {
      sections[this.position.section_index].components.splice(this.position.row_index,1);
    } else if(this.position.section_index != undefined) {
      sections.splice(this.position.section_index, 1);
    }

    if(this.position.row_index != undefined) {
      this.element.nativeElement.parentElement.remove();
    } else {
      this.element.nativeElement.remove();
    }

    this.builder.setSection(sections)
    console.log(this.uid, this.builder.getSection())
  }
}
