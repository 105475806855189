import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuilderComponent } from './builder.component';
import { BuilderRoutingModule } from './builder-routing.module';
import { ComponentComponent } from './component/component.component';
import { ViewComponent } from './view/view.component';
import { ViewToolbarComponent } from './view-toolbar/view-toolbar.component';
import { NgbModalModule, NgbModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ComponentViewComponent } from './component-view/component-view.component';
import { LexiWysiwygModule } from 'lexi-wysiwyg';
import { PlainViewComponent } from './plain-view/plain-view.component';
import { ThemeComponent } from './theme/theme.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TextComponent } from './component-view/text/text.component';
import { ImageComponent } from './component-view/image/image.component';
import { HtmlComponent } from './component-view/html/html.component';
import { VideoComponent } from './component-view/video/video.component';
import { SliderComponent } from './component-view/slider/slider.component';
import { ButtonComponent } from './component-view/button/button.component';
import { ListComponent } from './component-view/list/list.component';
import { MapComponent } from './component-view/map/map.component';
import { SectionComponent } from './layout/section/section.component';
import { RowComponent } from './layout/row/row.component';
import { CellComponent } from './layout/cell/cell.component';
import { NewSectionComponent } from './layout/new-section/new-section.component';
import { EditComponentComponent } from './layout/edit-component/edit-component.component';
import { LexiPagebuilderService } from '../lexi-pagebuilder.service';
import { CardComponent } from './component-view/card/card.component';
import { AccordionComponent } from './component-view/accordion/accordion.component';
import { PageComponent } from './options/page/page.component';
import { StyleComponent } from './options/style/style.component';
import { ContentComponent } from './options/content/content.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SettingComponent } from './options/setting/setting.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { ViewPopupComponent } from './view-popup/view-popup.component';

@NgModule({
  declarations: [
    BuilderComponent,
    ComponentComponent,
    ViewComponent,
    ViewToolbarComponent,
    ComponentViewComponent,
    PlainViewComponent,
    ThemeComponent,
    TextComponent,
    ImageComponent,
    HtmlComponent,
    VideoComponent,
    SliderComponent,
    ButtonComponent,
    ListComponent,
    MapComponent,
    SectionComponent,
    RowComponent,
    CellComponent,
    NewSectionComponent,
    EditComponentComponent,
    CardComponent,
    AccordionComponent,
    PageComponent,
    StyleComponent,
    ContentComponent,
    SettingComponent,
    ViewPopupComponent
  ],
  imports: [
    CommonModule,
    BuilderRoutingModule,
    NgbModalModule,
    NgbOffcanvasModule,
    FormsModule,
    LexiWysiwygModule,
    NgbModule,
    ColorPickerModule,
    GoogleMapsModule,
    HttpClientJsonpModule
  ],
  providers: [
    LexiPagebuilderService
  ],
  exports: [
    PlainViewComponent
  ]
})
export class BuilderModule { }
